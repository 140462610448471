export default class TruncatedTitle {
  constructor(title, maxLength) {
    this.title = title
    this.maxLength = maxLength || 50
  }

  toString() {
    if (this.truncatedTitle.length !== this.title.length) {
      return this.truncatedTitle + '...'
    }

    return this.title
  }

  get truncatedTitle() {
    return this.title.replace(this.regex, '$1')
  }

  get regex() {
    return new RegExp(`^(.{${this.maxLength}}[^\\s,]*).*`)
  }
}
