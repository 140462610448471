<template lang="pug">
  .content
    .page-heading
      h1.page-title.page-title_top
        | Вход
    .signup-container.cc-login-container
      rsv-login(:town="town" @loggedIn="onLoggedIn")
</template>

<script>
import RsvLogin from "../components/rsv-login";
export default {
  name: "page-rsv-login",
  components: {RsvLogin},
  computed: {
    town() {
      return this.$store.state.town.current
    }
  },
  methods: {
    onLoggedIn() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>