<template lang="pug">
  .agv-basic-article-info.cc-agv-basic-article-info
    .price(:class="{'price-with-discount': !!showDiscount}")
      span(v-if="showDiscount")
        | {{priceWithDiscount}}
      span(v-else)
        | {{price}}
      span.currency
        | {{renter.country | currencySymbol}}
    .for-individuals(v-if="showDiscount")
      | {{price | money(renter.country)}} для частников
    .order-info
      | за {{baseRentDuration | decline(['день', 'дня', 'дней'])}}
</template>

<script>
  export default {
    name: 'agv-basic-article-info',
    props: {
      price: Number,
      priceWithDiscount: Number,
      showDiscount: Boolean,
      baseRentDuration: Number,
      renter: Object
    }
  }
</script>

<style scoped>
  .price {
    font-size: 33px;
    font-weight: bold;
    color: #000000;
    line-height: 23px;
    margin-bottom: 9px;
  }

  .currency {
    font-size: 23px;
    margin-left: 5px;
  }

  .price.price-with-discount {
    color: #2ea50d;
  }

  .for-individuals {
    font-size: 12px;
    font-weight: 400;
    margin-top: 3px;
    margin-bottom: 8px;
    color: #828282;
  }
</style>
