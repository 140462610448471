// @flow

import Vue from 'vue'

import {getCurrentOrder} from 'RenterSite/lib/order'
import store from 'RenterSite/store/index'

export default function createStore(
  towns: Array<{ name: string }>,
  cookieTownName: string
) {
  const town = window.as_customer_id ? (_.find(towns, {id: window.as_customer_town_id})) : (_.find(towns, t => t.name === cookieTownName) || towns[0])

  return {
    namespaced: true,
    state: {
      current: town,
      towns: towns,
      townsById: _.keyBy(towns, 'id')
    },
    actions: {
      setCurrent(store, town) {
        store.commit('setCurrent', town)
        store.commit('order/clearCurrent', {renterId: store.rootState.renter.id}, {root: true})
        store.commit('availability/clearAvailable', {renterId: store.rootState.renter.id}, {root: true})
      }
    },
    mutations: {
      setCurrent(state, value) {
        state.current = value
        Vue.cookie.set('town_name', value.name)
      }
    }
  }
}
