<template lang="pug">
  .nav-sheet
    .section(v-if="isAbridged")
      ul.important-kinds
        li.kind(v-for="navItem in orderBy(subsections, 'position')" :key="navItem.id")
          router-link(:to='navItem.url')
            | {{navItem.name}}
    .section(v-else v-for="section in sections" :key="section.id")
      router-link.section-title(:to='section.url')
        | {{section.name}}
      ul.important-kinds
        li.kind(v-for="navItem in orderBy(section.importantItems, 'position')" :key="navItem.id")
          router-link(:to='navItem.url')
            | {{navItem.name}}
      ul.non-important-kinds(v-if='section.nonImportantItems.length')
        li.kind(v-for="navItem in orderBy(section.nonImportantItems, 'name')" :key="navItem.id")
          router-link(:to='navItem.url')
            | {{navItem.name}}
    slot
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'agv-nav-sheet',
    props: {
      town: Object,
      sections: Array,
      subsections: Array,
      isAbridged: Boolean
    },
    data() {
      return {
        availableKindIds: [],
      }
    },
    computed: {
      navItems() {
        return this.availableKinds
      }
    },
    methods: {
      orderBy: _.orderBy
    }
  }
</script>

<style scoped>
  .section {
    margin-bottom: 1.5em;
    padding-right: 4.6em;
    width: 320px;
  }

  .section-title {
    display: block;
    font-size: 130%;
    line-height: 140%;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: #000;
    margin-bottom: 0.3em;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    list-style: none;
  }

  .important-kinds {
    font-size: 100%;
    color: #004ba5;
    line-height: 160%;
  }

  .non-important-kinds {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  .non-important-kinds .kind {
    margin-right: 0.7em;
    line-height: 146%;
    display: inline-block;
  }

  .non-important-kinds .kind a {
    font-size: 92%;
    color: #5d5d5d;
  }

  a:hover {
    filter: brightness(120%);
  }

  .nav-sheet {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .icon-red-heart {
    margin-right: 7px;
    position: relative;
    top: 4px;
  }

  .nav-sheet-container-full {
    position: absolute;
    top: 136px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .nav-sheet-container-abridged {
    position: absolute;
    top: 150px;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }
</style>
