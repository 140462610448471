/*eslint no-var: "error"*/
/*eslint-env es6*/
//@flow

import axios from 'axios'
import qs from 'querystring'
import _ from 'lodash'

import store from 'RenterSite/store/index'
import type {PaymentMethodString} from 'Shared/order'

import {
  isDateDisabledForOrder as _isDateDisabledForOrder
} from 'Shared/order'
import {getPaymentSum} from 'Shared/payment'

export function findOrder(orderId) {
  return axios.get(getOrdersUrl() + `/${orderId}.json`)
}

export function isOrderCurrent(order) {
  return order === getCurrentOrder()
}

export function isDateDisabledForOrder(date, order) {
  const branch = store.getters.branch
  return _isDateDisabledForOrder(date, order, branch)
}

export function getArticle(id, renterId) {
  if (!renterId) {
    throw new Error('No renterId!')
  }
  return store.getters['articles/getByRenterIdAndArticleId'](renterId, id)
}

export function getBranch(townId) {
  return store.state.branchesByTownId[townId]
}

export function getTown(townId) {
  return store.state.town.townsById[townId]
}

export function getArticleUrl(articleId, renterId) {
  return `/article/${articleId}`
}

export function getOrdersUrl() {
  return `/api/orders`
}

export function getOrderBillUrl(renterId: number,
                                orderId: number,
                                paymentMethod: PaymentMethodString,
                                sum: number,
                                balance: number,
                                bankDetails: ?{ legal_name: string, address: string, tin: string, rrc: string },
                                adminCustomerId: ?number
) {
  let queryString = qs.stringify(
    _.merge(
      {},
      adminCustomerId ? {as_customer_id: adminCustomerId} : {},
      _.pick(bankDetails, ['legal_name', 'address', 'tin', 'rrc']) || {}
    )
  )

  return `/api/orders/${orderId}/customer_bills/create/${paymentMethod}/` +
    getPaymentSum(sum, balance) + '?' + queryString
}

export function getLastWireTransferBillDetailsUrl(renterId: number) {
  return `/api/customer_bills/last_wire_transfer_bill/`
}

export function getCustomerBillsUrl() {
  return `/api/customer/customer_bills`
}

export function getExportUrl(orderCode, adminCustomerId) {
  if (adminCustomerId) {
    return `/api/orders/${orderCode}/order_pdf?as_customer_id=${adminCustomerId}`
  }
  return `/api/orders/${orderCode}/order_pdf`
}

export function getCurrentOrder() {
  return store.getters['order/getByRenterId'](store.state.renter.id)

}

export function getOrdersRouteUrl(renterId) {
  return '/order'
}

export function getStateUrl(renterId) {
  return '/state'
}
