<template lang="pug">
  .categories(:style="columnCount ? {gridTemplateColumns: `repeat(${columnCount}, 1fr)`} : {}")
    .category-cell(v-for="kind in kinds" :key="kind.id")
      router-link.category(:to='kind.url')
        .preview-container
          img(:src='kind.image_url')
        .category-name
          | {{kind.name}}
          |
          .count(v-if="kind.count")
            | — {{kind.count}}
</template>

<script>
  export default {
    name: 'agv-kind-list',
    props: {
      kinds: Array,
      columnCount: {
        type: Number,
        required: false
      }
    }
  }
</script>

<style scoped>
  .categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    width: 100%;
    margin-bottom: 10px;
  }

  .category-cell {
    height: 100px;
    border: 1px solid #D6D6D6;
    border-radius: 3px;
    padding: 15px 17px 15px 13px;
    width: 100%;
  }

  @media (min-width: 1250px) {
    .categories {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .category {
    align-items: center;
    display: flex;

    margin-right: 10px;
    font-size: 14px;
    color: black;
  }

  .category:last-child {
    margin-right: 0;
  }

  .category:hover {
    color: #8a8a8a;
    text-decoration: none;
  }

  .preview-container {
    width: 70px;
    min-width: 70px;
    height: 70px;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .preview-container img {
    max-width: 100%;
    max-height: 100%;
  }

  .preview {
    max-width: 100%;
  }

  .category-name {
    margin-left: 13px;
  }

  .count {
    display: inline-block;
    color: #737373;
  }
</style>
