<template lang="pug">
  .order-delivery-by-self.order-service-row
    .name-cell
      el-button.cc-btn-add-delivery(type="text" @click="$emit('add')" v-if='renter.is_delivery_offered && isEditing')
        | Добавить доставку
    .service-description-cell
      .service-description
        .service-description__title(v-if='!renter.is_delivery_offered')
          | Объем изделий
        .service-description__title(v-else-if='!isEditing')
          | Самостоятельная доставка
        p.service-description__text
          span(v-if='renter.is_delivery_offered')
            | Объем изделий:
            |
          | {{volume | pkitNumber}} м³, вес –– {{mass | pkitNumber}} кг
          br
          | (рекомендуется использовать {{transportTypeAdjective}} транспорт)
          br
          | Адрес пункта выдачи:
          | {{branch.cp_address}},
          br
          span(v-if="branch.freeform_cp_schedule" v-html="newlinesToBrTags(branch.freeform_cp_schedule)")
          span(v-else)
            | ({{branch.cp_off_days | cpWorkdaysFromOffDays}}, {{branch.cp_schedule | cpSchedule}})
</template>

<script>
  import {getMass, getVolume, getTransportType} from 'Shared/order'

  export default {
    props: {
      lineItems: Array,
      branch: Object,
      renter: Object,
      isEditing: Boolean
    },
    name: 'order-delivery-by-self',
    computed: {
      transportTypeAdjective() {
        switch(this.transportType) {
          case 'car': return 'легковой'
          case 'van': return 'грузовой'
          case 'truck': return 'большегрузный'
        }
      },
      mass() {
        return getMass(this.lineItems)
      },
      volume() {
        return getVolume(this.lineItems)
      },
      transportType() {
        return getTransportType(this.lineItems, this.renter, true)
      },
    },
    methods: {
      newlinesToBrTags(str) {
        return this.$options.filters.newlinesToBrTags(str)
      }
    }
  }
</script>

<style scoped>

</style>
