<template lang="pug">
  .agv-customer-balance.balance
    surplus-retrieval-modal(:renter="renter" v-if="showSurplusRetrievalPopup" @close="showSurplusRetrievalPopup = false")
    | Баланс: {{customer.balance | money(renter.country)}}
    agv-customer-debt-payment-popover(v-if="customer.balance < 0"
      :renter="renter"
      :customer="customer"
      :bank-details="bankDetails"
      :get-customer-bills-url="getCustomerBillsUrl")
      .pay-btn.link-btn(slot="reference")
        | Погасить задолженность
    .pay-btn.link-btn(v-else-if="customer.balance > 0" @click="sendSurplusReturnRequest")
      | Отправить заявку на возврат
</template>

<script>
  import axios from 'axios'
  import eventBus from 'Shared/eventBus'

  import SurplusRetrievalModal from 'Shared/components/surplus-retrieval-modal'
  import AgvCustomerDebtPaymentPopover from 'Aggregator/components/agv-customer-debt-payment-popover'

  export default {
    name: "agv-customer-balance",
    components: {AgvCustomerDebtPaymentPopover, SurplusRetrievalModal},
    props: {
      renter: {type: Object, required: true},
      customer: {type: Object, required: true},
      getCustomerBillsUrl: Function,
      getLastWireTransferBillDetailsUrl: Function
    },
    data() {
      return {
        showAll: false,
        sbolPaymentSum: null,
        showSurplusRetrievalPopup: false,
        bankDetails: null
      }
    },
    methods: {
      sendSurplusReturnRequest() {
        this.$store.dispatch('renterCustomer/retrieveSurplus', {renterId: this.renter.id})
          .then(() => {
            this.showSurplusRetrievalPopup = true
          })
      }
    },
    created() {
      axios.get(this.getLastWireTransferBillDetailsUrl(this.renter.id)).then(({data: details}) => {
        this.bankDetails = details || {}
      })
    }
  }
</script>

<style scoped>
  .balance {
    font-size: 14px;
    margin-top: -24px;
    margin-bottom: 20px;
  }

  .pay-btn {
    margin-left: 20px;
    color: #004ba5;
    display: inline-block;
  }
</style>
