<template lang="pug">
  .agv-customer-transactions-page-content.form-container
    .page-heading
      slot
      .span(v-if="transactions && transactions.length >= 10")
        .expand-btn.link-btn(v-if='showAll' @click="showAll = false")
          | Показать 10 последних
        .expand-btn.link-btn(v-else @click="showAll = true")
          | Показать архив операций
    agv-customer-balance(:customer="customer" :renter="renter"
      :get-customer-bills-url="getCustomerBillsUrl"
      :get-last-wire-transfer-bill-details-url="getLastWireTransferBillDetailsUrl")
    div(v-if="transactions && transactions.length === 0")
      | Пока у вас нет операций
    agv-customer-transactions-table(v-else v-loading="!transactions"
    :show-all="showAll"
    :transactions="transactions"
    :renter-id="renter.id"
    :country-code="renter.country"
    :get-orders-route-url="getOrdersRouteUrl")
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import AgvCustomerBalance from 'Aggregator/components/agv-customer-balance'
  import AgvCustomerTransactionsTable from 'Aggregator/components/agv-customer-transactions-table'

  export default {
    name: "agv-customer-transactions-page-content",
    components: {AgvCustomerTransactionsTable, AgvCustomerBalance},
    props: {
      renter: Object,
      customer: Object,
      transactions: Array,
      getOrdersRouteUrl: Function,
      getCustomerBillsUrl: Function,
      getLastWireTransferBillDetailsUrl: Function
    },
    data() {
      return {
        showAll: false,
      }
    }
  }
</script>

<style scoped>

</style>
