import _ from 'lodash'

const SIZE_NAMES_PER_SHAPE = {
  'круг': ['diameter'],
  'линия': ['length'],
  'цилиндр': ['diameter', 'height'],
  'параллелепипед': ['height', 'width', 'depth'],
  'прямоугольник': ['length', 'width'],
}

export default class NamedArticleSizes {
  constructor(article) {
    this.article = article
  }

  get value() {
    if (this._value) {
      return this._value
    }

    this._value = {}

    let currentSizeNum = 0
    _.each(SIZE_NAMES_PER_SHAPE[this.article.shape], (size) => {
      this._value[size] = this.article.sizes[currentSizeNum]
      currentSizeNum += 1
    })

    return this._value
  }
}
