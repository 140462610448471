<template>
  <div class="icon-like-button">
    <Heart @click.native="onClickHeart"
           :class="{ 'has-interacted': hasInteracted, 'liked': isLiked, 'not-liked': !isLiked }"/>
  </div>
</template>

<script>
import Heart from 'Aggregator/sprites/heart.svg'

export default {
  components: {Heart},
  data() {
    return {
      hasInteracted: false
    }
  },
  props: {
    isLiked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickHeart() {
      this.hasInteracted = true;
      this.$emit('click');
    }
  }
}
</script>

<style scoped>

.icon-like-button >>> svg {
  padding: 11px;
  margin-right: -11px;
  display: block;
  top: 2px;
  position: relative;
}

.icon-like-button {
  border-radius: 50%;
  width: 41px;
  height: 41px;
  background: transparent;
}

.icon-like-button:hover {
  background-color: rgba(204, 204, 204, 0.2);
}

.icon-like-button svg {
  width: 20px;
  cursor: pointer;
}

@keyframes pop {
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.0);
  }
}

.liked {
  color: red;
}

.has-interacted.liked {
  animation: pop 0.25s ease;
}

.liked >>> path {
  fill: red;
}

.not-liked {
  color: grey;
  transition: color 0.3s ease;
}

.not-liked:hover {
  color: red;
}

.not-liked >>> path {
  fill: white;
}
</style>
