export function getNameWithSubstitutions(name, substitutions: Object) {
  if (!substitutions) {
    return name;
  }

  for (let key in substitutions) {
    const regex = new RegExp(key, 'gi');
    name = name.replace(regex, substitutions[key]);
  }

  return name;
}