<template lang="pug">
  .key-features-container
    ul.key-features
      li.key-feature
        agv-article-size(:article="article")
      li.key-feature(v-if='customFeatures' v-for="feature in customFeatures" itemprop="description")
        | {{feature}}
      li.key-feature(v-if='article.type === "Article"')
        | В комплекте {{article.components.length | decline(['изделие', 'изделия', 'изделий'])}}:
        br
        br
        .component(v-for='component in article.components')
          .component-photo-container
            img(:src="component.first_photo.thumbnail.url")
          .component-name
            | {{component.name}} — {{quantityForComponent(component.id)}} шт.
      li.key-feature
        | Вес: {{mass | pkitNumber }} кг
      li.key-feature
        | Монтаж: {{setupDuration * 60 | pkitNumber }} мин
      meta(itemprop="weight" :content="mass")
</template>

<script>
  import _ from 'lodash'
  import AgvArticleSize from 'Aggregator/components/agv-article-size'

  export default {
    name: 'agv-key-features',
    components: {AgvArticleSize},
    props: {
      article: Object
    },
    methods: {
      quantityForComponent(componentId) {
        return _.find(this.article.components_with_quantity, {id: componentId}).quantity
      }
    },
    computed: {
      mass() {
        return this.article.type === 'Product' ? this.article.gross_mass : _.sum(_.map(this.article.components, c => c.gross_mass * this.quantityForComponent(c.id)))
      },
      setupDuration() {
        return this.article.type === 'Product' ? this.article.setup_duration : _.sum(_.map(this.article.components, c => c.setup_duration * this.quantityForComponent(c.id)))
      },
      customFeatures() {
        if (!this.article.key_feature_description) {
          return null
        }

        return _.split(_.trim(this.article.key_feature_description), /\n+/)
      }
    }
  }
</script>

<style scoped>
  .key-feature {
    margin-bottom: 12px;
    list-style: none;
  }

  .component {
    margin-bottom: 10px;
    display: flex;
  }

  .component-photo-container {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .component-photo-container img {
    max-width: 100%;
    max-height: 100%;
  }
</style>
