import _ from 'lodash'

export default {
  townId: state => {
    return state.town.current.id
  },
  town: state => {
    return state.town.current
  },
  showDiscount: state => {
    return !!(
      state.renter.show_discounted_prices &&
      _.get(state.customer.current, 'is_professional')
    )
  },
  branch: state => {
    return state.branchesByTownId[state.town.current.id]
  },
  rulesUrl: state => {
    return state.featureFlags.rules_page_url || `/answers`
  },
  relevantArticles: state => {
    return _.filter(
      state.articles.allByRenterId[state.renter.id],
      a => a.is_offered && a.total_available > 0 && a.is_shown_in_catalog
    )
  },
  sections: state => {
    return state.allSections
  },
  sectionsById: state => {
    return _.keyBy(state.allSections, 'id')
  },
  customerDiscount: state => {
    if (!state.renter.show_discounted_prices) {
      return
    }

    if (!state.customer.current) {
      return
    }
    if (!state.customer.current.is_professional) {
      return
    }
    let result = _.get(state.customer.current, 'special_rent_discount')

    if (result === null) {
      return state.renter.rent_discount
    }

    return result
  },
  getArticleNameSubstitutions: state => renterId => {
    return null
  },
  pifakitService: state => {
    return state.pifakit_service
  },
}
