import Vue from 'vue'
import _ from 'lodash'
import Storage from 'vue-web-storage'

const createService = function (state) {
  return new Vue({
    data() {
      return {
        allBanners: state.banners, // FIXME: на момет создания сервиса state еще не заполнен и $store возможно не создан
        bannerLoads: Vue.$localStorage.get('bannerLoads') || {},
        lastLoadedBannerId: Vue.$localStorage.get('lastLoadedBannerId'),
        closedBanners: Vue.$localStorage.get('closedBanners') || {},
      }
    },
    methods: {
      syncBannerLoads() {
        Vue.$localStorage.set('bannerLoads', this.bannerLoads)
      },
      syncLastLoadedBannerId() {
        Vue.$localStorage.set('lastLoadedBannerId', this.lastLoadedBannerId)
      },
      syncClosedBanners() {
        Vue.$localStorage.set('closedBanners', this.closedBanners)
      },
      close(banner) {
        Vue.set(this.closedBanners, banner.id, true)
      },
      getNext() {
        return _.sortBy(this.showableBanners, (banner) => {
          return Number(this.bannerLoads[banner.id] || 0) * 1000 - banner.position
        })[0]
      },
      show(banner) {
        if (!banner) {
          return
        }

        Vue.set(this.bannerLoads, banner.id, (this.bannerLoads[banner.id] || 0) + 1)
        this.lastLoadedBannerId = banner.id
      }
    },
    computed: {
      showableBanners() {
        const result = _.filter(this.allBanners, (b) => {
          const impressions = this.bannerLoads[b.id]
          const isClosed = this.closedBanners[b.id]
          return !impressions || (impressions < 5 && !isClosed)
        })

        if (!result.length) {
          return null
        }

        if (result.length === 1) {
          return result
        }

        return _.filter(result, (b) => {
          return this.lastLoadedBannerId !== b.id
        })
      }
    },
    watch: {
      bannerLoads: 'syncBannerLoads',
      lastLoadedBannerId: 'syncLastLoadedBannerId',
      closedBanners: 'syncClosedBanners'
    }
  })
}

let service = undefined

export default function getBannersService(state) {
  if (new Vue().$isServer) {
    return
  }

  if (!service) {

    Vue.use(Storage, {
      prefix: 'pkitApp.'
    })

    service = createService(state)
  }

  return service
}
