<template lang="pug">
  .order-delivery-details-form
    .section.cc-section-acquisition
      .label
        | Получение
      .edit-box
        .wrap-input
          .rent-period-date(v-if="order.acquisition")
            | {{order.acquisition | deliveryDetailsDate}},
          order-time-range(v-model='acquisitionRange'
              :class="{error: errors.acquisition_hour_start || errors.acquisition_hour_end}"
              :max-selectable-hour="maxAcquisitionHour"
              :show-loose-interval-discount-cta="hasPifakitService"
              )
      .arrangement-comment(v-if='workersManhoursRelations && order.arrangement && deliveryDetails.acquisition_hour_end !== undefined')
        | Монтаж закончим к {{Math.ceil(deliveryDetails.acquisition_hour_end + setupDuration) % 24 | militaryTime}}
    .section.cc-section-returning
      .label
        | Возврат
      .edit-box
        .wrap-input
          .rent-period-date(v-if="order.returning")
            | {{order.returning | deliveryDetailsDate}},
          order-time-range(v-model='returningRange'
              :disabled="deliveryDetails.acquisition_hour_end === undefined"
              :class="{error: errors.returning_hour_start || errors.returning_hour_end}"
              :min-selectable-hour="minReturningHour"
              :min-displayed-hour="minDisplayedReturningHour"
              :max-displayed-hour="29"
              :show-loose-interval-discount-cta="false"
              @prolongRentPeriodByDay="$emit('prolongRentPeriodByDay')"
              )
      .arrangement-comment(v-if='workersManhoursRelations && order.arrangement && deliveryDetails.returning_hour_end !== undefined')
        | Демонтаж закончим к {{Math.ceil(deliveryDetails.returning_hour_end + teardownDuration) % 24 | militaryTime}}
    .section(style="margin-top: 20px;")
      .label
        | Адрес
      .edit-box
        .radio-group
          el-radio.cc-delivery-is-extratown-false(v-model="deliveryDetails.isExtratown" :label="false" @change="onIsExtratownChange")
            | {{intratownDeliveryLabel}}
          el-radio.cc-delivery-is-extratown-true(v-model="deliveryDetails.isExtratown" :label="true" @change="onIsExtratownChange")
            | {{extratownDeliveryLabel}}
        div(v-if="deliveryDetails.isExtratown")
          el-input.distance-input.cc-distance-input(size="small" type="text" v-model="deliveryDetails.distance" maxlength="4" :class="{error: errors.distance}" placeholder="X")
          .kms
            | км от {{town.short_genitive_name.replace('Москвы', 'МКАД')}}
    div(v-if="isDeliveryExtratownSpecified")
      .section
        .label
        .edit-box
          input.cc-address-input(v-show="deliveryDetails.isExtratown" style="width: 100%;" placeholder="укажите адрес" type="text" v-model="deliveryDetails.address" :class="{error: errors.address}")
          .wrap-map.cc-map(v-show="!deliveryDetails.isExtratown")
            .delivery-address-block
              span.address(v-if="deliveryDetails.address" :class="{error: errors.address}")
                | {{ deliveryDetails.address }}
              span.address-buttons
                el-button(:class="{error: errors.address}" type="text" @click="beginEditingAddress" v-if="!isEditingAddress")
                  | {{ deliveryDetails.address ? 'Изменить' : 'Указать на карте'}}
            #map(v-if="isEditingAddress" style="width: 520px; height: 300px")
              span(v-if="isLoadingYMaps")
                | Загружаю Яндекс.Карты
      .section(style="margin-top: 20px;" v-if="renter.unloading_and_loading_cost !== null" )
        .label
          | Разгрузка-погрузка
        .edit-box
          order-delivery-details-unloading(:renter="renter" :delivery-details="deliveryDetails" :is-to-hall-mandatory="!!order.arrangement" :errors="errors")
      .section
        .label
          | Комментарий
        .edit-box
          .wrap-input
            input.cc-comment-input(:class='{error: errors.address_comment}' placeholder="например, название ресторана" style="width: 100%;" type="text" v-model='deliveryDetails.address_comment')
      .section
        .label
          | Контактное лицо
        .edit-box
          .wrap-input
            input.cc-contact-name-input(:class='{error: errors.contact_name}' placeholder="имя и фамилия" style="width: 100%;" type="text" v-model='deliveryDetails.contact_name')
          .wrap-input
            span.phone-eight(style='line-height: 17px;')
              | {{renter.country | phonePrefix}}
            input.cc-contact-phone-input(:class='{error: errors.contact_phone}' placeholder="XХХХХХХХХХ" maxlength="10" type="text" v-model='deliveryDetails.contact_phone')
      .section
        slot
</template>

<script>
  import Vue from 'vue'
  import OrderTimeRange from 'Shared/components/order-time-range'
  import openMap from 'Shared/lib/map'
  import {getArrangementWorkerCount, getSetupDuration, getTeardownDuration} from 'Shared/order'
  import dayjs from "dayjs";
  import OrderDeliveryDetailsUnloading from "Shared/components/order-delivery-details-unloading.vue";

  export default {
    name: 'order-delivery-details-form',
    components: {OrderDeliveryDetailsUnloading, OrderTimeRange},
    props: {
      order: Object,
      workersManhoursRelations: Array,
      town: Object,
      deliveryDetails: Object,
      errors: {},
      renter: Object,
      hasPifakitService: Boolean
    },
    data() {
      return {
        acquisitionRange: [],
        returningRange: [],
        isEditingAddress: false,
        temporaryAddress: null,
        temporaryAddressMetadata: null,
        isLoadingYMaps: false
      }
    },
    computed: {
      isRentPeriodOneDayLong() {
        if (!(this.order.acquisition && this.order.returning)) {
          return false
        }
        return dayjs(this.order.acquisition).isSame(dayjs(this.order.returning))
      },
      maxAcquisitionHour() {
        if (this.isRentPeriodOneDayLong) {
          return this.deliveryDetails.returning_hour_start
        }
        return null
      },
      minReturningHour() {
        if (this.isRentPeriodOneDayLong) {
          return this.deliveryDetails.acquisition_hour_end
        }
        return null
      },
      minDisplayedReturningHour() {
        if (this.isRentPeriodOneDayLong) {
          return 12
        }
        return null
      },
      setupDuration() {
        return getSetupDuration(this.order.line_items, this.workersManhoursRelations)
      },
      teardownDuration() {
        return getTeardownDuration(this.order.line_items, this.workersManhoursRelations)
      },
      intratownDeliveryLabel() {
        return `в пределах ${this.town.short_genitive_name}`.replace('в пределах Москвы', 'в пределах МКАД')
      },
      extratownDeliveryLabel() {
        return `вне ${this.town.short_genitive_name}`.replace('вне Москвы', 'за МКАД')
      },
      isDeliveryExtratownSpecified() {
        return this.deliveryDetails.isExtratown === true || this.deliveryDetails.isExtratown === false
      }
    },
    methods: {
      onSubmitClicked() {
        this.$emit('submit', this.deliveryDetails)
      },

      onIsExtratownChange() {
        this.deliveryDetails.distance = undefined
      },

      onSelectAddressOnMap(selectedAddress) {
        Vue.set(this.deliveryDetails, 'address', selectedAddress.temporaryAddress)
        Vue.set(this.deliveryDetails, 'address_metadata', selectedAddress.temporaryAddressMetadata)
        this.errors.address = undefined
      },

      beginEditingAddress() {
        this.isEditingAddress = true
        if (window.ymaps) {
          openMap(this.$store.getters.town.name, this.onSelectAddressOnMap, this.onAddressEditDone)
        } else {
          this.isLoadingYMaps = true
          const interval = setInterval(()=> {
            if (window.ymaps) {
              clearInterval(interval)
              this.isLoadingYMaps = false
              openMap(this.$store.getters.town.name, this.onSelectAddressOnMap, this.onAddressEditDone)
            }
          }, 1000)
        }
      },

      onAddressEditDone() {
        this.isEditingAddress = false
      },

      updateAcquisition() {
        Vue.set(this.deliveryDetails, 'acquisition_hour_start', this.acquisitionRange[0])
        Vue.set(this.deliveryDetails, 'acquisition_hour_end', this.acquisitionRange[1])
      },

      updateReturning() {
        Vue.set(this.deliveryDetails, 'returning_hour_start', this.returningRange[0])
        Vue.set(this.deliveryDetails, 'returning_hour_end', this.returningRange[1])
      }
    },
    watch: {
      'acquisitionRange': 'updateAcquisition',
      'returningRange': 'updateReturning',
    },
    created() {
      this.acquisitionRange = [this.deliveryDetails.acquisition_hour_start, this.deliveryDetails.acquisition_hour_end]
      this.returningRange = [this.deliveryDetails.returning_hour_start, this.deliveryDetails.returning_hour_end]
    }
  }
</script>

<style scoped>
  .radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .popup {
    font-size: 14px;
  }

  .label {
    width: 200px;
    color: #4A4A4A;
    line-height: 15px;
    font-weight: normal;
  }

  .wrap-input {
    color: grey;
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .section {
    display: flex;
    align-items: baseline;
    padding-bottom: 20px;
  }

  .edit-box {
    flex-grow: 1;
  }

  .block {
    display: block;
    margin-bottom: 15px;
  }

  input {
    flex-grow: 1;
    outline: none;
    min-width: 120px;
    border: none;
    background: #ffffff;
    color: #4A90E2;
    font-size: inherit;
    line-height: 15px;
    height: 15px;
    padding: 0;
  }

  .phone-eight {
    color: #4A90E2;
    font-size: inherit;
    line-height: 15px;
    margin-right: 3px;
    display: inline-block;
  }

  .error {
    color: #D0021B;
  }

  .el-button.error {
    color: #D0021B !important;
  }

  .error::-webkit-input-placeholder {
    color: #E88290;
  }

  .error:-moz-placeholder {
    color: #E88290;
  }

  .branch-select {
    margin-top: -17px;
  }

  .order-delivery-details-form >>> .el-date-editor.el-input {
    width: 100px;
  }

  .time-range-label {
    width: 87px;
  }

  .rent-period-date {
    width: 78px;
  }

  .arrangement-comment {
    color: gray;
  }

  .order-time-range.error {
    color: inherit;
  }

  .address, .temporary-address {
    margin-right: 10px;
  }

  .distance-input {
    display: inline-block;
    width: 70px;
  }

  .kms {
    display: inline-block;
    margin-left: 0.75em;
  }

  #map {
    margin-top: 10px;
  }
</style>
