<template lang="pug">
  div
    meta(itemprop="price" :content="price")
    meta(itemprop="priceCurrency" :content="country | currencySymbolISO4217")
    link(itemprop="availability" href="http://schema.org/InStock")
</template>

<script>
  export default {
    name: "agv-article-meta",
    props: {
      price: Number,
      country: String
    }
  }
</script>

<style scoped>

</style>
