<template lang="pug">
  .form-agv-details
    .section(v-if="!isInPasswordChangeMode")
      .label
        | Город
      .edit-box
        el-select.cc-town-select(v-if="towns.length > 1" v-model='customer.town_id')
          el-option(v-for="item in towns"
          :value-key="item.id"
          :key="item.id"
          :label="item.name"
          :value="item.id")
        span(v-else)
          | {{ towns[0].name }}
    .section(v-if="isInAggregatorMode")
      .label
        | Логин
      .edit-box
        .wrap-input
          | {{customer.login}}
    .section(v-else)
      .label
        | Логин и пароль
      .edit-box
        .wrap-input
          input.cc-login-input(:class='{error: errors.login}' placeholder="example@mail.ru" style="width: 130px;" type="text" v-model='customer.login')
        .wrap-input
          input.cc-password-input(:class='{error: errors.password}' placeholder="пароль" type="text" v-model='customer.password')
    .section(v-if="!isInPasswordChangeMode")
      .label
        | Контакты
      .edit-box
        .wrap-input
          input.cc-first-name-input(:class='{error: errors.first_name}' placeholder="имя" type="text" v-model='customer.first_name')
        .wrap-input
          input.cc-last-name-input(:class='{error: errors.last_name}' placeholder="фамилия" type="text" v-model='customer.last_name')
        .wrap-input
          span.phone-eight(style='line-height: 17px;')
            | {{countryCode | phonePrefix}}
          input.cc-phone-input(:class='{error: errors.phone}' placeholder="XXXXXXXXXX" maxlength="10" type="text" v-model='customer.phone')
    .section(v-if="!isInPasswordChangeMode")
      .label

      .edit-box
        .block
          .wrap-input(v-if="!customer.id")
            el-checkbox(v-model="customer.is_professional")
              | Я – специалист праздничной сферы
          .wrap-input(v-if="customer.is_professional")
            input(:class='{error: errors.company_name}' placeholder="название компании" type="text" v-model='customer.company_name')
        .block(v-if="isInAggregatorMode")
          .wrap-input
            el-checkbox(v-model="customer.is_subscribed_to_newsletter")
              | Я хочу получать новости Пифакита
        .block(v-else)
          .wrap-input
            el-checkbox(v-model="customer.wants_to_receive_news")
              | Я хочу получать новости {{renterGenitiveName}}
        .block(v-if="customer.id")
          .wrap-input
            el-checkbox(v-model="customer.wants_to_receive_originals")
              | Я хочу получать оригиналы документов
          .wrap-input(v-if="customer.wants_to_receive_originals")
            input(:class='{error: errors.location}' placeholder="город" type="text" v-model='customer.location')
          .wrap-input(v-if="customer.wants_to_receive_originals")
            input(:class='{error: errors.address}' placeholder="индекс, улица, дом, офис" type="text" v-model='customer.address')
</template>

<script>
  export default {
    name: 'form-agv-details',
    props: {
      countryCode: String,
      renterGenitiveName: String,
      customer: Object,
      errors: Object,
      isInPasswordChangeMode: Boolean,
      isInAggregatorMode: Boolean
    },
    computed: {
      towns() {
        return this.$store.state.town.towns
      }
    },
    watch: {
      'customer.login': 'clearError',
      'customer.password': 'clearError',
      'customer.first_name': 'clearError',
      'customer.last_name': 'clearError',
      'customer.is_professional': 'clearError',
      'customer.company_name': 'clearError',
      'customer.town': 'clearError'
    },
    methods: {
      clearError() {
        this.$emit('clearError')
      }
    }
  }
</script>

<style scoped>
  .popup {
    font-size: 14px;
  }

  .label {
    width: 216px;
    color: #4A4A4A;
    line-height: 15px;
  }

  .wrap-input {
    color: grey;
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
  }

  .section {
    display: flex;
    align-items: baseline;
    padding-bottom: 17px;
  }

  .edit-box {
    width: 100%;
  }

  .block {
    display: block;
    margin-bottom: 15px;
  }

  input {
    flex-grow: 1;
    outline: none;
    min-width: 120px;
    border: none;
    background: #ffffff;
    color: #4A90E2;
    font-size: inherit;
    line-height: 18px;
    height: 18px;
    padding: 0;
  }

  .big-btn {
    color: #4A4A4A;
    font-size: inherit;
    line-height: 51px;
    display: block;
    border: 1px solid #BCBCBC;
    width: 288px;
    height: 53px;
    text-align: center;
    transition: ease 0.4s 0s;
    border-radius: 3px;
  }

  .big-btn.active {
    border-color: #7799E4;
    box-shadow: 0 0 0 1px #7799E4;
  }

  .phone-eight {
    color: #4A90E2;
    font-size: inherit;
    line-height: 15px;
    margin-right: 3px;
    display: inline-block;
  }

  .btn-reg {
    font-size: inherit;
    line-height: 15px;
    background: #4990E2;
    padding: 11px 16px 10px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: block;
    color: #ffffff;
    float: right;
  }

  .error {
    color: #D0021B;
  }

  .error::-webkit-input-placeholder {
    color: #E88290;
  }

  .error:-moz-placeholder {
    color: #E88290;
  }

  .branch-select {
    margin-top: -17px;
  }

  .popup >>> .el-checkbox {
    display: block;
  }

  .popup >>> .el-checkbox__label {
    color: #4A90E2;
  }

  .popup >>> .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #4A90E2;
  }
</style>
