export function setupProgressBar($Progress, axios) {
  axios.interceptors.request.use((config) => {
    if (config.method === 'patch' || config.method === 'post' || config.method === 'put' || config.method === 'delete') {
      $Progress.start()
    }
    return config
  }, (error) => {
    $Progress.fail()
    return Promise.reject(error)
  })

  axios.interceptors.response.use((response) => {
    if (response.config.method === 'patch' || response.config.method === 'post' || response.config.method === 'put' || response.config.method === 'delete') {
      $Progress.finish()
    }
    return response
  }, (error) => {
    $Progress.fail()
    return Promise.reject(error)
  })
}
