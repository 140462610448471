import { render, staticRenderFns } from "./order-arrangement-by-self.vue?vue&type=template&id=785e9711&scoped=true&lang=pug&"
import script from "./order-arrangement-by-self.vue?vue&type=script&lang=js&"
export * from "./order-arrangement-by-self.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785e9711",
  null
  
)

export default component.exports