<template lang="pug">
  table.agv-customer-orders-table.table(style="border-collapse: separate;")
    tr
      th
      th(v-if="isSelectingOrders")
      th.cell
        | Заказ
      th.cell
        | Период аренды
      th.cell
        | Стоимость
      th.cell(v-if="showRenter")
        | Прокатчик
      th.cell
        | Состояние
    tr.row(v-for="order in limitedOrders" @click="onOrderClick(order, $event)" :class="{'non-selectable': isSelectingOrders && !isSelectable(order)}")
      td.bracket-cell(:rowspan="ordersInThisAggregatorDelivery(order.aggregator_delivery_id, limitedOrders)"
        v-if="getDeliveryPath && order === firstOrderByAggregatorDeliveryId(order.aggregator_delivery_id, limitedOrders)")
        el-button.delivery-btn.cc-delivery-link-btn(circle @click.stop="$router.push(getDeliveryPath(order.aggregator_delivery_id))")
          | Д
      td(v-if="!order.aggregator_delivery_id")
        |
      td.checkbox-cell(v-if="isSelectingOrders")
        el-checkbox(v-model="order.is_selected")
      td.cell
        span(v-if="isSelectingOrders")
          | {{order.code}}
        router-link(v-else :to="orderUrl(order.id, order.renter_id, order.town_id)")
          | {{order.code}}
      td.cell
        | {{[order.acquisition, order.returning] | dateRange}}
      td.cell
        | {{order.total | money(countryCode)}}
      td.cell.renter-name-cell(v-if="showRenter")
        | {{(getRenter(order.renter_id) || {}).short_commercial_name}}
      td.cell.state-cell(:class="{'state-payment-due-today': isPaymentDueToday(order)}")
        .order-state-container
          el-popover.prolong-payment-popover(v-if='canProlongPaymentOf(order)'
          :disabled="isProlongationDisabled || order.is_prolongation_disabled"
          popper-class='page-rsv-order-list-popper'
          placement="top-start"
          width="300"
          trigger="hover")
            el-button(type="text" @click="prolongPaymentPeriod(order)")
              | Продлить срок оплаты до {{ maxPaymentDue(order) | date('dd.MM.yy') }}
            .order-state(slot="reference")
              | {{order | stateToWords(countryCode)}}
          .order-state(v-else)
            | {{order | stateToWords(countryCode)}}
          .order-link(v-if='areActionsAvailable(order)')
            span(v-if="order.state === 'payment_pending'")
              | Оплатить
            span(v-else)
              span.order-text-btn(@click.stop="hide(order)")
                | Удалить
              span.order-text-btn
                | Восстановить
</template>

<script>
  import axios from 'axios'
  import dayjs from 'dayjs'

  import Vue from 'vue'

  import IconMerge from 'Aggregator/sprites/merge.svg'

  import minMax from 'dayjs/plugin/minMax'
  dayjs.extend(minMax)

  export default {
    name: "agv-customer-orders-table",
    props: {
      orders: Array,
      countryCode: String,
      showRenter: Boolean,
      isProlongationDisabled: Boolean,
      isSelectingOrders: Boolean,
      getOrdersRouteUrl: Function,
      getOrdersUrl: Function,
      getIsOrderSelectable: Function,
      getDeliveryPath: Function
    },
    components: {IconMerge},
    computed: {
      limitedOrders() {
        return _.orderBy(
          _.filter(this.orders, o => !o.is_hidden),
          ['acquisition', 'returning', 'aggregator_delivery_id'],
          ['desc', 'desc', 'asc']
        )
      }
    },
    methods: {
      maxPaymentDue(order) {
        if (!order.payment_due_date) {
          return dayjs(order.payment_due_and_required_payment[0])
        }
        return dayjs.min(dayjs(order.payment_due_and_required_payment[0]), dayjs(order.payment_due_maximum))
      },
      firstOrderByAggregatorDeliveryId(aggregatorDeliveryId, orders) {
        if (!aggregatorDeliveryId) {
          return false
        }
        return _.find(orders, o => o.aggregator_delivery_id === aggregatorDeliveryId)
      },
      ordersInThisAggregatorDelivery(aggregatorDeliveryId, orders) {
        return _.filter(orders, o => o.aggregator_delivery_id === aggregatorDeliveryId).length
      },
      isSelectable(order) {
        if (!this.getIsOrderSelectable) {
          return true
        }

        return this.getIsOrderSelectable(order)
      },
      onOrderClick(order, event) {
        if (this.isSelectingOrders) {
          Vue.set(order, 'is_selected', !order.is_selected)
          return
        }
        if (event.target.tagName !== 'A') {
          this.$router.push(this.orderUrl(order.id, order.renter_id, order.town_id))
        }
      },
      hide(order) {
        axios.patch(this.getOrdersUrl(order.renter_id) + `/${order.id}/hide`)
          .then(() => {
            Vue.set(order, 'is_hidden', true)
          })
      },
      orderUrl(orderId, renterId, townId) {
        return this.getOrdersRouteUrl(renterId, townId) + `/${orderId}`
      },
      areActionsAvailable(order) {
        return _.includes(
          ['payment_pending', 'cancelled_over_delinquency', 'cancelled_before_starting'],
          order.state
        )
      },
      isPaymentDueToday(order) {
        return dayjs(order.payment_due).diff(dayjs(new Date()), 'days') === 0
      },
      canProlongPaymentOf(order) {
        if (dayjs(new Date()).diff(dayjs(order.payment_due_maximum), 'days') < 0) {
          return false
        }

        if (!order.payment_due_and_required_payment) {
          return false
        }

        if (order.required_payment !== '50') {
          return false
        }

        const calculatedPaymentDueDate = dayjs(order.payment_due_and_required_payment[0])
        const today = dayjs().startOf('day')
        const actualPaymentDueDate = dayjs(order.payment_due)
        return calculatedPaymentDueDate.isAfter(today) && calculatedPaymentDueDate.isAfter(actualPaymentDueDate)
      },
      prolongPaymentPeriod(order) {
        axios.patch(this.getOrdersUrl(order.renter_id) + `/${order.id}/prolong_payment_period`)
          .then(({data: updatedOrder}) => {
            order.payment_due = updatedOrder.payment_due
            order.required_payment = updatedOrder.required_payment
          })
      },
      getRenter(id) {
        return this.$store.state.rentersById[id]
      }
    }
  }
</script>

<style scoped>
  .checkbox-cell {
    width: 40px;
  }

  .cell {
    padding-right: 20px;
  }

  .cell:nth-child(1) {
    width: 15%;
  }

  .cell:nth-child(2) {
    width: 20%;
  }

  .cell:nth-child(3) {
    width: 12%;
  }

  .cell.renter-name-cell {
    width: 10%;
  }

  td.state-cell {
    width: 366px;
  }

  td.state-cell.state-payment-due-today {
    color: #d0011b;
  }

  .order-state-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .order-link {
    font-size: 12px;
    margin-left: 20px;
  }

  .page-rsv-order-list-popper {
    padding: 10px 20px;
  }

  .order-text-btn {
    padding: 10px;
  }

  .order-text-btn:hover {
    color: #888;
    transition: color 300ms;
  }

  .checkbox-cell {
    padding-right: 5px;
    padding-left: 5px;
  }

  .bracket-cell {
    max-width: 20px;
    border: 2px solid #bbbbbb;
    border-right:none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 0;
    vertical-align: middle;
  }

  .delivery-btn {
    background: #bbb;
    color: white;
    position: relative;
    left: -40px;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    padding: 5px !important;
  }

  .delivery-btn:hover {
    background: white;
  }

  .delivery-btn >>> .el-icon-truck {
    transform: scale(1.3);
  }

  .non-selectable {
    pointer-events: none;
  }

  .non-selectable td {
    color: grey !important;
  }

  .non-selectable >>> .el-checkbox {
    display: none;
  }
</style>
