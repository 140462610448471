<template lang="pug">
  .agv-article-size
    .size(v-if="shouldDisplay('diameter')" itemprop="width" itemscope itemtype="http://schema.org/QuantitativeValue")
      | Диаметр:
      span(itemprop="value")
        |  {{article.diameter}}
      |  см
      meta(itemprop="unitCode" content="CMT")
    .size(v-if="shouldDisplay('height')" itemprop="height" itemscope itemtype="http://schema.org/QuantitativeValue")
      | Высота:
      span(itemprop="value")
        |  {{article.height}}
      |  см
      meta(itemprop="unitCode" content="CMT")
    .size(v-if="shouldDisplay('length')" itemprop="length" itemscope itemtype="http://schema.org/QuantitativeValue")
      | Длина:
      span(itemprop="value")
        |  {{article.length}}
      |  см
      meta(itemprop="unitCode" content="CMT")
    .size(v-if="shouldDisplay('width')" itemprop="width" itemscope itemtype="http://schema.org/QuantitativeValue")
      | Ширина:
      span(itemprop="value")
        |  {{article.width}}
      |  см
      meta(itemprop="unitCode" content="CMT")
    .size(v-if="shouldDisplay('depth')" itemprop="depth" itemscope itemtype="http://schema.org/QuantitativeValue")
      | Глубина:
      span(itemprop="value")
        |  {{article.depth}}
      |  см
      meta(itemprop="unitCode" content="CMT")
</template>

<script>
  export default {
    props: {
      article: Object
    },
    name: 'agv-article-size',
    methods: {
      shouldDisplay(dimension) {
        switch (dimension) {
          case 'diameter':
            return (this.article.shape === 'круг') || (this.article.shape === 'цилиндр')
          case 'height':
            return (this.article.shape === 'цилиндр') || (this.article.shape === 'параллелепипед')
          case 'length':
            return (this.article.shape === 'линия') || (this.article.shape === 'прямоугольник')
          case 'width':
            return (this.article.shape === 'параллелепипед') || (this.article.shape === 'прямоугольник')
          case 'depth':
            return this.article.shape === 'параллелепипед'
        }
      }
    }
  }
</script>

<style scoped>

</style>
