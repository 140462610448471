<template lang="pug">
  el-dialog(title="Детали доставки" width="900px" top="5vh" :visible="true" :before-close="handleClose")
    order-delivery-details-form(:delivery-details="deliveryDetails"
    :order="order"
    :branch="branch"
    :renter="renter"
    :workers-manhours-relations="renter.workers_manhours_relations"
    :town="town"
    :errors="errors"
    :has-pifakit-service="hasPifakitService"
    @prolongRentPeriodByDay="onProlongRentPeriodByDay")
      el-button.cc-btn-done(type="success" @click="onFormSubmitted(deliveryDetails)")
        | Готово
</template>

<script>
  import _ from 'lodash'
  import axios from 'axios'
  import eventBus from 'Shared/eventBus'
  import OrderDeliveryDetailsForm from 'Shared/components/order-delivery-details-form'
  import dayjs from "dayjs";

  export default {
    name: 'order-delivery-details-modal',
    components: {OrderDeliveryDetailsForm},
    props: {
      deliveryDetails: Object,
      order: Object,
      branch: Object,
      renter: Object,
      town: Object,
      getOrdersUrl: Function,
      hasPifakitService: Boolean
    },
    data() {
      return {
        errors: {}
      }
    },
    methods: {
      onProlongRentPeriodByDay() {
        this.$store.dispatch(
            'order/setRentPeriod',
            {
              range: [this.order.acquisition, dayjs(this.order.returning).add(1, 'day')],
              renterId: this.renter.id
            }
        )
      },
      onFormSubmitted(deliveryDetails) {
        const relevantDetails = _.pick(deliveryDetails,
          [
            'contact_name',
            'contact_phone',
            'acquisition_hour_start',
            'acquisition_hour_end',
            'returning_hour_start',
            'returning_hour_end',
            'address',
            'address_comment',
            'address_metadata',
            'tract',
            'distance',
            'isExtratown',
            'isElevatorPresent',
            'is_to_hall',
            'carrying_distance',
            'manual_lifting_floors'
          ]
        )

        axios.get(this.getOrdersUrl(this.renter.id) + '/validate_delivery', {params: relevantDetails}).then(
          ({data: delivery}) => {
            delivery.address_metadata = deliveryDetails.address_metadata
            this.$emit('validated', delivery)
          },
          ({response}) => {
            this.errors = response.data
          }
        )
      },
      handleClose() {
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>
  .order-delivery-details-modal >>> .el-dialog__header {
    padding: 50px 70px 10px;
  }

  .order-delivery-details-modal >>> .el-dialog__body {
    padding: 20px 70px 50px;
  }
</style>
