<template lang="pug">
  .order-arrangement-by-renter.order-service-row.cc-order-arrangement-by-renter
    .name-cell
      .service-title
        .service-name
          | Сетап
      .label-mandatory(v-if="renter.is_arrangement_mandatory")
        | Обязательный
      el-popover(v-else-if='articlesWithMandatoryArrangement.length > 0'
      placement="top-start"
      popper-class="cc-popover-articles-with-mandatory-arrangement"
      width="400"
      trigger="hover")
        .explanation
          | Некоторые изделия мы монтируем сами,
          br
          | потому что они сложные в сборке.
          br
          | В эту смету добавлены такие изделия:
        ul.articles-with-mandatory-services.cc-articles-with-mandatory-arrangement
          li(v-for='article in articlesWithMandatoryArrangement')
            | {{article.name}}
        .label-mandatory(slot="reference")
          | Обязательный
      el-button.cc-btn-remove-arrangement(v-else-if="isEditing" type="text" @click="$emit('remove')")
        | Удалить услугу
    .service-description-cell
      .service-description
        p.service-description__text
          | Продолжительность работ
        p.service-description__text
          | Монтаж – {{setupWorkers | decline(['человек','человека','человек'])}}, {{setupDuration | ceilToNearestHalf | spellTime}},
          | демонтаж – {{teardownWorkers  | decline(['человек','человека','человек'])}}, {{teardownDuration | ceilToNearestHalf | spellTime}}
    .total-price-cell.cc-total-price
      | {{price | money(renter.country)}}

</template>

<script>
import {
  getSetupDuration,
  getTeardownDuration,
  getArrangementPrice,
  getArticlesWithMandatoryArrangement,
  getServiceDiscountPortion,
  getArrangementWorkerCount,
  getSetupAmount,
  getTeardownAmount, getRequiredArrangementWorkerCount
} from 'Shared/order'

  import {arrangementPrice as pifakitServiceArrangementPrice} from "Shared/pifakitService";

  export default {
    name: 'order-arrangement-by-renter',
    props: {
      order: Object,
      customer: Object,
      lineItems: Array,
      branch: Object,
      renter: Object,
      pifakitService: Object,
      isEditing: Boolean
    },
    computed: {
      setupAmount() {
        return getSetupAmount(this.lineItems)
      },
      teardownAmount() {
        return getTeardownAmount(this.lineItems)
      },
      setupWorkers() {
        return Math.max(
          getRequiredArrangementWorkerCount(this.lineItems),
          getArrangementWorkerCount(this.setupAmount, this.renter.workers_manhours_relations)
        )
      },
      teardownWorkers() {
        return Math.max(
          getRequiredArrangementWorkerCount(this.lineItems),
          getArrangementWorkerCount(this.teardownAmount, this.renter.workers_manhours_relations)
        )
      },
      setupDuration() {
        return this.setupAmount / this.setupWorkers
      },
      teardownDuration() {
        return this.teardownAmount / this.teardownWorkers
      },
      articlesWithMandatoryArrangement() {
        return getArticlesWithMandatoryArrangement(_.map(this.lineItems, 'article'))
      },
      price() {
        if (this.pifakitService) {
          return pifakitServiceArrangementPrice(
            this.lineItems,
            this.pifakitService,
            this.renter.workers_manhours_relations,
            this.renter.service_discount
          )
        }
        return getArrangementPrice(
          this.lineItems,
          this.branch.arrangement_price_multiplier,
          this.renter
        )
      }
    }
  }
</script>

<style scoped>
  .total-price-cell {
    width: 220px !important;
  }
</style>
