<template lang="pug">
  .agv-order-article(itemprop="offers" itemscope itemtype="http://schema.org/Offer")
    agv-article-meta(:price="article.price" :country="renter.country")
    agv-basic-article-info-with-magnet(v-if="showMagnet"
      :price="article.price"
      :base-rent-duration="baseRentDurationForInfo"
      :renter="renter")
    agv-basic-article-info(v-else
      :price="article.price"
      :price-with-discount="article.price_with_discount"
      :show-discount="showDiscount"
      :base-rent-duration="baseRentDurationForInfo"
      :renter="renter")
    hr
    .rent-period-container(v-if="showDatepicker")
      order-datepicker.cc-datepicker(:value="range"
        @input="emitRange"
        :can-select-urgent-dates="false"
        :is-date-disabled-predicate="isDateDisabled")
    .order-info(v-if="available === 0")
      | Недоступно на период
    .order-info(v-else)
      | Доступно {{available}} шт.
    .order-btn-container
      agv-add-to-order-button(:article="article" :order="order" :available="available" :renter-id="renter.id" size="large")
</template>

<script>
  import AgvAddToOrderButton from 'Aggregator/components/agv-add-to-order-button'
  import OrderDatepicker from 'Shared/components/order-datepicker'
  import AgvBasicArticleInfo from 'Aggregator/components/agv-basic-article-info'
  import AgvBasicArticleInfoWithMagnet from 'Aggregator/components/agv-basic-article-info-with-magnet'
  import AgvArticleMeta from 'Aggregator/components/agv-article-meta'

  const getRentPeriodRange = (order) => {
    if (!(order.acquisition && order.returning)) {
      return null
    }
    return [order.acquisition, order.returning]
  }

  export default {
    name: 'agv-order-article',
    components: {
      AgvArticleMeta,
      AgvBasicArticleInfoWithMagnet, AgvAddToOrderButton, AgvBasicArticleInfo, OrderDatepicker
    },
    methods: {
      emitRange(value) {
        this.$emit('range-changed', value)
      }
    },
    computed: {
      range() {
        return getRentPeriodRange(this.order)
      },
      setOrProductSpecifiedBaseRentDuration() {
        if (this.article.type === 'Product') {
          return this.article.base_rent_duration
        }

        const durations = _.map(this.article.components, 'base_rent_duration')

        if (_.every(durations, _.isNil)) {
          return null
        }

        return _.min(durations)
      },
      baseRentDurationForInfo() {
        return this.setOrProductSpecifiedBaseRentDuration || this.renter.base_rent_duration
      }
    },
    props: {
      article: Object,
      available: Number,
      order: Object,
      renter: Object,
      isDateDisabled: Function,
      showMagnet: Boolean,
      showDiscount: Boolean,
      showDatepicker: Boolean
    }
  }
</script>

<style scoped>
  .agv-order-article {
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    padding: 31px;
    min-width: 250px;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid #CFCFCF;
  }

  .rent-period-container {
    margin-bottom: 12px;
  }

  .order-btn-container {
    margin-top: 62px;
  }

  .agv-order-article .agv-add-to-order-button {
    max-width: none;
  }
</style>
