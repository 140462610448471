<template lang="pug">
  .breadcrumbs
    router-link.breadcrumb.breadcrumb-active(:to="item.url" v-for="item in itemsWithUrl" :key="item.url" :class="{'with-arrow': showArrow}")
      | {{item.name}}
    .breadcrumb.breadcrumb-inactive(v-for="item in itemsWithoutUrl")
      | {{item.name}}
</template>

<script>
  export default {
    name: 'agv-breadcrumbs',
    props: {
      items: Array,
      showArrow: Boolean
    },
    computed: {
      itemsWithUrl() {
        return this.items.filter(item => item.url)
      },
      itemsWithoutUrl() {
        return this.items.filter(item => !item.url)
      }
    }
  }
</script>

<style scoped>
  .breadcrumbs {
    margin-bottom: 40px;
    font-size: 13px;
  }
  .breadcrumb {
    color: #9b9b9b;
    display: inline-block;
  }
  .breadcrumb-active:hover {
    color: #666;
  }

  .breadcrumb-inactive {
    cursor: default;
  }

  .breadcrumb::after {
    content: '—';
    display: inline-block;
    margin: 0 5px;
  }
  .breadcrumb:last-child::after {
    display: none;
  }

  .with-arrow::before {
    content: '←';
    display: inline-block;
    margin-right: 5px;
  }
</style>
