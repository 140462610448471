<template lang="pug">
  .content.clearfix(v-if='article && renter && branch' itemscope itemtype="http://schema.org/Product")
    agv-breadcrumbs(:items="breadcrumbItems")
    .article-container
      .photos-container
        agv-photo-selector(v-model="activePhoto" :photos="photos")
      .active-photo-wrapper
        div(v-if="activePhoto.is_infringing")
          i.el-icon-picture
          |
          | Фотография удалена
        div(v-else)
          img(itemprop="image" :title="photo.title || article.name" v-for="photo in photos"
            :key="photo.id" v-show="activePhoto === photo" :src="photo.url"
            v-img="{group: 'photos', changed: onGalleryActivePhotoChanged, cursor: 'zoom-in'}")
      .right-block
        h1.article-name.page-title(itemprop="name")
          | {{article.name | capitalize}}
        .tools-block
          .tools-container.tools-container__features
            agv-key-features(:article="article")
          .tools-container
            agv-order-article(@range-changed="onRangeChanged"
              :article="article"
              :show-discount="showDiscount"
              :renter="renter"
              :available="available"
              :order="order"
              :show-datepicker="true"
              :is-date-disabled="isDateDisabled")
    .additional-section(v-if='additionalArticles && filteredAdditionalArticles.length > 0')
      .title-additional
        | Дополняющие изделия
      .catalog(style='margin-left: -6px; margin-right: -6px;')
        agv-article(v-for="article in orderedAdditionalArticles" :key="article.id"
          :town-id="article.town_id"
          :article="article"
          :show-discount="showDiscount"
          :renter="renter"
          is-in-wide-view)
    .additional-section(v-if='otherArticlesOfSameCategory && otherArticlesOfSameCategory.length > 0')
      .title-additional
        | Еще {{category.name | lowercase }}
      .catalog(style='margin-left: -6px; margin-right: -6px;')
        agv-article(v-for="article in otherArticlesOfSameCategory" :key="article.id"
          :town-id="article.town_id"
          :article="article"
          :show-discount="showDiscount"
          :renter="renter"
          is-in-wide-view)
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import dayjs from 'dayjs'

  import AgvKeyFeatures from 'Aggregator/components/agv-key-features'
  import AgvPhotoSelector from 'Aggregator/components/agv-photo-selector'
  import AgvBreadcrumbs from 'Aggregator/components/agv-breadcrumbs'
  import AgvArticle from 'Aggregator/components/agv-article'

  import AgvOrderArticle from 'Aggregator/components/agv-order-article'

  import {toRenterSiteArticles} from 'RenterSite/shared/renterSiteArticles'
  import NamedArticleSizes from 'Shared/NamedArticleSizes'

  import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'
  import {getCurrentOrder, isDateDisabledForOrder} from 'RenterSite/lib/order'

  export default {
    name: 'page-rsv-article',
    components: {AgvArticle, AgvBreadcrumbs, AgvKeyFeatures, AgvPhotoSelector, AgvOrderArticle},
    computed: {
      showDiscount() {
        return this.$store.getters.showDiscount
      },
      order() {
        return getCurrentOrder()
      },
      articleWithGeneralInfo() {
        return this.$store.getters['articles/getByRenterIdAndArticleId'](
            this.$store.state.renter.id,
            parseInt(this.article_id)
        )
      },
      metaTitle() {
        return this.articleWithGeneralInfo.seo_title ||
            `${this.articleWithGeneralInfo.name} в аренду за ${this.articleWithGeneralInfo.price_in_town}`
      },
      metaDescription() {
        return this.articleWithGeneralInfo.seo_description || this.metaTitle
      },
      section() {
        if (!this.category) {
          return
        }
        return this.$store.getters.sectionsById[this.category.section_id]
      },
      category() {
        return this.$store.state.categoriesById[this.article.category_id]
      },
      available() {
        return getAvailableForPeriodOrTotal(this.article, this.renter.id, this.$store)
      },
      availabilityKey() {
        return this.order.id || 'new'
      },
      breadcrumbItems() {
        return _.compact([
          {
            name: 'Каталог',
            url: '/catalogue'
          },
          (this.section ? {
            name: this.section.name,
            url: `/catalogue/section/${this.section.id}`
          } : null),
          (this.category ? {
            name: this.category.name,
            url: `/catalogue/category/${this.category.id}`
          } : null)
        ])
      },
      otherArticlesOfSameCategory() {
        return _.take(
          _.orderBy(
            _.filter(
              _.filter(
                this.$store.getters['relevantArticles'],
                a => a.category_id === this.article.category_id
              ),
              a => a.id !== this.article.id && a.total_available && a.is_offered
            ),
            'position_in_category'
          ),
          20
        )
      },
      componentPhotos() {
        return _.map(
            this.article.components,
            (a) => {
              return {
                id: a.id,
                url: `https://pifakit.ru${a.first_photo.large.url}`,
                thumbnailUrl: `https://pifakit.ru${a.first_photo.thumbnail.url}`,
                description: null,
                type: 'product'
              }
            }
        )
      },
      articlePhotos() {
        return _.map(
            _.take(_.concat(this.article.photos, this.article.gallery_photos), 6),
            (p) => {
              return {
                id: p.id,
                url: `https://pifakit.ru${p.image.large.url}`,
                thumbnailUrl: `https://pifakit.ru${p.image.thumbnail.url}`,
                type: this.article.type.toLowerCase(),
                title: p.seo_title,
                is_infringing: p.is_infringing
              }
            }
        )
      },
      photos() {
        return _.concat(this.articlePhotos, this.componentPhotos)
      },
      renter() {
        return this.$store.state.renter
      },
      branch() {
        return this.$store.getters.branch
      },
      town() {
        return this.$store.state.town.current
      },
      additionalArticles() {
        return _.filter(this.article.children, a => a.is_offered)
      },
      filteredAdditionalArticles() {
        return _.filter(
          this.additionalArticles,
          a => a.is_offered && a.total_available
        )
      },
      orderedAdditionalArticles() {
        return _.orderBy(
          this.filteredAdditionalArticles,
          'position_in_category'
        )
      }
    },
    data() {
      return {
        availabilitySource: this.$store.state.availability.source,
        article: null,
        activePhoto: null,
        range: _.clone(this.$store.getters['order/range'])
      }
    },
    metaInfo() {
      return {
        title: this.metaTitle,
        meta: [{vmid: 'description', name: 'description', content: this.metaDescription}]
      }
    },
    methods: {
      onRangeChanged(range) {
        this.$store.dispatch(
          'order/setRentPeriod',
          {
            range: range,
            renterId: this.$store.state.renter.id
          }
        )
      },
      isDateDisabled(date) {
        return isDateDisabledForOrder(date, getCurrentOrder())
      },
      assignArticle(article) {
        toRenterSiteArticles([article], this.$store.state)

        const childrenIds = _.map(article.children, 'id')
        article.children = _.filter(
          this.$store.getters['articles/getAllByRenterId'](this.$store.state.renter.id),
          a => _.includes(childrenIds, a.id)
        )

        Object.assign(article, new NamedArticleSizes(article).value)
        this.article = article
        this.activePhoto = this.photos[0]
      },
      fetchArticle() {
        this.article = null
        if (this.$store.state.$isServer) {
          this.assignArticle(this.$store.state.SPECIAL_STATE)
        } else {
          axios.get(`/api/articles/${this.article_id}`)
            .then(({data: article}) => {
              this.assignArticle(article)
            })
        }
      },
      onGalleryActivePhotoChanged(index) {
        this.activePhoto = this.photos[index]
      }
    },
    props: {
      article_id: String
    },
    watch: {
      article_id: {
        handler: 'fetchArticle',
        immediate: true
      }
    }
  }
</script>

<style scoped>
  img {
    max-width: 100%;
    max-height: 520px;
  }

  .content {
    font-size: 13px;
  }

  .article-container {
    display: flex;
  }

  .photos-container {
    flex-basis: 160px;
    flex-shrink: 1;
    min-width: 100px;
    display: flex;
  }

  .active-photo-wrapper {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-grow: 1;
    padding-bottom: 40px;
    padding-right: 40px;
  }

  .right-block {
    flex-basis: 400px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .tools-block {
    display: flex;
    justify-content: space-between;
  }

  .tools-container {
    /*width: 249px;*/
    max-width: 250px;
  }

  .tools-container__features {
    flex-grow: 1;
    margin-right: 20px;
    min-width: 180px;
  }

  .title-additional {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1.5em;
  }

  .additional-section {
    margin-top: 40px;
  }

  .catalog {
    display: flex;
    flex-wrap: wrap;
  }

  .catalog .ag-article {
    flex-basis: 25%;
  }

  .content {
    margin-bottom: 60px;
  }

  .article-name {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 28px;
  }
</style>
