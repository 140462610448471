<template lang="pug">
  .order-arrangement-by-self.order-service-row
    .name-cell
      el-button.cc-btn-add-arrangement(type="text" @click="$emit('add')" v-if='renter.is_arrangement_offered && isEditing')
        | Добавить сетап
    .service-description-cell
      .service-description
        .service-description__title(v-if='!renter.is_arrangement_offered')
          | Объем работы
        .service-description__title(v-else-if='!isEditing')
          | Самостоятельный сетап
        .service-description__text
          span(v-if='renter.is_arrangement_offered')
            | Объем работы:
            |
          | {{renter.is_arrangement_offered ? 'м':'М'}}онтаж – {{setupAmount | ceilToNearestHalf | decline(['человекочас','человекочаса','человекочасов'])}},
          | демонтаж – {{teardownAmount | ceilToNearestHalf | decline(['человекочас','человекочаса','человекочасов'])}}
</template>

<script>
  import {getSetupAmount, getTeardownAmount} from 'Shared/order'

  export default {
    props: {
      lineItems: Array,
      renter: Object,
      isEditing: Boolean
    },
    name: 'order-arrangement-by-self',
    computed: {
      setupAmount() {
        return getSetupAmount(this.lineItems)
      },
      teardownAmount() {
        return getTeardownAmount(this.lineItems)
      },
    }
  }
</script>

<style scoped>

</style>
