<template lang="pug">
  agv-customer-transactions-page-content(:renter="renter"
  :customer="customer"
  :transactions="transactions"
  :get-orders-route-url="getOrdersRouteUrl"
  :get-customer-bills-url="getCustomerBillsUrl"
  :get-last-wire-transfer-bill-details-url="getLastWireTransferBillDetailsUrl")
    h1.page-title.page-title_top
      | Последние денежные операции
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import eventBus from 'Shared/eventBus'

  import {getOrdersRouteUrl, getCustomerBillsUrl, getLastWireTransferBillDetailsUrl} from 'RenterSite/lib/order'
  import AgvCustomerTransactionsPageContent from 'Aggregator/components/agv-customer-transactions-page-content'

  export default {
    name: 'page-rsv-customer-transactions-list',
    components: {AgvCustomerTransactionsPageContent},
    data() {
      return {
        transactions: null,
        getOrdersRouteUrl,
        getCustomerBillsUrl,
        getLastWireTransferBillDetailsUrl
      }
    },
    watch: {
      customer: {
        handler: 'onCustomerChange',
        immediate: true
      }
    },
    computed: {
      customer() {
        return this.$store.state.customer.current
      },
      renter() {
        return this.$store.state.renter
      }
    },
    methods: {
      onCustomerChange() {
        if (!this.customer.id) {
          return
        }

        this.fetchTransactions()
      },
      fetchTransactions() {
        axios.get('/api/customer/customer_transactions')
          .then(({data: transactions}) => {
            this.transactions = transactions
          })
      }
    },
    created() {
      eventBus.$on('renterCustomer:retrieveSurplus', this.fetchTransactions)
    }
  }
</script>

<style scoped>

</style>
