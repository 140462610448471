import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

import PageRsvFront from 'RenterSite/pages/page-rsv-front'
import PageRsvSignup from 'RenterSite/pages/page-rsv-signup'
import PageRsvLogin from 'RenterSite/pages/page-rsv-login'
import PageRsvArticlesList from 'RenterSite/pages/page-rsv-articles-list'
import PageRsvArticle from 'RenterSite/pages/page-rsv-article'
import PageRsvGalleryPhotosList from 'RenterSite/pages/page-rsv-gallery-photos-list'
import PageRsvGalleryPhoto from 'RenterSite/pages/page-rsv-gallery-photo'
import PageRsvCustomerTransactionsList from 'RenterSite/pages/page-rsv-customer-transactions-list'
import PageRsvCustomerOrdersList from 'RenterSite/pages/page-rsv-customer-orders-list'
import PageRsvCustomerDetails from 'RenterSite/pages/page-rsv-customer-details'
import PageRsvOrder from 'RenterSite/pages/page-rsv-order'
import PageRsvRules from 'RenterSite/pages/page-rsv-rules'
import AgvCustomerPage from 'Aggregator/components/agv-customer-page'

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: PageRsvFront,
      props: true
    },
    {
      path: '/signup',
      component: PageRsvSignup,
      props: true
    },
    {
      path: '/login',
      component: PageRsvLogin
    },
    {
      path: '/catalogue',
      component: PageRsvArticlesList,
      props: true,
    },
    {
      path: '/catalogue/category/:category_id(\\d+)',
      component: PageRsvArticlesList,
      props: true,
    },
    {
      path: '/catalogue/section/:section_id(\\d+)',
      component: PageRsvArticlesList,
      props: true,
    },
    {
      path: '/catalogue/search/:searchTerms',
      component: PageRsvArticlesList,
      props: true
    },
    {
      path: '/article/:article_id(\\d+)',
      component: PageRsvArticle,
      props: true,
    },
    {
      path: '/gallery',
      component: PageRsvGalleryPhotosList,
      props: true
    },
    {
      path: '/photo/:photo_id(\\d+)',
      component: PageRsvGalleryPhoto,
      props: true
    },
    {
      path: '/answers',
      component: PageRsvRules,
      props: true
    },
    {
      path: '/favorites',
      component: PageRsvArticlesList,
      props: true,
    },
    {
      path: '/order/:order_id(\\d+|new)',
      component: PageRsvOrder,
      props: true
    },
    {
      path: '/customer',
      component: AgvCustomerPage,
      children: [
        {
          path: 'transactions',
          component: PageRsvCustomerTransactionsList,
          props: true
        },
        {
          path: 'orders',
          component: PageRsvCustomerOrdersList,
          props: true
        },
        {
          path: 'details',
          component: PageRsvCustomerDetails,
          props: true
        },
        {
          path: 'password-change',
          component: PageRsvCustomerDetails,
          props: true
        }
      ]
    }
  ],
  scrollBehavior(to, from) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return {x: 0, y: 0}
  }
})
