import _ from 'lodash'

export function getStateFromInitialState(INITIAL_STATE) {
  return {
    SPECIAL_STATE: INITIAL_STATE.SPECIAL_STATE,
    allSections: INITIAL_STATE.sections,
    banners: INITIAL_STATE.banners,
    branches: INITIAL_STATE.branches,
    branchesByTownId: _.keyBy(INITIAL_STATE.branches, 'town_id'),
    articlesById: _.keyBy(INITIAL_STATE.articles, 'id'),
    categories: INITIAL_STATE.categories,
    categoriesById: _.keyBy(INITIAL_STATE.categories, 'id'),
    category_names: INITIAL_STATE.category_names,
    color_options: INITIAL_STATE.color_options,
    featureFlags: INITIAL_STATE.renter.feature_flags || {},
    frontPageBanners: INITIAL_STATE.frontPageBanners,
    renter: INITIAL_STATE.renter,
    themeColor: `#${INITIAL_STATE.renter.color}`,
    pifakit_service: INITIAL_STATE.pifakit_service
  }
}

export default _.merge(
  getStateFromInitialState(window.INITIAL_STATE),
  {
    $isServer: false,
    order: null,
    orderBackup: null,
    as_customer_id: window.as_customer_id,
    isOrderPlacing: false,
    isOrderCancelling: false,
    isOrderSaving: false,
  }
)
