<template lang="pug">
  .ordering-selector
    .ordering(v-for="criterion in orderingCriteria"
    :class='{"ordering-active": sortColumn == criterion.name, "ordering-desc": sortDirection == "desc"}',
    @click='toggleSortColumn(criterion.name)')
      | {{ criterion.description }}
</template>

<script>
  import _ from 'lodash'

  let flipDirection = (direction) => {
    return direction === 'asc' ? 'desc' : 'asc'
  }

  export default {
    name: 'agv-ordering-selector',
    props: {
      sortColumn: String,
      sortDirection: String,
      orderingCriteria: Array
    },
    methods: {
      toggleSortColumn(columnName) {
        if (this.sortColumn === columnName) {
          this.$emit('update:sortDirection', flipDirection(this.sortDirection))
        } else {
          this.$emit('update:sortColumn', columnName)
          this.$emit('update:sortDirection', _.find(this.orderingCriteria, c => c.name === columnName).defaultDirection)
        }
        this.$emit('changed')
      }
    }
  }
</script>

<style scoped>
  .ordering-selector {
    display: flex;
    flex-grow: 1;
    user-select: none;
  }

  .title, .ordering {
    margin-right: 20px;
    font-size: 14px;
    color: #949494;
  }

  .ordering {
    color: #4990e2;
    cursor: pointer;
    white-space: nowrap;
  }

  .ordering.ordering-active {
    color: #949494;
  }

  .ordering.ordering-active:after {
    content: '';
    display: inline-block;
    margin-left: 5px;
    width: 7px;
    height: 8px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIHN0cm9rZT0iIzk3OTc5NyIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiPjxwYXRoIGQ9Ik0xIDFoMU0xIDRoM00xIDdoNSIvPjwvZz48L3N2Zz4=);
  }

  .ordering.ordering-active.ordering-desc:after {
    transform: scaleY(-1);
  }
</style>
