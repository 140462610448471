// @flow

import axios from 'axios'
import {openWindowWithPost} from 'Shared/lib/openWindowWithPost'

function proceedToCashPayment(eventBus: { $emit: Function }) {
  eventBus.$emit('openCashPaymentModal')
  return Promise.resolve()
}

function proceedToWireTransferOrRobokassaPayment(newBillUrl) {
  window.open(newBillUrl, '_blank')
  return Promise.resolve()
}

function proceedToSbolPayment(sum: number, balance: number, eventBus: { $emit: Function }) {
  eventBus.$emit('openSbolPaymentModal', getPaymentSum(sum, balance))
  return Promise.resolve()
}

export function getPaymentSum(billSum: number, balance: number) {
  return billSum - Math.max(0, -balance)
}

export function proceedToPayment(paymentMethod: string,
                                 sum: number,
                                 balance: number,
                                 newBillUrl: string,
                                 eventBus: { $emit: Function }) {
  switch (paymentMethod) {
    case 'cash':
      return proceedToCashPayment(eventBus)
    case 'sberbank':
      return proceedToSbolPayment(sum, balance, eventBus)
    case 'robokassa':
      return axios.get(newBillUrl, sum)
        .then(({data: robokassaRequestParams}) => {
          console.log(robokassaRequestParams)
          return openWindowWithPost("https://auth.robokassa.ru/Merchant/Index.aspx", robokassaRequestParams)
        })
    case 'modulbank':
      return window.open(newBillUrl, sum)
    default:
      return proceedToWireTransferOrRobokassaPayment(newBillUrl)
  }
}

export type PaymentMethodString = 'robokassa' | 'sberbank' | 'wire_transfer' | 'cash'
