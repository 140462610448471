<template lang="pug">
  .order-time-range
    .selectors-container
      | с
      | &ensp;
      el-select.cc-time-select-start(placeholder="XX:XX" v-model="rangeStart" size="small" @change="adjustEnd" @focus="scrollSelectedToParentCenter" :disabled="disabled")
          el-option(v-for="hour in displayedRange" :key="hour" :label="toMilitaryTime(hour % 24)" :value="hour" :disabled="!isInSelectableRange(hour)")
      | &ensp;
      | до
      | &ensp;
      el-select.cc-time-select-end(placeholder="XX:XX" v-model="rangeEnd" size="small" @change="adjustStart" @focus="scrollSelectedToParentCenter" :disabled="disabled || isRangeStartNil")
          el-option(v-for="hour in displayedRange" :key="hour" :label="toMilitaryTime(hour % 24)" :value="hour" :disabled="!isInSelectableRange(hour)")
      .loose-interval-discount(v-if="showLooseIntervalDiscountCta && (rangeStart !== rangeEnd) && (rangeEnd != null)")
        | –10%
      .loose-interval-discount-cta(v-if="showLooseIntervalDiscountCta && rangeStart != null && rangeEnd == null")
        | Выберите интервал в 1 час или больше
        br
        | и получите скидку 10% на доставку
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import scrollToParentCenter from 'Shared/lib/scrollToParentCenter'

export default {
  name: 'order-time-range',
  props: {
    value: Array,
    minDisplayedHour: Number,
    maxDisplayedHour: Number,
    minSelectableHour: Number,
    maxSelectableHour: Number,
    disabled: Boolean,
    showLooseIntervalDiscountCta: Boolean
  },
  data() {
    return {
      internalValue: this.value,
      rangeStart: null,
      rangeEnd: null
    }
  },
  computed: {
    isRangeStartNil() {
      return _.isNil(this.rangeStart)
    },
    displayedRange() {
      return _.range(this.minDisplayedHour || 0, this.maxDisplayedHour || 24)
    },
    isRangeNotFullyDefined() {
      return _.isUndefined(this.rangeStart) || _.isUndefined(this.rangeEnd)
    },
    actualMaxDisplayedHour() {
      if (_.isNil(this.maxDisplayedHour)) {
        return 23
      }
      return this.maxDisplayedHour
    },
    actualMaxSelectableHour() {
      if (_.isNil(this.maxSelectableHour)) {
        return this.actualMaxDisplayedHour
      }
      return this.maxSelectableHour
    }
  },
  methods: {
    toMilitaryTime(hour) {
      return (hour < 10 ? `0${hour}` : hour) + ':00'
    },
    isInSelectableRange(hour) {
      return (this.minSelectableHour || 0) <= hour && hour <= this.actualMaxSelectableHour
    },
    scrollSelectedToParentCenter() {
      setTimeout(() => {
        const container = $('.el-select-dropdown:visible .el-scrollbar__wrap')[0]
        const selected = $('.el-select-dropdown:visible .el-select-dropdown__item.selected:visible')[0] || $('.el-select-dropdown:visible .el-select-dropdown__item:not(.disabled):first')[0]
        if (container && selected) {
          scrollToParentCenter(container, selected)
        }
      }, 100)
    },
    updateInternalValue() {
      this.rangeStart = _.isUndefined(this.value[0]) ? undefined : this.value[0]
      this.rangeEnd = _.isUndefined(this.value[1]) ? undefined : this.value[1]
    },
    emitValue() {
      this.$emit('input', [this.rangeStart, this.rangeEnd])
    },
    adjustEnd() {
      if (this.isRangeNotFullyDefined) {
        return
      }

      if (this.rangeStart > 23) {
        this.rangeStart = this.rangeStart % 24
        this.$emit('prolongRentPeriodByDay')
      }

      this.rangeEnd = Math.max(this.rangeStart, this.rangeEnd)

      this.emitValue()
    },
    adjustStart() {
      if (this.isRangeNotFullyDefined) {
        return
      }

      if (this.rangeEnd > 23) {
        this.rangeEnd = this.rangeEnd % 24
        this.$emit('prolongRentPeriodByDay')
      }

      this.rangeStart = Math.min(this.rangeStart, this.rangeEnd)

      this.emitValue()
    }
  },
  watch: {
    'value': 'updateInternalValue'
  },
  created() {
    this.updateInternalValue()
  }
}
</script>

<style scoped>
.selectors-container {
    display: flex;
    align-items: baseline;
    position: relative;
}

.el-select {
    width: 100px;
}

.loose-interval-discount-cta {
    font-size: 12px;
    margin-top: 15px;
    margin-left: 20px;
    border: 1px solid #e1f3d8;
    background-color: #f0f9eb;
    color: #67c23a;
    padding: 5px 10px;
    border-radius: 4px;
    position: absolute;
    top: -16px;
    right: -280px;
    width: 260px;
}

.loose-interval-discount-cta::before {
    content: "";
    position: absolute;
    top: 30%; /* Adjust this value to control the position of the arrow */
    left: -10px; /* Width of the arrow */
    width: 0;
    height: 0;
    border-top: 6px solid transparent; /* Half of the arrow height */
    border-bottom: 6px solid transparent; /* Half of the arrow height */
    border-right: 10px solid #f0f9eb; /* Arrow color should match the background-color of the rectangle */
    transform: translateY(-50%); /* Center the arrow vertically */
}

.loose-interval-discount {
    display: inline-block;
    border-radius: 50%;
    height: 46px;
    width: 46px;
    background-color: #67c23a;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    padding-top: 13px;
    padding-left: 2px;
    position: absolute;
    top: -7px;
    right: -60px;
}

</style>
