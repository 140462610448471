<template lang="pug">
  .datepicker(:class="{'with-value': !!internalValue}")
    el-date-picker(@change="onChangeInternal" @focus="$emit('focus')" v-model='internalValue' :editable='false' format='dd.MM' type='daterange' :picker-options='{firstDayOfWeek: 1, disabledDate: isDateDisabledPredicate}' :align="align || 'right'"
    range-separator='—' start-placeholder='Получение' end-placeholder='Возврат' size="small" popper-class="page-agv-articles-list__picker")
</template>

<script>
  export default {
    name: 'agv-datepicker',
    props: {
      value: Array,
      isDateDisabledPredicate: Function,
      onChange: Function,
      align: String
    },
    data() {
      return {
        internalValue: this.value
      }
    },
    computed: {
      renter() {
        return this.$store.state.renter
      }
    },
    watch: {
      'value': 'updateInternalValue',
      'internalValue': 'emitValue'
    },
    methods: {
      onChangeInternal() {
        this.$emit('change', this.internalValue)
      },
      updateInternalValue() {
        this.internalValue = this.value
      },
      emitValue() {
        this.$emit('input', this.internalValue)
      }
    }
  }
</script>

<style scoped>

</style>

<style>
  .el-picker-panel.page-agv-articles-list__picker {
    box-shadow: none;
    border: 1px solid #cbcdd1;
  }

  .el-picker-panel.page-agv-articles-list__picker .popper__arrow {
    filter: none !important;
  }

  .el-picker-panel.page-agv-articles-list__picker[x-placement^=bottom] .popper__arrow {
    border-bottom-color: #cbcdd1 !important;
  }

  .el-picker-panel.page-agv-articles-list__picker[x-placement^=top] .popper__arrow {
    border-top-color: #cbcdd1 !important;
  }

  .el-date-range-picker.page-agv-articles-list__picker {
    width: 546px;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-table td {
    padding: 1px 0;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-table td div {
    height: 26px;
    padding: 1px 0;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-table td.disabled {
    cursor: default;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-table td.disabled div {
    cursor: default;
    background-color: #fff;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-table td.in-range div {
    background: #a8d4ff;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-table td.today span {
    color: inherit;
    font-weight: normal;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-range-picker__header [class*=arrow-left]:first-child {
    display: none;
  }

  .el-date-range-picker.page-agv-articles-list__picker .el-date-range-picker__header [class*=arrow-right]:first-child {
    display: none;
  }

  .datepicker .el-icon-date {
    display: none;
  }

  .datepicker .el-range__close-icon {
    margin-right: -2px;
  }

  .datepicker .el-date-editor--daterange.el-input__inner {
    width: 185px;
    border-color: #9b9b9b;
    cursor: pointer;
  }

  .datepicker .el-date-editor--daterange.el-input__inner:hover {
    border-color: #666;
  }

  .datepicker .el-date-editor--daterange.el-input__inner .el-range-input {
    font-size: 12px;
    cursor: pointer;
    line-height: 24px;
    pointer-events: none;
  }

  .datepicker .el-date-editor--daterange.el-input__inner .el-range-input:nth-child(2) {
    width: 48%;
  }

  .datepicker .el-date-editor--daterange.el-input__inner .el-range-separator {
    width: auto;
    cursor: pointer;
  }

  .datepicker.with-value .el-date-editor--daterange.el-input__inner {
    border: 2px solid #27b300;
    background: #ebffe5;
  }

  .datepicker.with-value .el-date-editor--daterange.el-input__inner input {
    background: #ebffe5;
  }
</style>
