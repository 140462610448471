// @flow

import axios from 'axios'
import eventBus from 'Shared/eventBus'
import * as Sentry from '@sentry/browser'

let loadPromise

export default function createStore(
  loadUrl: string,
  logoutUrl: string,
  saveUrl: string
) {
  return {
    namespaced: true,
    state: {
      current: {}
    },
    mutations: {
      setCurrent(state, value) {
        state.current = value
        eventBus.$emit('customerSet', state.current)
        Sentry.configureScope((scope) => {
          scope.setUser({
            'login': state.current.login,
            first_name: state.current.first_name,
            last_name: state.current.last_name
          })
        })
      },
    },
    getters: {
      isLoggedIn(state) {
        return !!state.current.id
      },
    },
    actions: {
      save(store, {customer}) {
        axios.patch(saveUrl, {customer}
        ).then((response) => {
          store.commit('setCurrent', response.data)
        })
      },
      load(store) {
        if (!loadPromise) {
          loadPromise = axios.get(loadUrl).then(({data: customer}) => {
            store.commit('setCurrent', customer)
            loadPromise = undefined
            return Promise.resolve(customer)
          }, () => {
            loadPromise = undefined
            console.log('Customer not loaded')
          })
        }
        return loadPromise
      },
      logout(store) {
        return axios.delete(logoutUrl).then(() => {
          store.commit('setCurrent', {})
        })
      }
    }
  }
}
