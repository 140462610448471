<template lang="pug">
  transition(name="fade")
    .item-added-notification-container(v-if='show')
      router-link(:to="orderUrl")
        .item-added-notification
          .shopping-bag
            agv-shopping-bag(:quantity="quantity")
          .notification
            | Добавлено!
</template>

<script>
  import AgvShoppingBag from 'Aggregator/components/agv-shopping-bag'
  import eventBus from 'Shared/eventBus'

  export default {
    name: "order-item-added-notification",
    data() {
      return {
        show: false,
        renterId: null,
        orderId: null
      }
    },
    components: {AgvShoppingBag},
    props: {
      quantity: Number,
      getOrdersUrl: Function
    },
    computed: {
      orderUrl() {
        return this.getOrdersUrl(this.renterId) + '/' + this.orderId
      }
    },
    created() {
      eventBus.$on('order:itemAdded', (renterId) => {
        this.show = true
        this.renterId = renterId
        this.orderId = _.get(this.$store.getters['order/getByRenterId'](renterId), 'id', 'new')

        setTimeout(() => {
          this.show = false
        }, 1800)
      })
    }
  }
</script>

<style scoped>
  .item-added-notification-container {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background: transparent;
    z-index: 999;
  }

  .item-added-notification {
    pointer-events: all;
    width: 200px;
    height: 200px;
    border-radius: 40px;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .items-in-bag {
    display: block;
    font-size: 42px;
    color: #F6A623;
  }

  .shopping-bag svg {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
  {
    opacity: 0;
  }

  .notification {
    color: #4a4a4a;
  }
</style>
