<template lang="pug">
  .ag-article.cc-article
    .top-container(v-if="showCreatedAt || showLikeButton" :class="{ 'top-container-with-created-at': showCreatedAt }")
      agv-article-like(v-if="showLikeButton" :article="article" @update="updateArticle($event)" :is-logged-in="isLoggedIn")
      .created-at(v-if="showCreatedAt")
        | {{article.created_at | formatRelativeGMTDate}}
    .ad(v-if='isAdvertising')
      | Популярно
    .spreader
    router-link.ag-article_image_container(:to="articleUrl")
      .infringing-photo-removed(v-if="article.is_infringing")
        i.el-icon-picture
        |
        | Фотография удалена
      img.ag-article_image(v-else :src="previewUrl")
    .spreader
    .price-block.price-with-discount(v-if="showDiscount")
      | {{article.price_with_discount | money(country)}}
      .price-for-individuals
        | {{article.price_in_town | money(country)}} для частников
    .price-block(v-else)
      | {{article.price_in_town | money(country)}}
    .name-block
      router-link.name(:to="articleUrl")
        span(v-html="nameWithSubstitutionsHtml")
    .aux-block
      .row
        | {{article | articleSizes}} см
      .row(v-if="available === 0")
        | Недоступно на период
      .row(v-else)
        | Доступно {{available}} шт.
    .aux-block
      .row(v-if='showRating')
        router-link.renter-name-and-rating-count(:to="`/${townId}/renter/${article.renter_id}`")
          .renter-name
            | {{renter.short_commercial_name}}
      .row.rating-row(v-if='showRating')
        agv-rating(:rating="renter.avg_rating" :rating-count="renter.rating_count")
      .row.order-button-row
        agv-add-to-order-button(:article="article" :order="order" :available="available" :renter-id="renter.id")
</template>

<script>
  import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'
  import AgvAddToOrderButton from 'Aggregator/components/agv-add-to-order-button'
  import AgvRating from 'Aggregator/components/agv-rating'
  import {getNameWithSubstitutions} from "Shared/articleNames";
  import AgvArticleLike from "Aggregator/components/agv-article-like.vue";

  export default {
    name: 'agv-article',
    components: {AgvArticleLike, AgvAddToOrderButton, AgvRating},
    props: {
      article: Object,
      isAdvertising: Boolean,
      isAvailableForPeriodLoaded: Boolean,
      renter: Object,
      townId: Number,
      showRating: Boolean,
      showDiscount: Boolean,
      showLikeButton: Boolean,
      showCreatedAt: Boolean
    },
    methods: {
      updateArticle(article) {
        this.$set(this.article, 'is_liked', article.is_liked)
        this.$store.dispatch('customer/load')
      }
    },
    computed: {
      articleUrl() {
        return this.article.url + (this.$route.params.renter_id ? '?from_renter_page=true' : '')
      },
      isLoggedIn() {
        return this.$store.getters['customer/isLoggedIn']
      },
      nameWithSubstitutionsHtml() {
        const nameSubstitutions = this.$store.getters.getArticleNameSubstitutions(this.renter.id)
        return getNameWithSubstitutions((this.article.search_headline || this.article.name), nameSubstitutions)
      },
      order() {
        return this.$store.getters['order/getByRenterId'](this.renter.id)
      },
      country() {
        return this.renter.country
      },
      countryCurrencySymbol() {
        return this.$options.filters.currencySymbol(this.country)
      },
      previewUrl() {
        return `https://pifakit.ru${this.article.preview_url}`
      },
      available() {
        return getAvailableForPeriodOrTotal(this.article, this.renter.id, this.$store)
      }
    },
  }
</script>

<style scoped>
  .ag-article {
    position: relative;
  }

  .top-container {
    height: 20px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .top-container.top-container-with-created-at {
    margin-bottom: 24px;
  }

  .created-at {
    font-size: 14px;
    line-height: 20px;
    color: #999;
  }

  .ag-article .agv-like >>> svg {
    margin-right: -16px;
  }

  .ag-article .agv-like {
    position: relative;
    top: -16px;
  }

  .height {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2px;
    padding-top: 7px;
    font-size: 13px;
    line-height: 26px;
    color: #2b2b2b;
  }

  .spreader {
    flex-grow: 1;
  }

  .ag-article {
    position: relative;
    padding-right: 30px;
    margin-bottom: 60px;
    flex: 0 0 242px;
    display: flex;
    flex-direction: column;
  }

  .ag-article_image_container {
    overflow: hidden;
    /*height: 241px;*/
    position: relative;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-article_image_container img {
    cursor: pointer;
  }

  .name-block {
    color: #000;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .name {
    color: #000;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 37px;
    line-height: 18px;
  }

  .name:hover {
    text-decoration: none;
    color: #4a90e2;
    outline: none;
  }

  .renter-name-and-rating-count {
    color: #828282;
    line-height: 18px;
  }

  .renter-name-and-rating-count:hover {
    text-decoration: none;
    color: #4a90e2;
    outline: none;
  }

  .renter-name {
    margin-right: 5px;
    display: inline-block;
  }

  .rating-count {
    display: inline-block;
  }

  .price-block {
    margin-top: 13px;
    font-size: 18px;
    font-weight: 600;
  }

  .price-block.price-with-discount {
    color: #2ea50d;
  }

  .price-for-individuals {
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 4px;
    color: #828282;
  }

  .aux-block {
    margin-top: 10px;
  }

  .available {
    color: #8e8e8e;
    font-size: 13px;
    line-height: 18px;
    float: right;
    display: inline-block;
  }

  .ad {
    position: absolute;
    color: white;
    background: #5ccc16;
    padding: 1px 4px;
    font-size: 11px;
    line-height: 13px;
    top: -27px;
    z-index: 100;
  }

  .row {
    font-size: 12px;
    color: #828282;
    letter-spacing: 0;
    line-height: 18px;
  }

  .rating-row {
    margin-top: 2px;
  }

  .order-button-row {
    padding-top: 20px;
  }

  img {
    max-width: 100%;
  }

  .agv-add-to-order-button {
    width: 40%;
  }

  .infringing-photo-removed {
    font-size: 14px;
  }
</style>

<style scoped>
  .rating-row >>> .agv-star-rating {
    margin-right: 0.4em;
    display: inline-block;
  }

  .rating-row >>> .rating-count {
    display: inline-block;
  }
</style>
