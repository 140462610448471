<template lang="pug">
  .root
    .topmost-section-container
      .topmost-section.wrapper
        el-select.branch-select(v-if="towns.length > 1"
          size="mini" :value="town.id"
          placeholder="Выберите город"
          filterable
          :default-first-option="true"
          @change="onTownChanged")
          el-option(v-for="town in towns" :key="town.id" :label="town.name" :value="town.id")
        .contact-info.cp-address
          icon-geo-tag.contact-info-icon
          component(:is="branch.location_url ? 'a' : 'span'" :href="branch.location_url" target="_blank")
            | {{branch.cp_address}}
        .contact-info.contact-info-phone
          icon-phone.contact-info-icon
          a(:href="`tel:+7${branch.customer_service_phone}`")
            | {{branch.customer_service_phone | phoneNumber(renter.country)}}
        .contact-btn
          a(:href="`mailto:${branch.customer_service_email}`")
            icon-contact-email
        .contact-btn(v-if='renter.vk_url')
          a(:href="renter.vk_url" target="_blank")
            icon-contact-vk
        .spreader
        .nav-links
          router-link.nav-link(to="/gallery")
            | Галерея
          a.nav-link(v-if='$store.state.featureFlags.sales_page_url' :href='$store.state.featureFlags.sales_page_url' target="_blank")
            | Распродажа
          a.nav-link(:href="$store.state.featureFlags.rules_page_url || '/answers'" target="_blank")
            | Условия работы
    .second-section-container.wrapper
      agv-nav-sheet-popover(v-show='isNavSheetVisible'
      :town="town"
      :sections="navSheetSections"
      :subsections="navSheetSubsections"
      :is-abridged="isNavSheetAbridged"
      @close="hideNavSheet")
        .link-container(v-if="showFavoritesLink" :class="{section: !isNavSheetAbridged}")
          .favorites-link
            icon-red-heart.icon-red-heart
            router-link(to="/favorites")
              | Мои любимые изделия
      .header
        .renter-site-header.header__inner.clearfix
          .logo-link.renter-logo_image-container(:is='renter.compliant_homepage_url ? "a" : "router-link"' :href="logoLinkUrl" :to="logoLinkUrl" v-if='renter.logo && renter.logo.url')
            img(:src="renter.logo.url")
          .logo-link.renter-logo_text(:is='renter.compliant_homepage_url ? "a" : "router-link"' :href="logoLinkUrl" :to="logoLinkUrl" v-else)
            | {{renter.short_commercial_name_cases[0]}}
          .search-wrapper
            agv-sitewide-search(v-model='searchTerms' @input="goToSearch")
            .search-btn
              | Найти
          agv-nav-button(@click.native.stop="toggleNavSheet" :is-nav-sheet-visible="isNavSheetVisible")
          .spreader
          router-link.toolbar-btn(:to='currentOrderPath')
            agv-shopping-bag.btn-icon(:quantity="lineItemsQuantity")
            | {{currentOrderLabel}}
          .toolbar-btn(v-if='customer.id')
            router-link.orders-link(:to="customerLink")
              icon-person.btn-icon
              | Мой кабинет
            .customer-attributes
              router-link.balance(v-if="customer.balance" to="/customer/transactions")
                | Баланс: {{customer.balance | money(renter.country)}}
              .discount-portion(v-if="discountPortion")
                | Скидка: {{Math.round(discountPortion * 100)}}%
          .toolbar-btn(v-else @click="eventBus.$emit('proceedToLogin')")
            icon-person.btn-icon
            | Войти
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import $ from 'jquery'

  import newSiteRouter from 'RenterSite/newSiteRouter'

  import AgvBranchSelect from 'Aggregator/components/agv-branch-select'
  import AgvNavButton from 'Aggregator/components/agv-nav-button'

  import AgvShoppingBag from 'Aggregator/components/agv-shopping-bag'
  import IconPerson from 'Aggregator/sprites/icon-person.svg'

  import IconGeoTag from 'Aggregator/sprites/icon-geo-tag.svg'
  import IconPhone from 'Aggregator/sprites/icon-phone.svg'

  import IconContactEmail from 'Aggregator/sprites/icon-contact-email.svg'
  import IconContactInstagram from 'Aggregator/sprites/icon-contact-instagram.svg'
  import IconContactVk from 'Aggregator/sprites/icon-contact-vk.svg'
  import IconContactFacebook from 'Aggregator/sprites/icon-contact-facebook.svg'

  import IconRedHeart from 'Aggregator/sprites/red-heart.svg'

  import AgvSitewideSearch from 'Aggregator/components/agv-sitewide-search'

  import FormAgvLogin from 'Aggregator/pages/form-agv-login'
  import FormAgvDetails from 'Aggregator/pages/form-agv-details'
  import RsvPaymentModal from 'RenterSite/components/rsv-payment-modal'
  import RsvPersonalDataPolicy from 'RenterSite/components/rsv-personal-data-policy'

  import AgvNavSheetPopover from 'Aggregator/components/agv-nav-sheet-popover'
  import AgvSbolPaymentHelp from 'Aggregator/components/agv-sbol-payment-help'
  import OrderDeliveryDetailsModal from 'Shared/components/order-delivery-details-modal'
  import OrderItemAddedNotification from 'Shared/components/order-item-added-notification'
  import SbolPaymentModal from 'Shared/components/sbol-payment-modal'
  import SurplusRetrievalModal from 'Shared/components/surplus-retrieval-modal'

  import eventBus from 'Shared/eventBus'
  import {setupProgressBar} from 'Shared/lib/progressBar'

  export default {
    name: 'rsv-header',
    components: {
      SurplusRetrievalModal,
      SbolPaymentModal,
      OrderItemAddedNotification,
      AgvNavSheetPopover,
      OrderDeliveryDetailsModal,
      AgvSbolPaymentHelp,
      RsvPaymentModal,
      RsvPersonalDataPolicy,
      FormAgvDetails,
      FormAgvLogin,
      AgvSitewideSearch,
      AgvShoppingBag,
      AgvNavButton,
      AgvBranchSelect,
      IconPerson,
      IconGeoTag,
      IconPhone,
      IconContactEmail,
      IconContactInstagram,
      IconContactVk,
      IconContactFacebook,
      IconRedHeart
    },
    props: {
      order: Object,
      lineItemsQuantity: Number
    },
    data() {
      let branchesByTownId = _.keyBy(this.$store.state.branches, b => b.town_id)
      let towns = _.map(this.$store.state.branches, b => b.town)

      _.each(towns, (t) => {
        t.label = t.name
      })

      return {
        eventBus,
        renter: this.$store.state.renter,
        towns: towns,
        branchesByTownId: branchesByTownId,
        isNavSheetVisible: false,
        searchTerms: this.$route.params.searchTerms
      }
    },
    computed: {
      discountPortion() {
        if (!this.customer.is_professional) {
          return null
        }
        return this.customer.special_rent_discount || this.renter.rent_discount
      },
      town() {
        return this.$store.state.town.current
      },
      logoLinkUrl() {
        return this.renter.compliant_homepage_url || '/'
      },
      customerLink() {
        return this.customer.has_orders ?
          '/customer/orders' :
          (this.customer.has_transactions ? '/customer/transactions' : '/customer/details')

      },
      showFavoritesLink() {
        return !!this.$store.state.customer.current && _.keys(this.$store.state.customer.current.article_order_counts).length > 0
      },
      currentOrderPath() {
        return `/order/${_.get(this.order, 'id', 'new')}`
      },
      currentOrderLabel() {
        if (_.get(this.order, 'id')) {
          return `Заказ ${this.order.code}`
        }

        return 'Заказ'
      },
      customer() {
        return this.$store.state.customer.current
      },
      branch() {
        return this.branchesByTownId[this.$store.state.town.current.id]
      },
      sections() {
        return this.$store.getters.sections
      },
      categories() {
        return this.$store.state.categories
      },
      categoriesOfRelevantArticles() {
        return _.filter(this.categories, c => _.find(this.$store.getters.relevantArticles, a => a.category_id === c.id))
      },
      sectionsOfRelevantArticles() {
        return _.filter(this.sections, s => _.find(this.categoriesOfRelevantArticles, c => c.section_id === s.id))
      },
      isNavSheetAbridged() {
        return !(this.navSheetSections && this.navSheetSections.length > 0)
      },
      navSheetSubsections() {
        if (this.navSheetSections) {
          return null
        }

        return _.map(
          _.orderBy(this.categoriesOfRelevantArticles, 'position'),
          (c) => {
            return {
              name: c.name,
              isImportant: true,
              url: `/catalogue/category/${c.id}`
            }
          }
        )
      },
      navSheetSections() {
        if (this.sectionsOfRelevantArticles.length === 0) {
          return null
        }

        return _.map(
          _.orderBy(this.sectionsOfRelevantArticles, 'position'),
          (s) => {
            let items = _.map(
              _.filter(
                this.categories,
                (c) => {
                  return c.section_id === s.id
                }),
              (c) => {
                return {
                  name: c.name,
                  isImportant: true,
                  url: `/catalogue/category/${c.id}`
                }
              }
            )
            return {
              name: s.name,
              url: `/catalogue/section/${s.id}`,
              importantItems: items,
              nonImportantItems: []
            }
          })
      },
    },
    methods: {
      onTownChanged(townId) {
        this.$store.dispatch('town/setCurrent', _.find(this.towns, t => t.id === townId))
        this.$router.push('/')
      },
      goToSearch() {
        if (!this.searchTerms) {
          return
        }
        this.$router.push(`/catalogue/search/${this.searchTerms}`)
      },
      toggleNavSheet() {
        this.isNavSheetVisible = !this.isNavSheetVisible

        if (this.isNavSheetVisible) {
          let interval = setInterval(() => {
            if (!$('.nav-sheet-container').is(':visible')) {
              return
            }

            const sectionEls = $('.section')

            if (!(sectionEls && sectionEls.length)) {
              return
            }

            const heights = sectionEls.map((i, el) => {
              return $(el).outerHeight(true)
            })

            if (!(heights && heights.length)) {
              return
            }

            const averageColumnHeight = _.sum(heights) / 3
            const maxColumnHeight = _.max(heights)
            let proposedNavSheetHeight = Math.max(averageColumnHeight, maxColumnHeight)

            let finalNavSheetHeight = null
            let iterations = 0

            while (!finalNavSheetHeight || iterations > 20) {
              iterations += 1

              let columnsFilled = 0
              let currentColumnHeight = 0
              _.each(heights, (h) => {
                currentColumnHeight += h
                if (currentColumnHeight > proposedNavSheetHeight) {
                  columnsFilled += 1
                  currentColumnHeight = 0
                  currentColumnHeight += h
                }
              })

              columnsFilled += 1

              if (columnsFilled > 3) {
                proposedNavSheetHeight += 20
              } else {
                finalNavSheetHeight = proposedNavSheetHeight
              }
            }

            $('.nav-sheet-full').css('height', Math.ceil(finalNavSheetHeight + 66) + 'px')
            clearInterval(interval)
          }, 300)
        }
      },
      hideNavSheet() {
        this.isNavSheetVisible = false
      }
    },
    router: newSiteRouter,
    watch: {
      isNavSheetVisible: (value) => {
        // if (!value) {
        //   return
        // }
        // setTimeout(() => {
        //   const navSheetEl = $('.nav-sheet-abridged')
        //   const navBtnEl = $('.nav-btn-activated')
        //   navSheetEl.offset(
        //     {
        //       left: navBtnEl.offset().left - 32
        //     }
        //   )
        // }, 0)
      },
      $route(to) {
        this.isNavSheetVisible = false

        if (!to.params.searchTerms) {
          this.searchTerms = ''
        }
      }
    },
    created() {
      setupProgressBar(this.$Progress, axios)
      eventBus.$on('proceedToLogin', ()=>{
        this.$router.push('/login')
      })
    }
  }
</script>

<style scoped>
  .search-wrapper {
    display: flex;
    flex-grow: 1;
    flex-basis: 223px;
  }

  .search-btn {
    flex-basis: 50px;
    line-height: 28px;
    font-size: 14px;
    height: 50px;
    color: white;
    background: #9B9B9B;
    border: 1px solid #9B9B9B;
    border-left: none;
    cursor: pointer;
    border-radius: 0 4px 4px 0;

    display: flex;
    align-items: center;

    padding: 0 18px;
  }

  .nav-btn {
    margin-left: 20px;
  }

  .root >>> .nav-sheet-full .section {
    width: 225px;
  }

  .root >>> .nav-sheet-container-full {
    height: 340px;
    top: 156px;
  }

  .root >>> .nav-sheet-full {
    height: 340px;
    width: 700px;
  }

  .root >>> .nav-sheet-abridged {
    left: 592px;
    top: 10px;
  }

  .root {
    position: relative;
  }

  .topmost-section {
    padding-top: 6px;
    height: 56px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .topmost-section >>> .branch-select {
    position: relative;
    top: -1px;
    margin-right: 20px;
    max-width: 170px;
  }

  .topmost-section div {
    flex-shrink: 0;
  }

  .topmost-section .cp-address {
    flex-shrink: 1;
    min-width: 150px;
    padding-top: 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;

    z-index: 1000;
  }

  @media (pointer: coarse) {
    .topmost-section {
      border-bottom: 1px solid #E7E7E7;
    }
  }

  @media (pointer: fine) {
    .topmost-section-container {
      border-bottom: 1px solid #E7E7E7;
    }
  }

  .renter-site-header {
    display: flex;
    justify-content: stretch;
    align-items: baseline;
    /*height: 126px;*/
    padding: 41px 0 7px;
    margin-bottom: -20px;
  }

  .padder {
    width: 9px;
    flex-basis: 9px;
    flex-grow: 0.5;
  }

  .renter-logo_image-container {
    align-self: start;

    margin-right: 40px;

    position: relative;
    top: -30px;
    height: 112px;

    display: flex;
    align-items: center;
  }

  .renter-logo_image-container img {
    max-height: 112px;
    max-width: 200px;
  }

  .renter-logo_text {
    font-size: 24px;
    color: #4a4a4a;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-right: 30px;
    position: relative;
    top: 4px;
  }

  .wrapper {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    max-width: 1400px;
    min-width: 1100px;
    margin: 0 auto;
  }

  .contact-info {
    font-size: 14px;
    color: #686868;
    margin-right: 20px;
  }

  .contact-info a {
    color: inherit;
  }

  .contact-info-icon {
    position: relative;
    top: 2px;
    margin-right: 6px;
  }

  .contact-info-phone {
    margin-right: 25px;
  }

  .contact-btn {
    align-self: center;
    margin-right: 9px;
  }

  .spreader {
    flex-grow: 1;
  }

  .nav-links {
    justify-self: flex-end;
    margin-left: 20px;
  }

  .nav-link {
    border-bottom: 1px solid #E1E1E1;
    font-size: 14px;
    color: #686868;
    margin-left: 15px;
  }

  .toolbar-btn {
    font-size: 17px;
    color: #4A4A4A;
    text-decoration: none !important;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
  }

  .toolbar-btn .orders-link {
    color: #4A4A4A;
  }

  .btn-icon {
    margin-right: 9px;
    position: relative;
    top: 6px;
  }

  .customer-attributes {
    font-size: 11px;
    position: absolute;
    top: 41px;
    left: 38px;
    line-height: 1.6em;
  }

  .balance, .discount-portion {
    color: grey;
  }

  .balance:hover {
    color: lightgrey;
  }

  .link-container {
    /*flex-grow: 1;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: flex-end;*/
  }

  .favorites-link {
    margin-bottom: 20px;
    font-size: 13px;
  }

  .personal-data-policy-checkbox {
    display: block;
    margin-top: -10px;
    margin-bottom: 40px;
  }
</style>


<style>
  a {
    color: #0074d9;
  }
</style>
