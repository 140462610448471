<template lang="pug">
  .order-menu
    .order-menu-variant(v-if='showEditingModeButtons')
      el-button(@click="$emit('save')" type="success" :loading="isOrderSaving")
        i.el-icon-check
        | Сохранить изменения
      el-button(@click="$emit('revert')" type="info" :disabled="isOrderSaving")
        i.el-icon-close
        | Отменить изменения
    .order-menu-variant(v-else-if="order.line_items.length")
      agv-customer-debt-payment-popover(v-if="customer.balance < 0 && order.paid_on_credit > 0"
        :renter="renter"
        :customer="customer"
        :bank-details="bankDetails"
        :get-customer-bills-url="getCustomerBillsUrl")
        el-button(type="primary" slot="reference" icon="el-icon-bank-card")
          | Оплатить заказ
      el-button(@click="$emit('payFromSurplus')"
      v-if='customer.balance > 0 && showPaymentButtons'
      type="primary")
        | Внести {{maxPaymentFromSurplus | money(renter.country)}} из свободных средств
      el-button(@click="$emit('openPaymentModal')"
      v-else-if="showPaymentButtons"
      type="primary")
        i.el-icon-bank-card
        | Оплатить заказ
      el-button(@click="$emit('restore')"
      v-if='canBeRestored'
      type="primary")
        | Восстановить заказ
      el-button(@click="$emit('beginEditing')"
      v-if='canBeChanged && !isEditing'
      type="primary")
        i.el-icon-edit
        | Изменить заказ
      el-button(@click="$emit('place')"
      v-if='canBePlaced'
      :loading="isOrderPlacing"
      icon="el-icon-goods"
      type="primary")
        | Разместить заказ
      el-popover(v-if='canBeConfirmed && order.is_urgent'
        placement="top-start"
        width="200"
        trigger="click")
        p
          | Отправить в работу
        el-button(@click="$emit('confirmAndProcess')" type="text" size="mini")
          | Прямо сейчас
        el-button(@click="$emit('confirmWithoutProcessing')" style="margin-left: 0;" type="text" size="mini")
          | После оплаты
        el-button(slot="reference"
          type="success")
          i.el-icon-check
          | Принять заказ
      el-button(v-if='canBeConfirmed && !order.is_urgent'
        type="success"
        @click="$emit('confirmWithoutProcessing')"
        icon="el-icon-check")
        | Принять заказ
      el-button(v-if="canBeConfirmed" type="danger"
        :loading="isOrderCancelling"
        icon="el-icon-delete"
        @click="$emit('cancel')")
        | Отменить заказ
      el-popover(v-if='canBeCancelled && !isEditing'
      placement="top-start"
      width="400"
      v-model="cancelPopoverVisible"
      trigger="click")
        p(v-if='order.state === "payment_pending" && order.paid === 0')
          | При отмене заказа бронь на изделия
          br
          | будет снята.
        p(v-if='(order.state === "payment_pending" && order.paid > 0) || (order.state === "paid" && !order.is_frozen)')
          | При отмене заказа сумма предоплаты
          br
          | будет перемещена на ваш личный счет.
        p(v-if='order.state === "preparing" || (order.state === "paid" && order.is_frozen)')
          | Заказ уже подготавливается.
          br
          | При отмене заказа сумма предоплаты будет удержана.
        p(v-if='order.state === "executing"')
          | Заказ уже в работе.
          br
          | При отмене заказа сумма предоплаты будет удержана.
        el-button.cc-order-confirm-cancel-btn(type="text" @click="$emit('cancel'); cancelPopoverVisible = false")
          | Подтвердить отмену заказа
        el-button(slot="reference"
        :loading="isOrderCancelling"
        icon="el-icon-delete"
        type="primary")
          | Отменить заказ
      .download-links
        a.order-download-link(v-if="isEligibleForExport && customer.is_professional" :href="exportOrderUrl" target="_blank")
          i.el-icon-download
          | Смета для себя
        a.order-download-link(v-if="isEligibleForExport" :href="`${exportOrderUrl}?for_client=true`" target="_blank")
          i.el-icon-download
          span(v-if="customer.is_professional")
            | Смета для клиента
          span(v-else)
            | Загрузить смету
</template>

<script>
  import {getMaxPaymentFromSurplus} from 'Shared/order'
  import {isEligibleForExport} from 'Shared/order'
  import AgvPaymentForm from 'Aggregator/components/agv-payment-form'
  import AgvCustomerDebtPaymentPopover from '../../aggregator/components/agv-customer-debt-payment-popover'
  import {mapState} from "vuex";

  export default {
    name: "order-menu",
    components: {AgvCustomerDebtPaymentPopover, AgvPaymentForm},
    data() {
      return {
        showCancelTip: false,
        showCantChangeTip: false,
        cancelPopoverVisible: false
      }
    },
    props: {
      order: Object,
      customer: Object,
      renter: Object,
      isEditing: Boolean,
      isEmbedded: Boolean,
      isUserAdmin: Boolean,
      exportOrderUrl: String,
      paymentMethods: Array,
      bankDetails: Object,
      getCustomerBillsUrl: Function
    },
    computed: {
      ...mapState(['isOrderPlacing', 'isOrderCancelling', 'isOrderSaving']),
      isEligibleForExport() {
        return isEligibleForExport(this.order)
      },
      showPaymentButtons() {
        return !this.isUserAdmin &&
          this.order.id &&
          this.canBePaid &&
          !this.isEditing &&
          !this.order.needs_confirmation
      },
      showEditingModeButtons() {
        return this.order.id &&
          this.isEditing
      },
      canBePlaced() {
        return !this.order.id
      },
      canBePaid() {
        return this.order.state === 'payment_pending'
      },
      canBeChanged() {
        return this.order.id &&
          (
            (
            this.order.state_code <= 2 &&
            (this.isUserAdmin || (!this.order.needs_confirmation && !this.order.is_frozen))
          ) ||
            (
              (this.order.state_code === 3 || this.order.state_code === 4) &&
              this.isUserAdmin
            )
          )
      },
      canBeCancelled() {
        return !this.isUserAdmin &&
          this.order.id &&
          this.order.state_code <= 4
      },
      canBeRestored() {
        return this.order.state === 'cancelled_over_delinquency' || this.order.state === 'cancelled_before_starting'
      },
      canBeConfirmed() {
        return this.order.needs_confirmation && this.isUserAdmin
      },
      maxPaymentFromSurplus() {
        return getMaxPaymentFromSurplus(this.order, this.customer)
      },
    }
  }
</script>

<style scoped>
  .order-menu-variant {
    display: flex;
    flex-wrap: wrap;
  }

  .download-links {
    margin-top: 20px;
  }

  .order-menu-variant .el-button {
    margin-left: 20px;
  }

  .order-menu button i {
    margin-right: 10px;
  }

  .order-download-link {
    padding: 12px 10px;
    font-size: 14px;
  }

  .order-download-link:first-of-type {
    margin-left: 20px;
  }

  .order-download-link i {
    margin-right: 6px;
  }
</style>
