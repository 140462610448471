// @flow

import axios from 'axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import Vue from 'vue'

import {getFixedOrder} from 'Shared/order'
import {reachGoal} from 'Shared/lib/metrika'
import {fbqWrapper} from 'Shared/lib/facebook'

function getPlaceableLineItems(line_items) {
  return _.reject(line_items, li => li.quantity === 0)
}

function getErrors(order): Object | void {
  if (!(order.acquisition && order.returning)) {
    return {acquisition: 'specify'}
  }

  if (!getPlaceableLineItems(order.line_items)) {
    return {line_items: true}
  }
}

function getBlueprint(
  order: {
    acquisition: string,
    returning: string,
    arrangement: Object,
    line_items: Array<{ quantity: number, article_id: number }>,
    ad_hoc_line_items: ?Array<{ id: number, name: string, price: number }>
  }
): Object {
  let result = _.pick(order,
    [
      'line_items',
      'ad_hoc_line_items',
      'delivery',
      'arrangement',
      'town_id',
      'restored_from_code',
      'restored_order_id'
    ])
  result.line_items = _.map(
    getPlaceableLineItems(order.line_items),
    li => _.pick(li, ['quantity', 'article_id'])
  )

  result.acquisition = dayjs(order.acquisition).format('YYYY-MM-DD')
  result.returning = dayjs(order.returning).format('YYYY-MM-DD')

  if (order.arrangement) {
    result.arrangement = true
  }

  return result
}

let placePromise
let cancelPromise = null;

export default function getActions(getArticle, getOrdersUrl, getStateUrl) {
  return {
    setErrors(
      store: Object,
      {errors, renterId}: { errors: Object, renterId: number }
    ) {
      store.commit('setErrors', {errors, renterId})

      const order = store.getters['getByRenterId'](renterId)

      axios.get(getStateUrl(renterId)).then(({data: state}) => {
        store.dispatch('articles/add', state.articles, {root: true})
        store.commit('setRenter', {renterId, renter: state.renter}, {root: true})

        const fixedOrder = getFixedOrder(order, state.renter, getArticle)

        _.each(_.keys(fixedOrder), (k) => {
          Vue.set(order, k, fixedOrder[k])
        })
      }).then(() => {
        if (errors['line_items.not_enough']) {
          store.dispatch(
            'availability/loadAvailable',
            {renterId, reason: 'saveOrder'},
            {root: true}
          )
        }
      })
    },
    setRentPeriod(store, {range, renterId, isInitial}) {
      store.commit('setRentPeriod', {range, renterId})
      if (range) {
        store.dispatch(
          'availability/loadAvailable',
          {renterId, reason: isInitial ? 'navigate' : 'setRentPeriod'},
          {root: true}
        ).then(() => {
          // store.commit('fixQuantities', {renterId})
        })
      } else {
        store.commit('availability/clearAvailable', {renterId}, {root: true})
      }
    },
    beginEditing(store, {order, renterId}) {
      const towns = _.map(store.rootState.branches, b => b.town)
      store.commit('town/setCurrent', _.find(towns, t => t.id === order.town_id), {root: true})
      store.commit('setBackup', {order: _.cloneDeep(order), renterId})
      store.commit('setCurrent', {order, renterId})
      store.dispatch(
        'availability/loadAvailable',
        {renterId, reason: 'setRentPeriod'},
        {root: true}
      )
    },
    revert(store, {renterId}) {
      if (!_.get(store.state.backupsByRenterId, renterId)) {
        return
      }
      store.commit('restoreFromBackup', {renterId})
      store.dispatch('finishEditing', {renterId})
    },
    finishEditing(store, {renterId}) {
      store.commit('clearErrors', {renterId})
      store.commit('setCurrentFromLocalStorage', {townId: store.rootGetters.townId})
    },
    place(store, {order, renterId}) {
      if (placePromise) {
        return placePromise
      }

      const errors = getErrors(order)
      if (errors) {
        store.dispatch('setErrors', {errors, renterId})
        return Promise.reject(errors)
      }

      const blueprint = _.merge({town_id: store.rootGetters.townId}, getBlueprint(order))

      reachGoal('createOrder:begin')

      placePromise = axios.post(
        getOrdersUrl(renterId),
        {blueprint: blueprint}
      ).then(({data: placedOrder}) => {
        placePromise = undefined
        reachGoal('createOrder:done', {
          order_price: placedOrder.total,
          currency: 'RUB'
        })
        fbqWrapper(
          'track',
          'Purchase', {currency: "RUB", value: placedOrder.total}
        )
        return Promise.resolve(placedOrder)
      }, ({response: {data: errors}}) => {
        placePromise = undefined
        store.dispatch('setErrors', {errors, renterId})
        return Promise.reject(errors)
      })

      return placePromise
    },
    save(store, {order, renterId}): Promise<Object> {
      const blueprint = _.omit(getBlueprint(order), 'town_id')

      const savePromise = axios.patch(getOrdersUrl(renterId) + `/${order.id}`, {blueprint})
        .then(({data: orderFromServer}) => {
            store.dispatch('finishEditing', {renterId})
            _.each(_.keys(orderFromServer), (k) => {
              Vue.set(order, k, orderFromServer[k])
            })
            return Promise.resolve(order)
          }, ({response: {data: errors}}) => {
            return Promise.reject(errors)
          }
        )

      return savePromise
    },
    cancel(context, { order, renterId }) {
      if (cancelPromise) {
        return cancelPromise;
      }

      cancelPromise = axios.patch(getOrdersUrl(renterId) + `/${order.id}/cancel`)
        .finally(() => {
          cancelPromise = null;
        });

      return cancelPromise;
    },
    confirmWithoutProcessing(store, {order, renterId}): Promise<Object> {
      return axios.patch(getOrdersUrl(renterId) + `/${order.id}/confirm_without_processing`)
    },
    confirmAndProcess(store, {order, renterId}): Promise<Object> {
      return axios.patch(getOrdersUrl(renterId) + `/${order.id}/confirm_and_process`)
    },
    payFromSurplus(store, {order, renterId}): Promise<Object> {
      return axios.post(getOrdersUrl(renterId) + `/${order.id}/pay_from_surplus`)
        .then(({data: paidOrder}) => {
            return Promise.resolve(paidOrder)
          }, ({response: {data: errors}}) => {
            return Promise.reject(errors)
          }
        )
    }
  }
}
