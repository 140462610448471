<template lang="pug">
  .content(v-if="customer")
    router-view(:customer="customer")
    .spreader
    .sidebar-wrapper
      agv-customer-sidebar(:customer="customer" :get-balance-for="getBalanceFor")
</template>

<script>
  import AgvCustomerSidebar from 'Aggregator/components/agv-customer-sidebar'

  export default {
    name: 'agv-customer-page',
    components: {AgvCustomerSidebar},
    computed: {
      customer() {
        return this.$store.state.customer.current
      }
    },
    methods: {
      getBalanceFor(renterId) {
        return _.get(
          _.get(this.customer.customers_by_renter_id, renterId),
          'balance'
        )
      }
    }
  }
</script>

<style scoped>
  .page-title {
    font-weight: 900;
    font-size: 28px;
    color: #4A4A4A;
    white-space: nowrap;
    letter-spacing: 0;
    margin-right: 30px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 71px;
  }

  .content >>> .form-container {
    flex-grow: 1;
    width: 100%;
  }

  .spreader {
    flex-basis: 40px;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .sidebar-wrapper {
    flex-grow: 0;
    flex-shrink: 0;
  }
</style>
