function getDistrictAbbreviation(districtName) {
  return _.join(_.map(districtName.split(/[ -]/), w => w[0]), '').toUpperCase()
}

function getAddressComponents(res) {
  return res.properties.get('metaDataProperty.GeocoderMetaData.Address.Components')
}

function getCurrentTownName(scope) {
  return scope.currentBranch.town.name
}

function getLocality(res) {
  return _.get(
    _.find(
      getAddressComponents(res),
      c => c.kind === 'locality'),
    'name'
  )
}

function getDistrictName(districtObject) {
  return _.get(_.first(
    _.filter(
      getAddressComponents(districtObject),
      c => {
        return c.kind === 'district' && c.name.match(/округ/)
      }
    )
  ), 'name')
}

function getAddress(name, locality, districtName, currentTownName) {
  return _.join(
    _.compact([
      name.indexOf(locality) === 0 || locality === currentTownName ? null : locality,
      districtName ? getDistrictAbbreviation(districtName) : undefined,
      name
    ]),
    ', '
  )
}

function writeSelected(res, currentTownName, addressSelectCallback) {
  const ymaps = window.ymaps

  let coordinates

  const selectedResultName = res.properties._data.name
  const locality = getLocality(res)

  // ymaps.geocode(selectedResultName, {results: 1})
  //   .then(function (res) {
  //     coordinates = res.geoObjects.get(0).geometry.getCoordinates()
  //     return ymaps.geocode(coordinates, {kind: 'district', results: 1})
  //   })
  //   .then(function (res) {
  //     const districtObject = res.geoObjects.get(0)
  //
  //     let district
  //
  //     if (districtObject) {
  //       district = getDistrictName(districtObject)
  //     }

  addressSelectCallback({
    temporaryAddressMetadata: {
      district: null,
      locality: null,
      coordinates: null
    },
    temporaryAddress: getAddress(selectedResultName, locality, null, currentTownName)
  })
  // })
}

function createSearchControl() {
  return new window.ymaps.control.SearchControl({
    options: {
      provider: 'yandex#map',
      suppressYandexSearch: true,
      noPlacemark: true,
    }
  })
}

function createMap(ymaps, coordinatesOfCurrentTown) {
  return new ymaps.Map('map', {
    center: coordinatesOfCurrentTown,
    zoom: 10,
    controls: []
  })
}

export default function openMap(currentTownName, addressSelectCallback, doneCallback) {
  const ymaps = window.ymaps
  ymaps.geocode(currentTownName).then((res) => {
    const map = createMap(
      ymaps,
      res.geoObjects.get(0).geometry.getCoordinates()
    )
    let results = new ymaps.GeoObjectCollection(null)
    map.geoObjects.add(results)

    const searchControl = createSearchControl()
    map.controls.add(searchControl)

    const doneButton = new ymaps.control.Button('Готово')
    map.controls.add(doneButton, {float: 'right'})

    doneButton.events.add('click', doneCallback)

    searchControl.events.add('resultselect', function (e) {
      var index = e.get('index')
      searchControl.getResult(index).then(function (res) {
        results.add(res)

        writeSelected(res, currentTownName, addressSelectCallback, doneCallback)
      })
    }).add('submit', function () {
      results.removeAll()
    })
  })
}
