<template lang="pug">
.agv-add-to-order-button
  el-button.remove-btn(v-if='hasArticle' @click='remove' :size="renderedSize")
    | Убрать из заказа
  el-input(v-else  placeholder="X" v-model="quantity" :size="renderedSize")
    el-button(slot="append" @click='setQuantity')
      | Добавить
</template>

<script>
import {reachGoal} from 'Shared/lib/metrika'
import eventBus from 'Shared/eventBus'

export default {
  name: 'agv-add-to-order-button',
  props: {
    article: Object,
    order: Object,
    available: Number,
    renterId: Number,
    size: String
  },
  data() {
    return {
      quantity: 1
    }
  },
  watch: {
    quantity: 'validateQuantity',
    available: 'validateQuantity'
  },
  computed: {
    componentsWithQuantity() {
      return this.article.type === 'Product' ? [{id: this.article.id, quantity: 1}] : this.article.components_with_quantity
    },
    hasArticle() {
      if (!this.order) {
        return false
      }

      return _.every(
          this.componentsWithQuantity,
          ({id}) => _.find(this.order.line_items, {article_id: id})
      )
    },
    renderedSize() {
      return this.size || 'mini'
    }
  },
  methods: {
    validateQuantity() {
      if (this.quantity > this.available) {
        this.quantity = this.available
      }
    },
    setQuantity() {
      if (this.article.type === 'Product') {
        this.$store.commit(
            'order/setArticleQuantity',
            {
              articleId: this.article.id,
              quantity: this.quantity,
              renterId: this.renterId
            })
      } else {
        this.$store.commit(
            'order/addArticlesQuantity',
            {
              componentsWithQuantity: this.article.components_with_quantity,
              quantity: this.quantity,
              renterId: this.renterId
            })
      }
      reachGoal('addArticle', {
        article_name: this.article.name
      })
      eventBus.$emit('article:added-to-cart', this.article)
    },
    remove() {
      _.each(this.componentsWithQuantity, ({id}) => {
            this.$store.commit(
                'order/removeArticle',
                {
                  articleId: id,
                  renterId: this.renterId
                }
            )
          }
      )
    }
  }
}
</script>

<style scoped lang="css">
.agv-add-to-order-button {
  max-width: 100px;
}

.el-input >>> .el-input-group__append {
  background-color: #5ee24a;
  border: 1px solid #5ee24a;
  color: white;
  width: auto;
}

.el-input >>> .el-input-group__append:hover {
  background-color: #64eb50;
  border: 1px solid #64eb50;
}

.remove-btn {
  background-color: #afb0b3;
  border: 1px solid #afb0b3;
  color: white !important;
}

.remove-btn:hover {
  background-color: #afb0b3;
  border: 1px solid #afb0b3;
}

.el-input >>> .el-input__inner {
  border: 1px solid #5ee24a;
}

.el-input.el-input--mini >>> .el-input__inner {
  width: 42px;
  padding-left: 8px;
  padding-right: 4px;
}

.el-input.el-input--mini >>> .el-input-group__append button {
  padding: 10px 10px;
}
</style>
