import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import {Vue as SentryVueIntegration} from '@sentry/integrations'

import store from 'RenterSite/store/index'
import infiniteScroll from 'vue-infinite-scroll'
import BackToTop from 'vue-backtotop'
import VueHotkey from 'v-hotkey'
import iFrameResizeContentWindow from 'iframe-resizer/js/iframeResizer.contentWindow'

Vue.use(infiniteScroll)
Vue.use(BackToTop)
Vue.use(VueHotkey)

import 'RenterSite/vueImports.es6'

import NewSiteApp from 'RenterSite/renter_site.vue'

import 'Aggregator/filters.es6'
import {SENTRY_IGNORED_ERRORS} from 'Shared/lib/sentry'

Vue.config.productionTip = false

store.dispatch('articles/add', window.INITIAL_STATE.articles)
store.commit('order/setCurrentFromLocalStorage', {townId: store.state.town.current.id})
const currentOrder = store.state.order.ordersByRenterId[store.state.renter.id]
if (_.get(currentOrder, 'acquisition')) {
  store.dispatch('order/setRentPeriod', {
    range: [currentOrder.acquisition, currentOrder.returning],
    renterId: store.state.renter.id,
    isInitial: true
  })
}

store.commit('order/fixQuantities', {renterId: store.state.renter.id})

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    store,
    render: h => h(NewSiteApp)
  })
})

if (window.environment === 'production' || window.environment === 'staging') {
  Sentry.init({
    dsn: 'https://0bb80b6d3a1a4a31959440379707bc0d@sentry.io/1432159',
    environment: window.environment,
    release: window.RELEASE_NAME,
    captureUnhandledRejections: false,
    ignoreErrors: SENTRY_IGNORED_ERRORS,
    integrations: [new SentryVueIntegration({
      Vue,
      attachProps: true
    })]
  })
}
