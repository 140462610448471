import Vue from 'vue'
import VueCookie from 'vue-cookie'
import VueRouter from 'vue-router'
import vClickOutside from 'v-click-outside'
import VueImg from 'Aggregator/components/agv-gallery'
import VueHotkey from 'v-hotkey'

import {
  Button,
  DatePicker,
  Dialog,
  Carousel,
  CarouselItem,
  Checkbox,
  InputNumber,
  Select,
  Option,
  Popover,
  Radio,
  RadioGroup,
  RadioButton,
  TimeSelect,
  Badge,
  Alert,
  Container,
  Aside,
  Input,
  Tabs,
  TabPane,
  Form,
  FormItem
} from 'element-ui'
import {Loading, Popover as PopoverDirective} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

import VueProgressBar from 'vue-progressbar'

Vue.use(Loading)
Vue.use(PopoverDirective)
Vue.use(VueCookie)
Vue.use(VueRouter)
Vue.use(vClickOutside)
Vue.use(VueImg)
Vue.use(VueHotkey)
Vue.component(Button.name, Button)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Dialog.name, Dialog)
Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Popover.name, Popover)
Vue.component(Radio.name, Radio)
Vue.component(TimeSelect.name, TimeSelect)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Badge.name, Badge)
Vue.component(Alert.name, Alert)
Vue.component(Container.name, Container)
Vue.component(Aside.name, Aside)
Vue.component(Input.name, Input)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(RadioButton.name, RadioButton)

locale.use(lang)

Vue.use(VueProgressBar, {
  color: '#4990E2',
  failedColor: 'red',
  height: '2px'
})

import '../aggregator/filters.es6'
