<template>
  <div>
    <IconLikeButton @click="onClickHeart"
                    :is-liked="localIsLiked"/>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from 'Shared/eventBus';

import IconLikeButton from 'Aggregator/components/icon-like-button'

export default {
  components: { IconLikeButton },
  props: {
    article: {
      type: Object,
      required: true
    },
    isLoggedIn: Boolean
  },
  data() {
    return {
      localIsLiked: this.article.is_liked,
      isRequestInProgress: false
    }
  },
  watch: {
    'article.is_liked': function (newVal) {
      this.localIsLiked = newVal;
    }
  },
  methods: {
    onClickHeart() {
      if (!this.isLoggedIn) {
        eventBus.$emit('proceedToLogin')
        return;
      }

      if (this.isRequestInProgress) {
        return;
      }

      if (this.article.is_liked) {
        this.unlikeArticle();
      } else {
        this.likeArticle();
      }
    },
    likeArticle() {
      // Optimistically update UI
      this.localIsLiked = true;

      this.isRequestInProgress = true;

      axios.post(`/api/likes/${this.article.renter_id}/${this.article.id}`)
          .then(response => {
            // On success, emit update to the parent component
            this.$emit('update', {...this.article, is_liked: true})
          }, response => {
            console.error(response)
            // On failure, rollback the change locally and emit update to the parent component
            this.localIsLiked = false;
            this.$emit('update', {...this.article, is_liked: false})
          }).finally(() => {
        this.isRequestInProgress = false;
      });
    },
    unlikeArticle() {
      // Optimistically update UI
      this.localIsLiked = false;

      this.isRequestInProgress = true;

      axios.delete(`/api/likes/${this.article.renter_id}/${this.article.id}`)
          .then(response => {
            // On success, emit update to the parent component
            this.$emit('update', {...this.article, is_liked: false})
          }, response => {
            console.error(response)
            // On failure, rollback the change locally and emit update to the parent component
            this.localIsLiked = true;
            this.$emit('update', {...this.article, is_liked: true})
          }).finally(() => {
        this.isRequestInProgress = false;
      });
    }
  }
}
</script>

<style scoped>
</style>
