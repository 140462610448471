<template lang="pug">
  .popup
    form(autocomplete="on" v-on:keyup.enter="$emit('login', loginCredentials)")
      .row
        input.login(:class='{error: errors.login || errors.error}' placeholder="Ваша электронная почта" type='text' v-model='loginCredentials.login')
      el-alert(v-if="errors.login" title="Пользователь с такой почтой не зарегистрирован" type="error" show-icon :closable="false")
      .row
        input.password(:class='{error: errors.error}' placeholder="Ваш пароль" type="password" v-model='loginCredentials.password')
      .row
        el-button(type="primary" @click="$emit('login', loginCredentials)")
          | Войти
        el-button(type="text" @click="$emit('requestOneTimeAccess', loginCredentials)")
          | Восстановить доступ
        el-button(type="text" @click="$emit('clickedSignup')")
          | Зарегистрироваться
</template>

<script>
  export default {
    name: 'form-agv-login',
    data() {
      return {
        loginCredentials: {
          login: '',
          password: '',
        },
      }
    },
    props: {
      errors: Object
    },
    watch: {
      'loginCredentials.login': 'clearError',
      'loginCredentials.password': 'clearError',
    },
    methods: {
      clearError() {
        this.$emit('clearError')
      }
    }
  }
</script>

<style scoped>
  .popup >>> .el-alert {
    margin: 10px 0;
  }
</style>

<style scoped>
  .error {
    color: #d0021b;
  }

  .error::-webkit-input-placeholder {
    color: #e88290;
    font-family: 'helveticaneuecyrroman', Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .error:-moz-placeholder {
    color: #e88290;
    font-family: 'helveticaneuecyrroman', Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .row {
    margin-bottom: 10px;
  }

  .row:last-child {
    margin-top: 20px;
  }

  .renter-dashboard-enter-link {
    font-size: 14px;
    padding-top: 6px;
    margin-right: 26px;
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    background: #fff;
    color: #4a90e2;
    font-size: 14px;
    padding: 0;
    font-family: 'helveticaneuecyrroman', Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .el-button + .el-button {
    margin-left: 20px;
  }
</style>
