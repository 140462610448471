<template lang="pug">
  .colors-container(:class="{'colors-container_compact': isCompact}")
    agv-color-btn(v-for='option in colorOptions' :key="option.value"
    :color="option.value"
    :color-name="option.name"
    :is-checked="isColorChecked(option.value)"
    :is-active="isColorActive(option.value)"
    :is-compact="isCompact"
    @click='toggleColor(option.value)')
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import AgvColorBtn from 'Aggregator/components/agv-color-btn'

  export default {
    components: {AgvColorBtn},
    methods: {
      isColorActive(color) {
        return _.includes(this.activeColors, color)
      },
      isColorChecked(color) {
        return !!this.checkedColors[color]
      },
      toggleColor(color) {
        this.$emit('toggle', color)
      }
    },
    name: 'agv-color-filter',
    props: {
      colorOptions: Array,
      activeColors: Array,
      checkedColors: Object,
      isCompact: Boolean
    }
  }
</script>

<style scoped>
  .colors-container {
    width: 200px;
    margin-bottom: 10px;
  }

  .colors-container.colors-container_compact {
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
</style>
