import axios from 'axios'
import dayjs from "dayjs";

let promise

export default function getActions(
  getAvailabilityUrl: Function
) {
  return {
    loadAvailable(
      {state, rootState, commit, rootGetters},
      {renterId, reason}
    ) {
      const order = rootState.order.ordersByRenterId[renterId]

      if (!(order && order.acquisition && order.returning)) {
        return Promise.resolve()
      }

      if (promise) {
        return promise
      }

      promise = new Promise((resolve, reject) => {
        const url = getAvailabilityUrl(
          order.acquisition,
          order.returning,
          order.id,
          renterId,
          rootGetters.townId,
          order.delivery?.returning_hour_end
        )

        const params = _.isNil(order.delivery?.returning_hour_end) ? '' : `?returning_hour_end=${order.delivery.returning_hour_end}`

        axios.get(url + params).then(({data: available}) => {
          commit('setAvailableForPeriod', {available, reason, renterId})
          promise = undefined
          if (resolve) {
            resolve(available)
          }
        }, () => {
          promise = undefined
          reject()
        })
      })

      return promise
    }
  }
}
