// @flow
// eslint-disable

import Vue from 'vue'
import _ from 'lodash'
import dayjs from 'dayjs'
import {PaymentMethodString} from 'Shared/payment'
import {METHODS as PAYMENT_METHODS} from 'Shared/paymentMethods'
import {calculateLoadingUnloading} from "Shared/loadingUnloadingCalculation";
import type {RenterWithUnloadingLoading, DeliveryWithUnloadingLoading} from "Shared/unloadingLoadingCalculation";
import {
  deliveryPrice as pifakitServiceDeliveryPrice,
  arrangementPrice as pifakitServiceArrangementPrice
} from "Shared/pifakitService";

function __range__(left: number, right: number, inclusive: boolean) {
  let range = []
  let ascending = left < right
  let end = !inclusive ? right : ascending ? right + 1 : right - 1
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i)
  }
  return range
}

type LineItem = {
  quantity: number,
  article_id: number,
  article: { price: number },
  setup_duration: number,
  teardown_duration: number,
  gross_mass: number,
  gross_volume: number,
  vehicle: string
}

type BranchWithNonworkDays = {
  cp_off_days: Array<boolean>,
  cp_holidays: Array<boolean>
}

type BranchWithPriceMultipliers = {
  rent_price_multiplier: number,
  arrangement_price_multiplier: number,
  delivery_price_multiplier: number
}

type RenterWithVehicleUsages = {
  uses_cars: boolean,
  uses_vans: boolean,
  uses_trucks: boolean
}

type RenterWithVehicleCapacities = {
  car_capacity: number,
  van_capacity: number,
  truck_capacity: number
}

type RenterWithIntratownDeliveryPrices = {
  intratown_car_delivery_price_per_km: number,
  intratown_van_delivery_price_per_km: number,
  intratown_truck_delivery_price_per_km: number
}

type RenterWithExtratownDeliveryPrices = {
  extratown_car_delivery_price_per_km: number,
  extratown_van_delivery_price_per_km: number,
  extratown_truck_delivery_price_per_km: number
}

type RenterWithArrangementProperties = {
  workers_manhours_relations: Array<number>,
  min_arrangement_worker_price: number,
  arrangement_price_per_hour: number
}

type RenterWithDiscountProperties = {
  rent_discount: number,
  service_discount: number
}

type RenterWithDurationProperties = {
  additional_day_charge: number,
  base_rent_duration: number
}

type CustomerWithDiscountProperties = {
  is_professional: boolean,
  special_rent_discount: number,
  special_service_discount: number
}

function getFreeRentDays(
  order: { acquisition: string, returning: string, delivery: ?Object },
  branch: BranchWithNonworkDays
) {
  if (order.delivery) {
    return 0
  } else {
    return _.reduce(
      __range__(0, getRentPeriodLength(order.acquisition, order.returning), false),
      (sum, offset) => {
        const date = dayjs(order.acquisition).add(offset, 'day').toDate()
        if (isPlaydayInBranch(branch, date)) {
          return sum + 1
        } else {
          return sum
        }
      }
    )
  }
}

export function getRentPeriodLength(acquisition: string, returning: string, returning_hour_end: ?number) {
  if (!returning) {
    return 0
  }

  const isReturningHourEndInMorning = !_.isNull(returning_hour_end) && returning_hour_end <= 4

  return dayjs(returning).diff(dayjs(acquisition), 'day') + 1 - (isReturningHourEndInMorning ? 1 : 0)
}

export function getAdditionalRentDays(
  order: { acquisition: string, returning: string, delivery: ?Object },
  branch: BranchWithNonworkDays,
  renter: { base_rent_duration: number },
  article: { base_rent_duration: ?number },
  returning_hour_end: number
) {
  if (!order.returning) {
    return 0
  }

  return Math.max(
    0,
    getRentPeriodLength(order.acquisition, order.returning, returning_hour_end) -
    (article.base_rent_duration || renter.base_rent_duration) -
    getFreeRentDays(order, branch)
  )
}

export function getAdditionalRentDaysCharge(
  order: {
    acquisition: ?string,
    returning: ?string,
    delivery: ?{ returning_hour_end: number },
    line_items: Array<LineItem>
  },
  branch: BranchWithNonworkDays & { rent_price_multiplier: number },
  renter: RenterWithDurationProperties & { id: number }
) {
  if (!(order.acquisition && order.returning)) {
    return 0
  }

  return _.sum(
    _.map(
      order.line_items,
      (li) => {
        const additionalDays = getAdditionalRentDays(
          order,
          branch,
          renter,
          li.article,
          order.delivery?.returning_hour_end
        )
        return Math.round(
          additionalDays *
          li.quantity *
          getArticlePrice(li.article, branch) *
          renter.additional_day_charge
        )
      }
    )
  )
}

export function getBaseRentPriceWithoutModifiers(
  line_items: Array<LineItem>,
  branch: { rent_price_multiplier: number }
) {
  return _.reduce(
    line_items,
    (total, li: LineItem) => total + (
      li.quantity *
      getArticlePrice(li.article, branch)
    ),
    0
  )
}

export function getActualOrSmallestRentPriceWithoutModifiers(
  line_items: Array<LineItem>,
  isDeliveryPresent: boolean,
  branch: { rent_price_multiplier: number },
  renter: { smallest_rent_price_with_delivery: number, smallest_rent_price_without_delivery: number }
) {
  return Math.max(
    isDeliveryPresent ? renter.smallest_rent_price_with_delivery : renter.smallest_rent_price_without_delivery,
    getBaseRentPriceWithoutModifiers(line_items, branch)
  )
}

export function getTotalRentPriceWithoutDiscountOrUrgencyCharge(
  order: { acquisition: ?string, returning: ?string, delivery: ?Object, line_items: Array<LineItem> },
  branch: BranchWithNonworkDays & { rent_price_multiplier: number },
  renter: RenterWithDurationProperties
) {
  return getActualOrSmallestRentPriceWithoutModifiers(order.line_items, !!order.delivery, branch, renter) +
    getAdditionalRentDaysCharge(order, branch, renter)
}

function getMinWorkPrice(
  line_items: Array<LineItem>,
  workers_manhours_relations: Array<number>,
  min_arrangement_worker_price: number,
  arrangementPart: 'setup' | 'teardown',
  minimalWorkerCount: number
) {
  const workers = Math.max(
    minimalWorkerCount,
    getArrangementWorkerCount(
      getArrangementAmount(line_items, arrangementPart),
      workers_manhours_relations
    )
  )
  return min_arrangement_worker_price * workers
}

function sumLineItemProperty(
  line_items: Array<LineItem>,
  propertyName: string
) {
  return _.reduce(
    line_items,
    (total, li) => {
      return total + (li.quantity * li.article[propertyName])
    },
    0
  )
}

function getRatedWorkPrice(
  line_items: Array<LineItem>,
  arrangement_price_per_hour: number,
  arrangementPart: 'setup' | 'teardown'
): number {
  return getArrangementAmount(line_items, arrangementPart) * arrangement_price_per_hour
}

function getArrangementPartPrice(
  line_items_of_type: Array<LineItem>,
  renter: RenterWithArrangementProperties,
  arrangementPart: 'setup' | 'teardown',
  workerType: ?('worker_1' | 'worker_2')
) {
  const prefix = (workerType ? workerType + '_' : '')
  const min = getMinWorkPrice(
    line_items_of_type,
    renter.workers_manhours_relations,
    renter[prefix + 'min_arrangement_worker_price'],
    arrangementPart,
    getRequiredArrangementWorkerCount(line_items_of_type)
  )
  const rated = getRatedWorkPrice(
    line_items_of_type,
    renter[prefix + 'arrangement_price_per_hour'],
    arrangementPart
  )
  return Math.max(min, rated)
}

function isVanRequired(line_items: Array<LineItem>): boolean {
  return _.find(line_items, (li) => {
    return li.article.vehicle === 'van'
  })
}

function isTruckRequired(line_items: Array<LineItem>): boolean {
  return _.find(line_items, (li) => {
    return li.article.vehicle === 'truck'
  })
}

function getCapacities(
  types: Array<VehicleString>,
  renter: RenterWithVehicleCapacities,
) {
  return _.fromPairs(_.map(types, t => [t, renter[t + '_capacity']]))
}

function getTypes(
  possibleTypes: Array<VehicleString>,
  renter: RenterWithVehicleUsages,
  isDeliverySelfOrganized: boolean
) {
  if (isDeliverySelfOrganized) {
    return possibleTypes
  } else {
    return _.filter(possibleTypes, (type: VehicleString) => renter[(`uses_${type}s`)])
  }
}

type VehicleString = 'car' | 'van' | 'truck'

function getTransportQuantity(
  line_items: Array<LineItem>,
  renter: RenterWithVehicleCapacities,
  type: VehicleString
) {
  return Math.max(1, getVolume(line_items) / renter[type + '_capacity'])
}

function getExtratownDeliveryPrice(
  line_items: Array<LineItem>,
  delivery: { distance: number },
  renter: RenterWithExtratownDeliveryPrices & RenterWithVehicleCapacities,
  type: VehicleString
) {
  if (!!delivery.distance) {
    const q = getTransportQuantity(line_items, renter, type)
    const d = delivery.distance
    const dPrice = renter[`extratown_${type}_delivery_price_per_km`]
    return q * d * dPrice
  } else {
    return 0
  }
}

function getIntratownDeliveryPrice(
  line_items: Array<LineItem>,
  renter: RenterWithVehicleCapacities & RenterWithIntratownDeliveryPrices,
  type: VehicleString
) {
  const q = getTransportQuantity(line_items, renter, type)
  const pricePerVehicle = renter[`intratown_${type}_delivery_price`]
  return q * pricePerVehicle
}

export function getServicePriceWithoutModifiers(
  order: {
    line_items: Array<LineItem>,
    delivery: DeliveryWithUnloadingLoading,
    arrangement: boolean | Object
  },
  branch: BranchWithPriceMultipliers,
  renter: RenterWithVehicleCapacities &
    RenterWithVehicleUsages &
    RenterWithExtratownDeliveryPrices &
    RenterWithIntratownDeliveryPrices &
    RenterWithArrangementProperties &
    RenterWithUnloadingLoading,
  pifakit_service
) {
  return (
      order.arrangement ?
        (
          pifakit_service ?
            pifakitServiceArrangementPrice(
              order.line_items,
              pifakit_service,
              renter.workers_manhours_relations,
              renter.service_discount
            ) :
            getArrangementPrice(
              order.line_items,
              branch.arrangement_price_multiplier,
              renter
            )
        )
        :
        0
    ) +
    (order.delivery ?
        (
          pifakit_service ?
            pifakitServiceDeliveryPrice(
              order,
              pifakit_service,
              renter.service_discount
            ) :
            getDeliveryPrice(
              order.line_items,
              order.delivery,
              branch.delivery_price_multiplier,
              renter
            )
        )
        :
        0
    )
}

function getArrangementAmount(line_items, part): number {
  return _.reduce(
    line_items,
    (total, li) => {
      return total + (li.quantity * li.article[part + '_duration'] * li.article.worker_count)
    },
    0
  )
}

export function getSetupAmount(line_items: Array<LineItem>): number {
  return getArrangementAmount(line_items, 'setup')
}

export function getTeardownAmount(line_items: Array<LineItem>): number {
  return getArrangementAmount(line_items, 'teardown')
}

export function getArrangementPrice(
  line_items: Array<LineItem>,
  arrangement_price_multiplier: number,
  renter: RenterWithArrangementProperties
) {
  const worker1Items = _.filter(line_items, li => li.article.worker === 'worker_1')
  const worker2Items = _.filter(line_items, li => li.article.worker === 'worker_2')
  const otherWorkerItems = _.filter(line_items, li => {
    return !_.includes(['worker_1', 'worker_2'], li.article.worker)
  })

  const prices = _.map([
      ['worker_1', worker1Items],
      ['worker_2', worker2Items],
      [null, otherWorkerItems]
    ], ([workerType, lineItemsOfType]) => {
      if (!lineItemsOfType.length) {
        return 0
      }
      const setupPrice = getArrangementPartPrice(
        lineItemsOfType,
        renter,
        'setup',
        workerType
      )
      const teardownPrice = getArrangementPartPrice(
        lineItemsOfType,
        renter,
        'teardown',
        workerType
      )
      return setupPrice + teardownPrice
    }
  )
  return Math.round(_.sum(prices) * arrangement_price_multiplier)
}

export function getSetupDuration(line_items: Array<LineItem>, workers_manhours_relations: Array<number>) {
  const amount = getSetupAmount(line_items)
  return amount / getArrangementWorkerCount(amount, workers_manhours_relations)
}

export function getTeardownDuration(line_items: Array<LineItem>, workers_manhours_relations: Array<number>) {
  const amount = getTeardownAmount(line_items)
  return amount / getArrangementWorkerCount(amount, workers_manhours_relations)
}

export function getDiscountPortion(
  order: { id: ?number },
  discountKind: string,
  customer: CustomerWithDiscountProperties,
  renter: RenterWithDiscountProperties
) {
  const customerProp = `special_${discountKind}_discount`
  const orderProp = `${discountKind}_discount_portion`
  const renterProp = `${discountKind}_discount`

  if (order.id) {
    return order[orderProp]
  } else {
    if (customer.is_professional) {
      if (customer[customerProp] !== null) {
        return customer[customerProp]
      }
      return renter[renterProp]
    } else {
      return 0
    }
  }
}

export function getDeliveryPrice(
  line_items: Array<LineItem>,
  delivery: { distance: number } & DeliveryWithUnloadingLoading,
  delivery_price_multiplier: number,
  renter: RenterWithVehicleCapacities &
    RenterWithVehicleUsages &
    RenterWithExtratownDeliveryPrices &
    RenterWithIntratownDeliveryPrices &
    RenterWithUnloadingLoading
) {
  const vehicle1Items = _.filter(line_items, li => li.article.vehicle === 'vehicle_1')
  const vehicle2Items = _.filter(line_items, li => li.article.vehicle === 'vehicle_2')
  const otherVehicleItems = _.filter(line_items, li => {
    return !_.includes(['vehicle_1', 'vehicle_2'], li.article.vehicle)
  })

  const prices = _.map([
      ['vehicle_1', vehicle1Items],
      ['vehicle_2', vehicle2Items],
      [getTransportType(otherVehicleItems, renter, false), otherVehicleItems]
    ], ([vehicleType, lineItemsOfType]) => {
      if (!lineItemsOfType.length) {
        return 0
      }
      const exPrice = getExtratownDeliveryPrice(lineItemsOfType, delivery, renter, vehicleType)
      const inPrice = getIntratownDeliveryPrice(lineItemsOfType, renter, vehicleType)
      return exPrice + inPrice
    }
  )
  const unloadingLoadingPrice = calculateLoadingUnloading(renter, delivery, getMass(line_items))
  return Math.round((_.sum(prices) + unloadingLoadingPrice) * delivery_price_multiplier)
}

export function isPlaydayInBranch(
  branch: BranchWithNonworkDays,
  date: Date
) {
  const isOffDay = branch.cp_off_days[date.getDay()]
  const isHoliday = _.find(branch.cp_holidays, h => (h[0] === (date.getMonth() + 1)) && (h[1] === date.getDate()))
  return isOffDay || isHoliday
}

export function isDateDisabled(date: Date) {
  return dayjs(date).isBefore(dayjs(new Date()).startOf('day'))
}

export function isDateSelectable(date: Date) {
  return !isDateDisabled(date)
}

export function getTransportType(
  line_items: Array<LineItem>,
  renter: RenterWithVehicleUsages & RenterWithVehicleCapacities,
  isDeliverySelfOrganized: boolean
) {
  const possibleTypes: Array<VehicleString> = isTruckRequired(line_items) ?
    ['truck', 'truck_p', 'truck_pp'] :
    (isVanRequired(line_items) ?
        ['van', 'truck', 'truck_p', 'truck_pp'] :
        ['car', 'van', 'truck', 'truck_p', 'truck_pp']
    )
  const types = getTypes(possibleTypes, renter, isDeliverySelfOrganized)
  const capacities = isDeliverySelfOrganized ? {car: 1, van: 8, truck: 20} : getCapacities(types, renter)

  const can_contain_order = _.filter(types, type => capacities[type] >= getVolume(line_items))
  if (can_contain_order.length === 0) {
    return _.filter(types, type => capacities[type] === _.max(_.values(capacities)))[0]
  } else {
    const smallestCapacity = _.min(_.map(can_contain_order, type => capacities[type]))
    return _.find(types, t => capacities[t] === smallestCapacity)
  }
}

export function getArticlePrice(
  article: { price: ?number, price_in_town: ?number },
  branch: { rent_price_multiplier: number }
) {
  return article.price_in_town || Math.round(article.price * branch.rent_price_multiplier)
}

export function getRequiredArrangementWorkerCount(
  line_items: Array<LineItem>
) {
  return _.max(_.map(line_items, li => li.article.worker_count))
}

export function getArrangementWorkerCount(
  amount: number,
  workers_manhours_relations: Array<number>
) {
  const index = _.findIndex(workers_manhours_relations, hours => hours > amount)
  if (index !== -1) {
    return index + 1
  } else {
    return Math.round((amount * 9) / workers_manhours_relations[8])
  }
}

export function getMass(line_items: Array<LineItem>) {
  return sumLineItemProperty(line_items, 'gross_mass')
}

export function getVolume(line_items: Array<LineItem>) {
  return Math.max(0.1, sumLineItemProperty(line_items, 'gross_volume'))
}

export function getAdHocLineItemsPrice(ad_hoc_line_items: ?Array<{ price: number | string }>) {
  if (!ad_hoc_line_items) {
    return 0
  }
  return _.sumBy(ad_hoc_line_items, ahli => ahli.price ? parseInt(ahli.price) || 0 : 0)
}

type OrderWithUrgency = {
  id: ?number,
  acquisition: ?string,
  is_urgent: ?boolean
}

export function getIsUrgent(order: OrderWithUrgency) {
  if (!order.acquisition) {
    return false
  }

  if (order.id) {
    return order.is_urgent
  }

  return dayjs(order.acquisition).startOf('day').diff(dayjs(new Date()).startOf('day'), 'days') < 2
}

export function getRentUrgencyChargePortion(order: OrderWithUrgency,
                                            renter: { rent_urgency_charge_portion: number }) {
  return getIsUrgent(order) ? renter.rent_urgency_charge_portion : 0
}

export function getServiceUrgencyChargePortion(order: OrderWithUrgency,
                                               renter: { service_urgency_charge_portion: number }) {
  return getIsUrgent(order) ? renter.service_urgency_charge_portion : 0
}

export function getRentUrgencyCharge(order,
                                     branch,
                                     renter: RenterWithDurationProperties & { rent_urgency_charge_portion: number }
) {
  return Math.round((
      getBaseRentPriceWithoutModifiers(order.line_items, branch) +
      getAdditionalRentDaysCharge(order, branch, renter)
    ) *
    getRentUrgencyChargePortion(order, renter)
  )
}

export function getRentPriceWithUrgencyAndDiscount(order, customer, branch, renter) {
  return Math.round(
    (
      getTotalRentPriceWithoutDiscountOrUrgencyCharge(order, branch, renter) +
      getRentUrgencyCharge(order, branch, renter)
    ) *
    (1 - getDiscountPortion(order, 'rent', customer, renter))
  )
}

export function getServiceDiscountPortion(order, customer, renter) {
  return order.id ?
    order.service_discount_portion :
    (_.isNumber(customer.special_service_discount) ? customer.special_service_discount : renter.service_discount)
}

export function getServicePriceWithUrgencyAndDiscount(order, customer, branch, renter, pifakit_service) {
  return Math.round(
    getServicePriceWithoutModifiers(order, branch, renter, pifakit_service, getServiceDiscountPortion(order, customer, renter)) *
    (1 + getServiceUrgencyChargePortion(order, renter)) *
    (1 - getDiscountPortion(order, 'service', customer, renter))
  )
}

export function getTotal(order, customer, branch, renter, pifakit_service) {
  return getRentPriceWithUrgencyAndDiscount(order, customer, branch, renter) +
    getAdHocLineItemsPrice(order.ad_hoc_line_items) +
    getServicePriceWithUrgencyAndDiscount(order, customer, branch, renter, pifakit_service)
}

// DELIVERY UI

export function areDeliveryDetailsCompleted(delivery, isRenterChargingForUnloading) {
  return isFinite(parseInt(delivery.acquisition_hour_start)) &&
    isFinite(parseInt(delivery.acquisition_hour_end)) &&
    isFinite(parseInt(delivery.returning_hour_start)) &&
    isFinite(parseInt(delivery.returning_hour_end)) &&
    delivery.address &&
    (_.isBoolean(delivery.is_to_hall) || isRenterChargingForUnloading === false) &&
    delivery.contact_name &&
    delivery.contact_phone
}

export function getDeliveryDetails(order, customer) {
  let result = _.clone(order.delivery)
  result.isExtratown = _.isUndefined(order.delivery.distance) ? undefined : order.delivery.distance > 0
  result.isElevatorPresent = _.isNull(order.delivery.manual_lifting_floors)
  result.distance = order.delivery.distance || 0
  if (customer && customer.id && !result.contact_name) {
    result.contact_name = customer.first_name + ' ' + customer.last_name
    result.contact_phone = customer.phone
  }

  result.is_to_hall = _.isUndefined(result.is_to_hall) ? false : result.is_to_hall
  result.manual_lifting_floors = _.isUndefined(result.manual_lifting_floors) ? null : result.manual_lifting_floors
  result.carrying_distance = _.isUndefined(result.carrying_distance) ? null : result.carrying_distance

  return result
}

// UI CALCULATIONS

export function getMaxPaymentFromSurplus(order, customer) {
  return Math.min(order.payment_remainder, customer.balance)
}

export function isEligibleForExport(order: { id: ?number, state: string }) {
  return order.id && (order.state !== 'cancelled_before_starting') && (order.state !== 'cancelled_over_delinquency')
}

// PAYMENTS

function canPayHalf(
  order: { acquisition: string, paid: number, total: number },
  balance: number,
  renter: { freeze_duration: number }
) {
  if ((order.total * 0.3) < (balance + order.paid)) {
    return false
  }

  const halfPaymentDueTime = dayjs(order.acquisition).subtract(renter.freeze_duration + 4, 'day')
  const halfPaymentNotDueYet = dayjs().isBefore(halfPaymentDueTime)
  const orderNotPayableWithSurplus = (order.total * 0.3) > (Math.min(0, balance) + order.paid)
  return halfPaymentNotDueYet && orderNotPayableWithSurplus
}

function getHalfPayment(total: number, paid: number, balance: number) {
  return Math.round(total * 0.5 - balance - paid)
}

function getFullPayment(total: number, paid: number, balance: number) {
  return total - balance - paid
}

type RenterWithPaymentMethods = {
  is_payment_via_robokassa_allowed: boolean,
  is_payment_via_sberbank_allowed: boolean,
  is_payment_via_wire_transfer_allowed: boolean,
  is_payment_via_cash_allowed: boolean,
}

function getAllowedPaymentMethods(
  renter: RenterWithPaymentMethods
): Array<PaymentMethodString> {
  return _.filter(_.values(PAYMENT_METHODS), (method) => renter[`is_payment_via_${method}_allowed`])
}

export function getPaymentOptions(
  order: { acquisition: string, total: number, paid: number },
  balance: number,
  renter: RenterWithPaymentMethods & { freeze_duration: number },
  rulesUrl: string
) {
  const allowedPaymentMethods = getAllowedPaymentMethods(renter)
  return {
    sumOptions: _.compact(
      [
        (
          canPayHalf(order, balance, renter) ?
            {
              value: getHalfPayment(order.total, order.paid, balance),
              label: 'Достаточно для бронирования заказа',
              supportedPaymentMethods: _.without(allowedPaymentMethods, 'cash')
            }
            :
            null
        ),
        {
          value: getFullPayment(order.total, order.paid, balance),
          label: 'Полная оплата'
        }
      ]
    ),
    paymentMethods: allowedPaymentMethods,
    rulesUrl: rulesUrl
  }
}

export function getArticlesWithMandatoryDelivery(articles) {
  return _.filter(articles, a => a.is_delivery_mandatory)
}

export function getArticlesWithMandatoryArrangement(articles) {
  return _.filter(articles, a => a.is_arrangement_mandatory)
}

function isDeliveryMandatory(articles) {
  return !!getArticlesWithMandatoryDelivery(articles).length
}

function isArrangementMandatory(articles) {
  return !!getArticlesWithMandatoryArrangement(articles).length
}

export function getFixedOrder(order: { line_items: Array<LineItem>, delivery: Object, arrangement: Object },
                              renter: { id: number, is_delivery_offered: boolean, is_arrangement_offered: boolean },
                              getArticle: (articleId: number, renterId: number) => Object
) {
  const articles = _.map(order.line_items, li => {
    return getArticle(li.article_id, renter.id)
  })

  let result = {}

  _.assign(
    result,
    order,
    {
      delivery: renter.is_delivery_offered ?
        (order.delivery || (isDeliveryMandatory(articles) ? {
          is_to_hall: isArrangementMandatory(articles)
        } : undefined))
        : null,
      arrangement: renter.is_arrangement_offered ?
        (order.arrangement || (isArrangementMandatory(articles) ? {} : undefined))
        : null
    }
  )

  return result
}

function getAllowedQuantity(
  li: LineItem,
  renterId: number,
  getAvailable: (articleId: number, renterId: number) => number
) {
  return Math.min(li.quantity, getAvailable(li.article_id, renterId))
}

export function fixQuantities(lineItems: Array<LineItem>,
                              renterId: number,
                              getAvailable: (articleId: number) => number
) {
  // _.each(lineItems, (li) => {
  //   li.quantity = getAllowedQuantity(li, renterId, getAvailable)
  // })
}

export function getNotEnoughErrors(lineItems: Array<LineItem>,
                                   renterId: number,
                                   getAvailable: (articleId: number) => number
) {
  return _.compact(
    _.map(
      lineItems,
      (li) => {
        const notEnough = Math.max(0, li.quantity - getAllowedQuantity(li, renterId, getAvailable))
        if (notEnough > 0) {
          return {article_id: li.article_id, quantity: notEnough}
        }
      }
    )
  )
}

export function getEmptyOrder(townId: ?number) {
  return {
    town_id: townId,
    line_items: []
  }
}

function isDateSelectableForOrder(date, order, branch, slotName) {
  const today = dayjs(new Date()).startOf('day')
  const earliestDate = order.id ? today.add(2, 'day') : today

  if (dayjs(date).isBefore(earliestDate)) {
    return false
  }

  if (order.delivery) {
    return true
  }

  if (order.id) {
    if (isCurrentPeriodEndpoint(date, order, slotName)) {
      return true
    }
  }

  return !isPlaydayInBranch(branch, date)
}

export function isCurrentPeriodEndpoint(date, order, slotName) {
  const determineForAcquisition = (!slotName || slotName === 'acquisition')
  const determineForReturning = (!slotName || slotName === 'returning')
  return (determineForAcquisition && (date.getTime() === new Date(order.acquisition).getTime())) ||
    (determineForReturning && (date.getTime() === new Date(order.returning).getTime()))
}

export function isDateDisabledForOrder(date, order, branch) {
  return !(isDateSelectableForOrder(date, order, branch, 'acquisition') ||
    isDateSelectableForOrder(date, order, branch, 'returning'))
}

export function findOrBuildLineItem(order, articleId) {
  if (!order.line_items) {
    Vue.set(order, 'line_items', [])
  }

  let li = _.find(order.line_items, li => li.article_id === articleId)

  if (li) {
    return li
  } else {
    return {
      article_id: articleId,
      quantity: 1
    }
  }
}

const NEW_ORDERS_LOCALSTORAGE_KEY = 'newOrders'

export function getRawLocalStorageOrders() {
  return Vue.$localStorage.get(NEW_ORDERS_LOCALSTORAGE_KEY) || {}
}

export function getAllLocalStorageOrders(
  townId: number,
  getRenter: (renterId: number) => { id: number, is_delivery_offered: boolean, is_arrangement_offered: boolean },
  getArticle: (articleId: number) => Object
) {
  return _.omitBy(
    _.mapValues(
      getRawLocalStorageOrders(),
      (order, renterId) => {
        const renter = getRenter(renterId)
        if (!renter) {
          return null
        }
        return getLocalStorageOrder(renter, townId, getArticle)
      }
    ),
    (order) => _.isNull(order) || (townId && order.town_id !== townId)
    // иногда townId бывает undefined. не стал разбираться, почему, пока будем проверять, что
    // он действительно задан, и только тогда исключать заказы
  )
}

export function fixOrder(order,
                         renter: { id: number, is_delivery_offered: boolean, is_arrangement_offered: boolean },
                         townId: ?number,
                         getArticle: (articleId: number) => Object
) {
  return getFixedOrder(
    _.merge(
      getEmptyOrder(townId),
      _.pick(
        order,
        [
          'acquisition',
          'returning',
          'line_items',
          'delivery',
          'arrangement',
          'town_id',
          'added_at',
          'restored_order_id',
          'restored_from_code'
        ]
      )
    ),
    renter,
    getArticle
  )
}

export function getLocalStorageOrder(renter: {
                                       id: number,
                                       is_delivery_offered: boolean,
                                       is_arrangement_offered: boolean
                                     },
                                     townId: ?number,
                                     getArticle: (articleId: number) => Object
) {
  if (!renter) {
    throw new Error()
  }

  const order = getRawLocalStorageOrders()[renter.id]

  try {
    return fixOrder(order, renter, townId, getArticle)
  } catch (e) {
    console.log(e)
    return getEmptyOrder(townId)
  }
}

export function setLocalStorageOrder(order: Object, renterId: number) {
  const orders = getRawLocalStorageOrders()
  orders[renterId] = order
  return Vue.$localStorage.set(
    NEW_ORDERS_LOCALSTORAGE_KEY,
    _.omitBy(orders, o => !o || _.isEmpty(o))
  )
}

export function clearAllLocalStorageOrders() {
  return Vue.$localStorage.set(NEW_ORDERS_LOCALSTORAGE_KEY, {})
}

export function containsArticle(order: Object, articleId: number) {
  return _.find(order.line_items, (li) => {
    return li.article_id === articleId
  })
}
