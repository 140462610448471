<template lang="pug">
  .content
    .page-heading
      .page-title.page-title_top
        | Условия работы
    agv-rules(:renter="renter" :branch="branch" :get-loss-prices-url="getLossPricesUrl")
</template>

<script>
  import AgvRules from "../../aggregator/components/agv-rules";

  export default {
    name: 'page-rsv-rules',
    components: {AgvRules},
    methods: {
      getLossPricesUrl() {
        return '/docs/loss_prices.pdf'
      }
    },
    computed: {
      branch() {
        return this.$store.getters.branch
      },
      renter() {
        return this.$store.state.renter
      }
    }
  }
</script>

<style scoped>
</style>
