<template lang="pug">
  .content
    order-main(v-if="order"
    :order='order'
    :renter='renter'
    :branch='branch'
    :customer='customer'
    :articles='articles'
    :categories='categories'
    :is-order-current="isOrderCurrent"
    :is-embedded="isEmbedded"
    :select-articles-in-dropdown="isEmbedded"
    :is-user-admin="isUserAdmin"
    :is-date-disabled-for-order="isDateDisabledForOrder"
    :get-town="getTown"
    :get-article="getArticle"
    :get-article-url="getArticleUrl"
    :get-orders-url="getOrdersUrl"
    :get-order-bill-url="getOrderBillUrl"
    :get-last-wire-transfer-bill-details-url="getLastWireTransferBillDetailsUrl"
    :get-customer-bills-url="getCustomerBillsUrl"
    :rules-url="rulesUrl"
    :export-order-url="exportUrl"
    add-article-url="/catalogue"
    @finishedEditing="onFinishedEditing"
    @cancelled="onCancelled"
    @confirmed="onConfirmed"
    @restored="onRestored"
    @placed="onPlaced"
    @saved="onSaved"
    @updateCustomerBalance="onUpdateCustomerBalance"
    @validationErrors="onValidationErrors")
</template>

<script>
  import {
    findOrder,
    isOrderCurrent,
    isDateDisabledForOrder,
    getArticle,
    getArticleUrl,
    getBranch,
    getTown,
    getExportUrl,
    getCurrentOrder,
    getOrdersUrl,
    getOrderBillUrl,
    getLastWireTransferBillDetailsUrl,
    getCustomerBillsUrl
  } from 'RenterSite/lib/order'
  import store from 'RenterSite/store/index'
  import OrderMain from 'Shared/components/order-main'

  import scrollToTopmostError from 'Shared/lib/scrollToTopmostError'
  import embedder from 'RenterSite/lib/embedder'
  import postToParent from 'RenterSite/lib/postToParent'
  import {getEmptyOrder, setLocalStorageOrder} from 'Shared/order'

  export default {
    name: 'page-rsv-order',
    components: {OrderMain},
    data() {
      return {
        order: undefined,
        isOrderCurrent,
        getArticle,
        getArticleUrl,
        getBranch,
        getTown,
        getOrdersUrl,
        getOrderBillUrl,
        getLastWireTransferBillDetailsUrl,
        getCustomerBillsUrl
      }
    },
    props: {
      order_id: String
    },
    computed: {
      rulesUrl() {
        return this.$store.getters.rulesUrl
      },
      customer() {
        return this.$store.state.customer.current
      },
      renter() {
        return this.$store.state.renter
      },
      branch() {
        return this.getBranch(this.order.town_id)
      },
      articles() {
        return this.$store.getters['articles/getAllByRenterId'](this.$store.state.renter.id)
      },
      categories() {
        return this.$store.state.categories
      },
      exportUrl() {
        return getExportUrl(this.order.code, undefined)
      },
      isEmbedded() {
        return embedder.isEmbedded
      },
      isUserAdmin() {
        return embedder.isAdmin
      },
      townId() {
        return this.$store.state.town.current.id
      },
      currentOrder() {
        return getCurrentOrder()
      }
    },
    methods: {
      isDateDisabledForOrder(date, order) {
        return isDateDisabledForOrder(date, order)
      },
      fetchOrder() {
        const isFirstTime = !this.order

        if (parseInt(this.order_id) === _.get(this.currentOrder, 'id')) {
          this.order = this.currentOrder
          return
        }

        if (this.order_id === 'new') {
          if (this.isUserAdmin && isFirstTime) {
            this.$store.commit(
              'order/clearCurrent',
              {
                townId: this.isUserAdmin ? this.customer.town_id : this.townId,
                renterId: this.renter.id
              }
            )
          } else {
            this.$store.commit('order/setCurrentFromLocalStorage', {townId: this.townId})
            if (!this.currentOrder) {
              this.$store.commit(
                'order/clearCurrent',
                {
                  townId: this.isUserAdmin ? this.customer.town_id : this.townId,
                  renterId: this.renter.id
                }
              )
            }
          }

          this.order = this.currentOrder
          return
        }

        findOrder(this.order_id).then(({data: order}) => {
          this.order = order
        })
      },
      onPlaced(id) {
        this.$router.push(`/order/${id}`)
        postToParent({placedOrder: {id: id, customer_id: this.customer.id}})
      },
      onSaved() {
        postToParent({event: 'orderChanged'})
      },
      onFinishedEditing() {
      },
      onCancelled(order) {
        this.order = _.extend({}, this.order, order)
      },
      onConfirmed(order) {
        this.order = _.extend({}, this.order, order)
      },
      onRestored(order) {
        this.$store.commit('order/saveOrderToLocalStorage', {order, renterId: this.renter.id})
        this.$router.push('/order/new')
        postToParent({restoredOrder: true})
      },
      onUpdateCustomerBalance() {
        this.$store.dispatch('customer/load').then((customer) => {
          postToParent({customerBalance: customer.balance})
        })
      },
      onValidationErrors(errors) {
        _.defer(scrollToTopmostError)
      }
    },
    beforeRouteEnter(to, fr, next) {
      if (store.state.customer.current.id) {
        next()
      } else {
        store.dispatch('customer/load').then(() => {
            next()
          },
          () => {
            next()
          }
        )
      }
    },
    watch: {
      order_id: [
        {
          handler: 'fetchOrder',
          immediate: true
        }
      ],
    },
    created() {
      this.$store.dispatch(
        'availability/loadAvailable',
        {reason: 'navigate', renterId: this.renter.id}
      ).then(() => {
        store.commit('order/fixQuantities', {renterId: this.renter.id, writeErrors: true})
      })
    }
  }
</script>

<style scoped>
  .content {
    min-height: 400px
  }

  .content-inner {
    height: 100%;
  }
</style>
