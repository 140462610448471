<template lang="pug">
  .photo-selector(:class="{'partitioned-selector': isSelectorPartitioned}")
    .photo-thumbnail-container(v-for="photo in photos" :key="photo.id" :class="`photo-thumbnail-container-${photo.type}`")
      .photo-thumbnail(@click='internalValue = photo' :class="{'photo-thumbnail-active': internalValue === photo}")
        .photo-thumbnail-img-wrapper
          i.el-icon-picture(v-if="photo.is_infringing")
          img(v-else :src="photo.thumbnailUrl")
</template>

<script>
  import _ from 'lodash'

  export default {
    created() {
      this.updateInternalValue()
    },
    data() {
      return {
        internalValue: this.value
      }
    },
    computed: {
      isSelectorPartitioned() {
        return _.uniq(_.map(this.photos, 'type')).length > 1
      }
    },
    watch: {
      'value': 'updateInternalValue',
      'internalValue': 'emitValue'
    },
    methods: {
      updateInternalValue() {
        this.internalValue = this.value
      },
      emitValue() {
        this.$emit('input', this.internalValue)
      }
    },
    name: 'agv-photo-selector',
    props: {
      value: Object,
      photos: Array
    }
  }
</script>

<style scoped>
  .photo-selector {
    display: flex;
    flex-direction: column;
    width: 60px;
  }

  .photo-thumbnail {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid white;
    transition: 0.1s;
  }

  .photo-thumbnail:hover {
    border-color: #e5e5e5;
  }

  .photo-thumbnail-active, .photo-thumbnail-active:hover {
    border-color: #f6a623;
  }

  .photo-thumbnail-img-wrapper {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photo-thumbnail-img-wrapper img {
    max-height: 100%;
    max-width: 100%;
  }

  .active-photo-wrapper {
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    max-width: 500px;
  }

  .active-photo-wrapper img {
    max-height: 100%;
    max-width: 100%;
    cursor: zoom-in;
  }

  .active-photo {
    max-height: 500px;
    max-width: 500px;
  }

  .partitioned-selector .photo-thumbnail-container-article:first-of-type:before {
    color: grey;
    content: 'Изделие';
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: center;
  }

  .partitioned-selector .photo-thumbnail-container-product:before {
    color: grey;
    content: 'В комплекте';
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: center;
    left: -20px;
    position: relative;
    width: 100px;
  }

  .partitioned-selector .photo-thumbnail-container-product ~ .photo-thumbnail-container-product:before {
    display: none;
  }

  .partitioned-selector .photo-thumbnail-container-warehouse:before {
    color: grey;
    content: 'Прокатчик';
    display: block;
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 12px;
    text-align: center;
  }
</style>
