import _ from 'lodash';
import {
  getArrangementWorkerCount,
  getSetupAmount,
  getTeardownAmount,
  getVolume
} from "Shared/order";

const calculateDeliveryComponentPrice = (value, rates) => {
  let price = 0;
  while (value > 0) {
    const rate = rates.slice().find(r => value <= r[0]) || rates[rates.length - 1];
    price += rate[1];

    if (value - rate[0] <= 0) {
      break;
    }

    value -= rate[0];
  }

  return price;
};

const calculateArrangementComponentPrice = (line_items, pifakitService, workType, workers_manhours_relations) => {
  const manhours = workType === 'setup' ? getSetupAmount(line_items) : getTeardownAmount(line_items);
  const workersCount = Math.max(
    Math.round(getArrangementWorkerCount(manhours, workers_manhours_relations)),
    _.max(_.map(line_items, li => li.article.worker_count))
  );
  const duration = manhours / workersCount;

  const teamLeadWorkPrice = Math.max(duration * pifakitService.setup_price_per_hour, pifakitService.setup_min_team_lead_price);
  const assistantsWorkPrice = Math.max(duration * pifakitService.setup_price_per_hour, pifakitService.setup_min_assistant_price) * (workersCount - 1);

  return teamLeadWorkPrice + assistantsWorkPrice;
};

const deliveryCostForRenter = (order, pifakitService) => {
  const someArticlesRequireVanOrTruck = _.some(order.line_items, li => li.article.vehicle === 'van' || li.article.vehicle === 'truck');
  const minimumVolume = someArticlesRequireVanOrTruck ? 2 : 0;
  const volume = Math.max(minimumVolume, getVolume(order.line_items));

  const deliveryBase = calculateDeliveryComponentPrice(volume, pifakitService.intratown_delivery_by_volume) +
    calculateDeliveryComponentPrice(volume, pifakitService.extratown_delivery_per_km_by_volume) * (order.delivery.distance || 0);

  const pricePreDiscount = deliveryBase / (1 - pifakitService.exact_time_surcharge);

  return order.delivery.acquisition_hour_start === order.delivery.acquisition_hour_end
    ? pricePreDiscount
    : pricePreDiscount * (1 - pifakitService.exact_time_surcharge);
};

const arrangementCostForRenter = (line_items, pifakitService, workers_manhours_relations) => {
  return calculateArrangementComponentPrice(line_items, pifakitService, 'setup', workers_manhours_relations) +
    calculateArrangementComponentPrice(line_items, pifakitService, 'teardown', workers_manhours_relations);
};

const priceFromCost = (cost, service_discount, renter_profitability) => {
  return Math.round(cost / (1 - service_discount) / (1 - renter_profitability));
}

export const deliveryPrice = (order, pifakitService, service_discount) => {
  if (_.isNil(order.delivery)) {
    return 0;
  }

  return priceFromCost(
    deliveryCostForRenter(order, pifakitService),
    service_discount,
    pifakitService.renter_profitability
  );
};

export const arrangementPrice = (line_items, pifakitService, workers_manhours_relations, service_discount) => {
  return priceFromCost(
    arrangementCostForRenter(line_items, pifakitService, workers_manhours_relations),
    service_discount,
    pifakitService.renter_profitability
  );
};
