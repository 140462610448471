<template lang="pug">
  el-dialog(width="420px" :visible="true" @close="$emit('close')")
    p(v-if="order.is_urgent")
      | Прежде, чем принять срочный заказ, мы проверяем, успеет ли склад его собрать.
    p
      | Наш оператор свяжется с вами в ближайшее время, чтобы подтвердить {{order.is_just_placed ? 'заказ' : 'изменение заказа'}}.
      br
      | Если хотите ускорить подтверждение, позвоните нам по телефону:
      br
      | {{branch.customer_service_phone | phoneNumber(renter.country)}}.
</template>

<script>
  export default {
    name: "order-confirmation-modal",
    props: {
      order: Object,
      branch: Object,
      renter: Object
    }
  }
</script>

<style scoped>

</style>
