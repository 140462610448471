import { render, staticRenderFns } from "./rsv-login.vue?vue&type=template&id=0b9c6573&scoped=true&lang=pug&"
import script from "./rsv-login.vue?vue&type=script&lang=js&"
export * from "./rsv-login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9c6573",
  null
  
)

export default component.exports