// @flow

import axios from 'axios'
import _ from 'lodash'

function getKey(renterId, articleId) {
  return renterId + ':' + articleId
}

export default function createStore(
  getRenterId: (article: Object) => number,
  transformArticles: (articles: Array<Object>) => void
) {
  return {
    namespaced: true,
    state: {
      articlesByKey: {},
      allByRenterId: {}
    },
    actions: {
      load({dispatch, rootState, getters}, path: string): Promise<Array<Object> | Error> {
        return new Promise((resolve: Function, reject: Function) => {
          axios.get(path)
            .then(
              ({data: articles}) => {
                dispatch('add', articles)
                if (getters['order/acquisition'] && articles.length) {
                  dispatch('availability/loadAvailable')
                }
                resolve(articles)
              }, error => {
                reject(error)
              }
            )
        })
      },
      add({commit, rootState}, articles) {
        transformArticles(articles, rootState)
        commit('add', articles)
      }
    },
    mutations: {
      add(state, articles) {
        const allRenterIds = _.map(articles, a => getRenterId(a))
        _.each(allRenterIds, id => {
          state.allByRenterId[id] = []
        })
        _.each(articles, a => {
            const renterId = getRenterId(a)
            const key = getKey(renterId, a.id)
            state.allByRenterId[renterId].push(a)
            state.articlesByKey[key] = a
          }
        )
      }
    },
    getters: {
      getByRenterIdAndArticleId: (state) => (renterId, articleId) => {
        return state.articlesByKey[getKey(renterId, articleId)]
      },
      getAllByRenterId: (state) => (renterId) => {
        return state.allByRenterId[renterId]
      }
    }
  }
}
