// @flow

import getActions from './actions'
import getMutations from './mutations'
import getGetters from './getters'

export default function createStore(
  townId: Number,
  getRenter: (renterId: number) => Object,
  getArticle: (articleId: number) => Object,
  getOrdersUrl: () => string,
  getAvailableForPeriod: (article: {id: ?number, article_id: ?number}) => number,
  getStateUrl: (renterId: number) => string,
  removeOrderWhenClearing
) {
  return {
    namespaced: true,
    state() {
      return {
        ordersByRenterId: {},
        backupsByRenterId: null
      }
    },
    getters: getGetters(),
    actions: getActions(getArticle, getOrdersUrl, getStateUrl),
    mutations: getMutations(getRenter, getArticle, getAvailableForPeriod, removeOrderWhenClearing)
  }
}
