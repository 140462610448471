// @flow

import axios from 'axios'
import eventBus from 'Shared/eventBus'

export default function createStore(
  getUrl: (number) => string
) {
  return {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
      retrieveSurplus(store, {renterId}) {
        axios.post(getUrl(renterId) + '/retrieve_surplus').then(()=>{
          store.dispatch('customer/load', null, {root: true})
          eventBus.$emit('renterCustomer:retrieveSurplus')
        })
      }
    }
  }
}
