<template lang="pug">
  .sidebar.noselect
    .sidebar-title
      | {{customer.first_name}}
      | {{customer.last_name}}
    .customer-contacts
      | Почта: {{customer.login}}
      br
      | Телефон: {{customer.phone | phoneNumber(countryCode)}}
    .customer-links
      router-link.customer-link(to="/customer/orders")
        | Мои заказы
      router-link.customer-link(to="/customer/transactions" v-if="!renters")
        | Мои денежные операции
      router-link.customer-link(to="/customer/details")
        | Мои данные
    .transactions-links(v-if="renters && renters.length")
      .transactions-links-heading
        | Денежные операции
      .transactions-link-container(v-for="renter in renters" :key="renter.id")
        router-link.customer-link(:to="`/customer/transactions/${renter.id}`")
          | {{renter.short_commercial_name}}
        .balance(v-if="getBalanceFor(renter.id)")
          | {{getBalanceFor(renter.id) | money('ru')}}
    .logout-btn(@click="logout")
      | Выйти из аккаунта
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'agv-customer-sidebar',
    props: {
      customer: Object,
      getBalanceFor: Function
    },
    methods: {
      logout() {
        this.$store.dispatch('customer/logout').then(() => {
          location.href = '/'
        })
      }
    },
    computed: {
      renters() {
        if (this.$store.state.customer.current.customers_by_renter_id) {
          return _.compact(
              _.map(
            _.keys(this.$store.state.customer.current.customers_by_renter_id),
            (renterId) => {
              return this.$store.state.rentersById[renterId]
            }
          )
          )
        }
      },
      countryCode() {
        return _.get(this.$store.state.renter, 'country', 'ru')
      }
    }
  }
</script>

<style scoped>
  .sidebar {
    min-width: 197px;
    max-width: 220px;
  }

  .sidebar-title {
    font-weight: bold;
    font-size: 17px;
    color: #000;
    line-height: 23px;
  }

  .customer-contacts {
    font-size: 14px;
    line-height: 30px;
    margin-top: 12px;
  }

  .customer-links {
    margin-top: 10px;
  }

  .customer-link {
    display: block;
    line-height: 30px;
    font-size: 14px;
    text-decoration: underline;
  }

  .customer-link.router-link-active {
    text-decoration: none;
  }

  .logout-btn {
    /* Выйти */
    font-size: 14px;
    color: #9b9b9b;
    margin-top: 31px;
    cursor: pointer;
  }

  .transactions-links {
    margin-top: 20px;
  }

  .transactions-links-heading {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .transactions-link-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .balance {
    font-size: 13px;
  }
</style>
