<template lang="pug">
  .order-prices
    .row(v-if='showCalculations' style="padding: 10px 0 !important;")
      .prices
        .price__item
          .price__description
            | Стоимость аренды
            span(v-if="showSmallestRentPriceCaveat")
              |
              | (с учетом минимального заказа)
          .price__number.cc-order-rent-price(:class="{price__number_subtotal: !anyRentPriceModifiers}")
            | {{actualOrSmallestRentPriceWithoutModifiers | money(renter.country)}}
        .price__item(v-if='additional')
          .price__description.cc-order-additional-days-description
            | Доплата за дополнительные дни аренды
          .price__number.cc-order-additional-days-price
            | + {{additional | money(renter.country)}}
        .price__item(v-if='rentUrgencyCharge')
          .price__description.cc-order-rent-urgency-charge-description
            | Доплата за срочную сборку заказа ({{rentUrgencyChargePortion * 100 | number(0)}}%)
          .price__number.cc-order-rent-urgency-charge-price
            | + {{rentUrgencyCharge | money(renter.country)}}
        .price__item(v-if="rentDiscount")
          .price__description.cc-order-rent-discount-description
            | Профскидка на аренду ({{rentDiscountPortion * 100 | number(0)}}%)
          .price__number.cc-order-rent-discount
            | {{-rentDiscount | money(renter.country)}}
        .subtotal-price__item(v-if="anyRentPriceModifiers && order.delivery")
          .price__number.price__number_subtotal.cc-order-rent-subtotal
            | {{rentSubtotal | money(renter.country)}}
      .prices.cc-order-service-prices(v-if="order.delivery")
        .price__item
          .price__description
            | Стоимость сервиса
          .price__number.cc-order-service-price(:class="{price__number_subtotal: !anyServicePriceModifiers}")
            | {{servicePriceWithoutModifiers | money(renter.country)}}
        .price__item(v-if='serviceUrgencyCharge')
          .price__description.cc-order-service-urgency-charge-description
            | Доплата за срочную организацию доставки ({{serviceUrgencyChargePortion * 100 | number(0)}}%)
          .price__number.cc-order-service-urgency-charge-price
            | + {{serviceUrgencyCharge | money(renter.country)}}
        .price__item(v-if="serviceDiscount")
          .price__description.cc-order-service-discount-description
            | Профскидка на сервис ({{serviceDiscountPortion * 100 | number(0)}}%)
          .price__number.cc-order-service-discount
            | {{-serviceDiscount | money(renter.country)}}
        .subtotal-price__item(v-if="anyServicePriceModifiers")
          .price__number.price__number_subtotal.cc-order-service-subtotal
            | {{serviceSubtotal | money(renter.country)}}
      .prices(v-if="order.ad_hoc_line_items && adHocLineItemsSubtotal")
        .price__item
          .price__description
            | Дополнительные позиции
          .price__number.price__number_subtotal.cc-ad-hoc-line-items-subtotal
            | {{adHocLineItemsSubtotal | money(renter.country)}}
    .row
      .price__item.total-price__item
        .price__description
          | Стоимость заказа
          span(v-if="showSmallestRentPriceCaveat && !showCalculations")
            |
            | (с учетом минимального заказа)
        .price__number.cc-order-total-price(:class="{'price-with-discount': !!(rentDiscount || serviceDiscount)}")
          | {{total | money(renter.country)}}
    .row(v-if="showDepositRequirement")
      .price__item.deposit__item(style="display: flex; justify-content: flex-end")
        .price__description
          | Залог
        .price__number.cc-deposit-requirement
          | Рассчитает менеджер
</template>

<script>
import {
  getRentPeriodLength,
  getAdditionalRentDaysCharge,
  getRentUrgencyCharge,
  getDiscountPortion,
  getTotal,
  getBaseRentPriceWithoutModifiers,
  getActualOrSmallestRentPriceWithoutModifiers,
  getRentPriceWithUrgencyAndDiscount,
  getServicePriceWithUrgencyAndDiscount,
  getAdHocLineItemsPrice,
  getServiceUrgencyChargePortion,
  getRentUrgencyChargePortion,
  getTotalRentPriceWithoutDiscountOrUrgencyCharge,
  getServicePriceWithoutModifiers, getServiceDiscountPortion
} from 'Shared/order'

  export default {
    name: 'order-prices',
    props: {
      order: Object,
      customer: Object,
      renter: Object,
      branch: Object,
      getArticle: Function,
      pifakitService: Object
    },
    computed: {
      showDepositRequirement() {
        if (this.renter.deposit_requirement === 2) {
          return true
        }
        if (this.renter.deposit_requirement === 0) {
          return false
        }
        return !this.customer.is_professional && this.customer.id
      },
      rentPeriodLength() {
        return getRentPeriodLength(this.order.acquisition, this.order.returning)
      },
      additional() {
        return getAdditionalRentDaysCharge(this.order, this.branch, this.renter)
      },
      servicePriceWithoutModifiers() {
        return getServicePriceWithoutModifiers(this.order, this.branch, this.renter, this.pifakitService, getServiceDiscountPortion(this.order, this.customer, this.renter))
      },
      rentUrgencyChargePortion() {
        return getRentUrgencyChargePortion(this.order, this.renter)
      },
      rentUrgencyCharge() {
        return Math.round(getRentUrgencyCharge(this.order, this.branch, this.renter))
      },
      serviceUrgencyChargePortion() {
        return getServiceUrgencyChargePortion(this.order, this.renter)
      },
      serviceUrgencyCharge() {
        return Math.round(this.servicePriceWithoutModifiers * this.serviceUrgencyChargePortion)
      },
      rentDiscountPortion() {
        return getDiscountPortion(this.order, 'rent', this.customer, this.renter)
      },
      serviceDiscountPortion() {
        return getDiscountPortion(this.order, 'service', this.customer, this.renter)
      },
      rentDiscount() {
        return Math.round(
          (
            getTotalRentPriceWithoutDiscountOrUrgencyCharge(this.order, this.branch, this.renter) +
            getRentUrgencyCharge(this.order, this.branch, this.renter)
          ) *
          getDiscountPortion(this.order, 'rent', this.customer, this.renter)
        )
      },
      serviceDiscount() {
        return Math.round(
          this.servicePriceWithoutModifiers *
          (1 + this.serviceUrgencyChargePortion) *
          getDiscountPortion(this.order, 'service', this.customer, this.renter))
      },
      showSmallestRentPriceCaveat() {
        return this.actualOrSmallestRentPriceWithoutModifiers >
          getBaseRentPriceWithoutModifiers(this.order.line_items, this.branch)
      },
      showCalculations () {
        return this.additional ||
          this.rentUrgencyCharge ||
          this.serviceUrgencyCharge ||
          this.rentDiscount ||
          this.serviceDiscount
      },
      actualOrSmallestRentPriceWithoutModifiers() {
        return getActualOrSmallestRentPriceWithoutModifiers(
          this.order.line_items,
          !!this.order.delivery,
          this.branch,
          this.renter
        )
      },
      anyRentPriceModifiers() {
        return !!(this.additional || this.rentUrgencyCharge || this.rentDiscount)
      },
      anyServicePriceModifiers() {
        return !!(this.serviceUrgencyCharge || this.serviceDiscount)
      },
      rentSubtotal() {
        return getRentPriceWithUrgencyAndDiscount(this.order, this.customer, this.branch, this.renter)
      },
      serviceSubtotal() {
        return getServicePriceWithUrgencyAndDiscount(this.order, this.customer, this.branch, this.renter, this.pifakitService)
      },
      adHocLineItemsSubtotal() {
        return getAdHocLineItemsPrice(this.order.ad_hoc_line_items)
      },
      total() {
        return getTotal(this.order, this.customer, this.branch, this.renter, this.pifakitService)
      }
    }
  }
</script>

<style scoped>
  .prices {
    padding: 30px 0;
  }

  .prices + .prices {
    border-top: 1px solid #eee;
  }

  .price__item {
    text-align: right;
  }

  .price__item + .price__item {
    margin-top: 20px;
  }

  .price__description {
    display: inline-block;
  }

  .price__number {
    display: inline-block;
    width: 130px;
    margin-left: 40px;
    font-feature-settings: 'tnum' 1;
  }

  .subtotal-price__item {
    text-align: right;
  }

  .price__number_subtotal {
    font-size: 24px;
  }

  .subtotal-price__item .price__number.price__number_subtotal {
    border-top: 1px solid #e6e6e6;
    margin-top: 25px;
    padding-top: 25px;
  }

  .total-price__item .price__number {
    font-size: 24px;
    font-weight: bold;
  }

  .total-price__item .price__number.price-with-discount {
    color: #2ea50d;
  }
</style>
