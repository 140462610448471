<template lang="pug">
  li.agv-tag-li-checkbox(:class="{'tag-inactive': !active}")
    div(v-if='checked')
      checkbox-filled.checkbox
    div(v-else)
      checkbox-empty.checkbox(v-if="active")
      checkbox-empty-inactive.checkbox(v-else)
    | {{name | capitalize}}
</template>

<script>

  import CheckboxEmpty from 'Aggregator/sprites/checkbox-empty.svg'
  import CheckboxEmptyInactive from 'Aggregator/sprites/checkbox-empty-inactive.svg'
  import CheckboxFilled from 'Aggregator/sprites/checkbox-filled.svg'

  export default {
    name: "agv-tag-li-checkbox",
    props: {
      name: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        required: true
      },
      checked: {
        type: Boolean,
        required: true
      }
    },
    components: {CheckboxEmpty, CheckboxEmptyInactive, CheckboxFilled}
  }
</script>

<style scoped>
  .agv-tag-li-checkbox {
    position: relative;
    font-size: 13px;
    color: #000000;
    line-height: 19px;

    display: flex;

    cursor: pointer;

    margin-bottom: 12px;
  }

  .checkbox {
    margin-right: 7px;
  }

  .tag-inactive {
    opacity: 0.4;
    pointer-events: none;
  }
</style>
