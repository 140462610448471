<template lang="pug">
  .agv-payment-form(:class="{'agv-payment-form_no-sum-options': !showSum}")
    .block.sum-block(v-if="showSum")
      .payment-sum-option(v-for="option in filteredSumOptions")
        el-radio(v-model="sum" :label="option.value" border)
          | {{option.value | money(renter.country)}}
        .payment-sum__label
          | {{option.label}}
    hr(v-if="showSum")
    .block
      el-radio-group(v-model="paymentMethod")
        el-radio-button(v-for="method in paymentMethods" :label="method" border size="small" :key="method")
          | {{labelForPaymentMethod(method)}}
          .payment-method-icon(:class="`payment-method-icon_${method}`")
          .payment-method-warning(v-if="warningForPaymentMethod(method)")
            | {{warningForPaymentMethod(method)}}
    .block(v-if="paymentMethod === 'wire_transfer'" style="padding-bottom: 0;")
      h4(style="margin-bottom: 20px; font-weight: normal;")
        | Ваши реквизиты
      form-agv-bank-details(:details='bankDetails')
    .block(v-if="rulesUrl")
      el-checkbox(v-model="areRulesAccepted" :class="{'el-checkbox_error': errors.rules}")
        | Я согласен с
        |
        a(:href="rulesUrl" target="_blank")
          | правилами
    .block
      el-button(type="success" @click="onSubmitClicked" :disabled="!isComplete")
        span(v-if="paymentMethod === 'wire_transfer'")
          | Выставить счет
          span(v-if="!allDetailsFilled")
            |
            | без моих реквизитов
        span(v-else-if="paymentMethod === 'cash'")
          | Подтвердить
        span(v-else)
          | Внести оплату
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  import {TITLES, SUBTITLES} from 'Shared/paymentMethods'
  import FormAgvBankDetails from 'Aggregator/pages/form-agv-bank-details'

  export default {
    name: 'agv-payment-form',
    components: {FormAgvBankDetails},
    props: {
      sumOptions: Array,
      showSum: Boolean,
      paymentMethods: Array,
      rulesUrl: String,
      bankDetails: Object,
      renter: Object
    },
    data() {
      return {
        sum: null,
        paymentMethod: null,
        areRulesAccepted: true,
        errors: {}
      }
    },
    computed: {
      allDetailsFilled() {
        return !!(this.bankDetails.legal_name && this.bankDetails.address && this.bankDetails.tin)
      },
      isComplete() {
        return this.sum && this.paymentMethod && (!this.rulesUrl || this.areRulesAccepted)
      },
      filteredSumOptions() {
        return _.filter(
          this.sumOptions,
          o => !this.paymentMethod || !o.supportedPaymentMethods || _.includes(o.supportedPaymentMethods, this.paymentMethod))
      }
    },
    methods: {
      labelForPaymentMethod(method) {
        return TITLES[method]
      },
      warningForPaymentMethod(method) {
        return SUBTITLES[method]
      },
      onSubmitClicked() {
        if (!this.areRulesAccepted) {
          Vue.set(this.errors, 'rules', true)
          return
        }

        this.$emit('proceed', {
          sum: this.sum,
          paymentMethod: this.paymentMethod
        })
      },
      onAreRulesAcceptedChanged() {
        if (this.areRulesAccepted) {
          Vue.set(this.errors, 'rules', false)
        }
      },
      onPaymentMethodChanged() {
        if (!_.find(this.filteredSumOptions, so => so.value === this.sum)) {
          this.sum = _.first(this.filteredSumOptions).value
        }
      }
    },
    watch: {
      areRulesAccepted: 'onAreRulesAcceptedChanged',
      paymentMethod: 'onPaymentMethodChanged'
    },
    created() {
      this.sum = this.sumOptions[0].value
    }
  }
</script>

<style scoped>
  .agv-payment-form >>> .el-radio__inner {
    display: none;
  }

  .agv-payment-form >>> .el-checkbox__input.is-checked+.el-checkbox__label {
    color: inherit;
  }

  .block {
    padding: 20px 0;
  }

  .block:last-child {
    padding-bottom: 0;
  }

  .agv-payment-form_no-sum-options .block  {
    padding-top: 0;
  }

  .agv-payment-form_no-sum-options .payment-method-block  {
    align-items: flex-start;
  }

  .agv-payment-form_no-sum-options .payment-method-option  {
    width: 100%;
  }

  .agv-payment-form_no-sum-options .el-radio  {
    width: 100%;
  }

  .sum-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .sum-block .el-radio {
    text-align: center;
    margin-right: 0;
  }

  .payment-method-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment-sum-option {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    margin-right: 20px;
    width: 120px;
  }

  .payment-sum-option:last-child {
    margin-right: 0;
  }

  .payment-sum__label {
    color: grey;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }

  hr {
    border: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }

  .payment-method-option + .payment-method-option {
    margin-top: 20px;
  }

  .agv-payment-form >>> .el-radio-group {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .agv-payment-form >>> .el-radio-button__inner {
    transition: none;
    -webkit-transition: none;

    height: 100%;
    white-space: normal;
    vertical-align: middle;
    padding: 12px 18px;
    line-height: 1.4em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .agv-payment-form >>> .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: white;
    color: #409EFF;
  }

  .payment-method-icon {
    background-size: contain;
    display: inline-block;
    background-position: 0 0;
    background-repeat: no-repeat;
  }

  .payment-method-icon.payment-method-icon_robokassa {
    margin-top: 3px;
    height: 14px;
    width: 65px;
    background-image: url("/public/img/aggregator/payment-systems-new.png");

  }

  .payment-method-icon.payment-method-icon_modulbank {
    margin-top: 3px;
    height: 14px;
    width: 65px;
    background-image: url("/public/img/aggregator/payment-systems-new.png");
  }

  .payment-method-warning {
    font-size: 11px;
    line-height: 14px;
    color: grey;
  }
</style>
