import _ from 'lodash'

function getAvailableForPeriod(articleId, renterId, store) {
  return _.get(
    store.getters['availability/getByRenterId'](renterId),
    articleId
  )
}

export function getAvailableForPeriodOrTotal(article, renterId, store) {
  // FIXME
  // доступность берется по id изделия, если это изделие, или по article_id, если это след
  // это очень некрасиво
  const forPeriod = getAvailableForPeriod(article.id || article.article_id, renterId, store)
  if (_.isUndefined(forPeriod)) {
    return article.total_available
  }
  return forPeriod
}
