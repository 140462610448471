<template lang="pug">
  form-agv-login(:default-town='town'
    @login="onTryLogin"
    @requestOneTimeAccess="onRequestOneTimeAccess"
    @clickedSignup="onClickedSignup"
    @clearError="onClearLoginError"
    :errors="errors"
  )
</template>

<script>
import axios from 'axios'
import FormAgvLogin from "../../aggregator/pages/form-agv-login";
import { Message } from 'element-ui';

export default {
  name: "rsv-login",
  components: {FormAgvLogin},
  props: {
    town: Object,
  },
  data() {
    return {
      errors: {}
    }
  },
  methods: {
    onClickedSignup() {
      this.$emit('close')
      this.$router.push('/signup')
    },
    onTryLogin(loginCredentials) {
      axios.post(
          '/customers/sign_in.json',
          {customer: loginCredentials}
      ).then(() => {
        this.$store.dispatch('customer/load').then(() => {
          this.$emit('loggedIn')
        })
      }).catch((error) => {
        this.errors = error.response.data
      })
    },
    onRequestOneTimeAccess(loginCredentials) {
      axios.post(
          '/api/customer/request_one_time_access',
          {customer: loginCredentials}
      ).then((response) => {
        this.$emit('close')
        this.$emit('openOneTimeAccess')
        const h = this.$createElement;
        Message({
          message: h('p', null, [
            h('span', null, 'Сейчас на вашу почту придет ссылка на восстановление доступа'),
          ]),
          type: 'success'
        });
      }).catch((error) => {
        this.errors = error.response.data
      })
    },
    onClearLoginError() {
      this.errors = {}
    },
  }
}
</script>

<style scoped>

</style>