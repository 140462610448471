<template lang="pug">
  .root(v-infinite-scroll="loadMore" infinite-scroll-distance="1000" v-hotkey="keymap")
    transition(name="fade")
      .error-report(v-if="showErrorReport")
        | Вы отправили отчет об ошибке. Спасибо!
    vue-progress-bar
    order-item-added-notification(v-if="canShowItemAddedNotification" :quantity="lineItemsQuantity" :get-orders-url="getOrdersRouteUrl")
    rsv-modals
    rsv-header(:line-items-quantity="lineItemsQuantity" :order="order" v-if="showHeader")
    .wrapper
      router-view(:branch="$store.getters.branch" :limit="limit")
      a.pifakit-link(href="https://pifakit.ru" target="_blank")
        | © Пифакит — платформа аренды декора
    back-to-top(text="Наверх" visibleoffset="750")
</template>

<script>
  import * as Sentry from '@sentry/browser'

  import 'element-ui/lib/theme-chalk/index.css'

  import newSiteRouter from 'RenterSite/newSiteRouter'
  import store from 'RenterSite/store/index'

  import TruncatedTitle from 'RenterSite/shared/TruncatedTitle'

  import AgvPaymentForm from 'Aggregator/components/agv-payment-form'
  import RsvHeader from 'RenterSite/rsv-header'

  import RsvModals from 'RenterSite/components/rsv-modals'
  import OrderItemAddedNotification from 'Shared/components/order-item-added-notification'
  import embedder from 'RenterSite/lib/embedder'
  import {getCurrentOrder, getLocalStorageOrder, getOrdersRouteUrl} from 'RenterSite/lib/order'

  store.commit('order/setCurrentFromLocalStorage', {townId: store.state.town.current.id})

  if (!store.state.$isServer) {
    if (window.as_customer_id) {
      embedder.adminCustomerId = window.as_customer_id
    }
    store.dispatch('customer/load')
  }

  export default {
    name: 'renter_site',
    router: newSiteRouter,
    data() {
      return {
        getOrdersRouteUrl,
        limit: store.state.$isServer ? 99999 : 21,
        showErrorReport: false,
        keymap: {
          'alt+g': () => {
            Sentry.captureMessage('User submitted error')
            this.showErrorReport = true
            setTimeout(() => {
              this.showErrorReport = false
            }, 2000)
          }
        },
      }
    },
    components: {OrderItemAddedNotification, RsvModals, RsvHeader, AgvPaymentForm},
    computed: {
      metaDescription() {
        return `${store.state.renter.short_commercial_name_cases[0]} предлагает в аренду ${store.state.category_names}`
      },
      lineItemsQuantity() {
        return store.getters['order/lineItemsQuantity']
      },
      order() {
        return getCurrentOrder()
      },
      showHeader() {
        return !embedder.isEmbedded
      },
      canShowItemAddedNotification() {
        return !embedder.isEmbedded
      },
    },
    metaInfo() {
      return {
        title: new TruncatedTitle(`Аренда декора: ${store.state.category_names}`).toString(),
        titleTemplate: `%s | ${store.state.renter.short_commercial_name_cases[0]}`,
        meta: [{vmid: 'description', name: 'description', content: this.metaDescription}]
      }
    },
    methods: {
      loadMore() {
        this.limit += 21
      }
    },
    watch: {
      $route(toRoute) {
        this.limit = 21
      }
    }
  }

</script>

<style>
  body, input, select, button, textarea {
    font-family: 'Inter', sans-serif;
  }

  .fancybox-inner {
    background: white !important;
  }

  .customControl {
    background-color: #fff;
    border: 1px solid #4A90E2;
    padding: 10px;
    border-radius: 4px;
    max-width: 350px;
    font-size: 14px;
  }
</style>

<!--<style src='element-ui/lib/theme-chalk/index.css'>-->
  <!--/* global styles */-->
<!--</style>-->

<style src="../shared/styles/reset.css" lang="css">

</style>

<style src="../shared/styles/common.css" lang="css">
</style>

<style src="../shared/styles/page.css" lang="css">

</style>

<style src='../shared/styles/back-to-top.css' lang="css">
  /* back to top */
</style>

<style src='../shared/styles/colors.css' lang="css">
/* back to top */
</style>

<style>
  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    src: url("../../assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Inter-Regular.woff") format("woff");
    font-display: fallback;
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    src: url("../../assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/Inter-SemiBold.woff") format("woff");
    font-display: fallback;
  }

  @font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    src: url("../../assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Inter-Bold.woff") format("woff");
    font-display: fallback;
  }
</style>

<style scoped>
  .wrapper {
    width: 100%;
    padding: 0 60px;
    max-width: 1400px;
    min-width: 1100px;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .embedded .wrapper {
    padding: 0;
    min-width: auto;
  }

  .pifakit-link {
    color: #8a8a8a;
    font-size: 13px;
  }

  .root >>> .el-dialog__header {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .root >>> .el-dialog__title {
    font-size: 32px;
    font-family: 'Inter', sans-serif;
  }

  .root >>> .el-dialog__body {
    padding-left: 50px;
    padding-right: 50px;
    word-break: keep-all !important;
    line-height: 22px;
  }

  .error-report {
    position: absolute;
    width: 200px;
    padding: 10px 20px;
    background: #b3ddff;
    opacity: 0.9;
    top: 20px;
    right: 20px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    z-index: 100;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>

<style>
  .embedded .order-page-title {
    display: none;
  }

  .embedded .order-download-link {
    display: none;
  }
</style>
