<template lang="pug">
  a.btn-filter-remove(@click="$emit('click')")
    icon-cross-small
    | Сбросить все
</template>

<script>
  import IconCrossSmall from 'Shared/sprites/icon-cross-small.svg'

  export default {
    components: {IconCrossSmall},
    name: 'agv-filter-remove-button'
  }
</script>

<style scoped>
  .btn-filter-remove {
    color: #828282;
    font-size: 11px;
    line-height: 12px;
  }

  .btn-filter-remove .icon-cross {
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
</style>
