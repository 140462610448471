<template lang="pug">
  .nav-sheet-container(:class='layoutContainerClass')
    agv-nav-sheet(:is-abridged="isAbridged"
    :class='layoutClass'
    :town="town"
    :sections="sections"
    :subsections="subsections"
    v-click-outside="onClickOutside")
</template>

<script>
  import _ from 'lodash'
  import AgvNavSheet from 'Aggregator/components/agv-nav-sheet'

  export default {
    name: 'agv-nav-sheet-popover',
    components: {AgvNavSheet},
    props: {
      town: Object,
      sections: Array,
      subsections: Array,
      isAbridged: Boolean
    },
    computed: {
      layoutContainerClass() {
        return (this.isAbridged ? 'nav-sheet-container-abridged' : 'nav-sheet-container-full')
      },
      layoutClass() {
        return (this.isAbridged ? 'nav-sheet-abridged' : 'nav-sheet-full')
      }
    },
    methods: {
      onClickOutside() {
        if (this.$el.style.display !== 'none') {
          this.$emit('close')
        }
      }
    }
  }
</script>

<style scoped>
  .nav-sheet-container-full {
    position: absolute;
    top: 136px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .nav-sheet-container-abridged {
    position: absolute;
    top: 150px;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }

  .nav-sheet-container-full {
    position: absolute;
    top: 136px;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .nav-sheet-container-abridged {
    position: absolute;
    top: 150px;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }

  .nav-sheet {
    border: 1px solid #979797;
    padding: 36px 10px 20px 47px;
    z-index: 1;
    background: white;
    font-size: 13px;
  }

  .nav-sheet-full {
    width: 990px;
    height: 590px;
    margin: 0 auto;
  }

  .nav-sheet-abridged {
    width: fit-content;
    min-width: 230px;
    position: absolute;
    padding: 20px 32px 10px;
  }

  .nav-sheet-abridged .section {
    margin-right: 0;
  }

  .nav-sheet-abridged .important-kinds, .nav-sheet-abridged .favorites-link {
    font-size: 14px;
    line-height: 30px;
  }

  .nav-sheet-abridged .important-kinds li a, .nav-sheet-abridged .favorites-link li a {
    white-space: nowrap;
    display: block;
  }
</style>
