export function openWindowWithPost(url, params) {
  var form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  for (var i in params) {
    if (params.hasOwnProperty(i)) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = i;
      input.value = typeof params[i] == 'object' ? JSON.stringify(params[i]) : params[i];
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  console.log(form)
  form.submit();
  document.body.removeChild(form);
}
