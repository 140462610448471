/*eslint no-var: "error"*/
/*eslint-env es6*/
//@flow

import Vue from 'vue'
import Vuex from 'vuex'
import VueCookie from 'vue-cookie'
import Storage from 'vue-web-storage'

import state from './state'
import mutations from './mutations'
import getters from './getters'

import createArticlesStore from 'Shared/store/articles'
import createOrderStore from 'Shared/store/order/index.es6'
import createAvailabilityStore from 'Shared/store/availability/index.es6'
import createCustomerStore from 'Shared/store/customer'
import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'
import orderActionLogger from 'Shared/orderActionLogger'

import createTownStore from 'RenterSite/store/town'
import createRenterCustomerStore from 'Shared/store/renterCustomer'
import {getArticle, getOrdersUrl, getStateUrl} from 'RenterSite/lib/order'
import {getAvailabilityUrl} from 'RenterSite/shared/availability'
import {toRenterSiteArticles} from 'RenterSite/shared/renterSiteArticles'

Vue.use(Vuex)
Vue.use(VueCookie)
Vue.use(Storage, {
  prefix: 'pkitApp.'
})

const townStore = createTownStore(
  _.map(window.INITIAL_STATE.branches, b => b.town),
  Vue.cookie.get('town_name')
)

const availabilityStore = createAvailabilityStore(
  getAvailabilityUrl
)

const store = new Vuex.Store(
  {
    state,
    mutations,
    getters,
    plugins: [orderActionLogger],
    modules: {
      articles: createArticlesStore(
        (_) => state.renter.id,
        toRenterSiteArticles
      ),
      customer: createCustomerStore(
        '/api/customer.json',
        '/customers/sign_out.json',
        '/api/customer.json'
      ),
      renterCustomer: createRenterCustomerStore(
        () => '/api/customer'
      ),
      availability: availabilityStore,
      town: townStore
    }
  }
)

store.registerModule('order', createOrderStore(
  townStore.state.current.id,
  () => {
    return state.renter
  },
  getArticle,
  getOrdersUrl,
  (articleId: number, renterId: number) => {
    return getAvailableForPeriodOrTotal(
      getArticle(articleId, renterId),
      renterId,
      store
    )
  },
  getStateUrl,
  false
))

export default store
