import { render, staticRenderFns } from "./agv-color-btn.vue?vue&type=template&id=6975a2a9&scoped=true&lang=pug&"
import script from "./agv-color-btn.vue?vue&type=script&lang=js&"
export * from "./agv-color-btn.vue?vue&type=script&lang=js&"
import style0 from "./agv-color-btn.vue?vue&type=style&index=0&id=6975a2a9&scoped=true&lang=css&"
import style1 from "../../shared/styles/colors.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6975a2a9",
  null
  
)

export default component.exports