<template lang="pug">
  table.agv-customer-transactions-table.table
    tr
      th.cell
        | Дата
      th.cell
        | Заказ
      th.cell
        | Основание
      th.cell
        | Сумма
    tr.row(v-for="tx in limitedTransactions")
      td.cell
        | {{tx.created_at | longDate}}
      td.cell
        router-link(v-if="tx.order" :to="getOrdersRouteUrl(renterId, tx.order.town_id) + '/' + tx.order.id")
          | {{tx.order.code}}
        span(v-else)
          | —
      td.cell
        | {{transactionReason(tx)}}
      td.cell
        | {{tx.sum | money(countryCode)}}
</template>

<script>
  export default {
    name: "agv-customer-transactions-table",
    props: {
      transactions: Array,
      showAll: Boolean,
      countryCode: String,
      renterId: Number,
      getOrdersRouteUrl: Function
    },
    computed: {
      limitedTransactions() {
        if (this.showAll) {
          return _.orderBy(this.transactions, 'created_at', 'desc')
        } else {
          return _.take(_.orderBy(this.transactions, 'created_at', 'desc'), 10)
        }
      }
    },
    methods: {
      transactionReason(tx) {
        switch (tx.tx_type) {
          case 'receipt':
            return 'Поступление денег'
          case 'receipt_with_bill':
            return 'Поступление денег'
          case 'surplus_retrieval':
            return 'Возврат средств'
          case 'order_payment_from_surplus':
            return 'Оплата заказа'
          case 'order_payment_on_credit':
            return 'Оплата заказа'
          case 'order_surplus_release':
            return 'Изменение заказа, возврат предоплаты'
          case 'order_paid_release':
            return 'Отмена заказа, возврат предоплаты'
          case 'generic_compensation_from_renter':
            return tx.reason
          case 'generic_compensation_from_customer':
            return tx.reason
        }
      }
    }
  }
</script>

<style scoped>
  .cell:nth-child(3) {
    min-width: 180px;
    width: 470px;
  }

  .cell:nth-child(4) {
    text-align: right;
  }

  td.state-cell {
    width: 366px;
  }

  td.state-cell.state-payment-due-today {
    color: #d0011b;
  }
</style>
