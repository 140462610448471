export default {
  setRenter(state, {renter, renterId}) {
    state.renter = renter
  },
  setIsOrderPlacing(state, value) {
    state.isOrderPlacing = value;
  },
  setIsOrderCancelling(state, value) {
    state.isOrderCancelling = value;
  },
  setIsOrderSaving(state, value) {
    state.isOrderSaving = value;
  }
}
