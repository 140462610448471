<template lang="pug">
  el-dialog(width="420px" :visible="true" @close="$emit('close')")
    p
      | Ссылка на восстановление пароля
      br
      | отправлена на указанную вами электронную почту.
</template>

<script>
  export default {
    name: "one-time-access-modal"
  }
</script>

<style scoped>

</style>
