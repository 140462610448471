<template lang="pug">
  transition(appear='' name='v-img-fade')
    .fullscreen-v-img(v-if='!closed' @click.self='close')
      .close-btn-container(@click='close')
        close-popup
      span.prev-v-img(v-if='images.length !== 1' @click='prev')
        arrow-prev
      span.next-v-img(v-if='images.length !== 1' @click='next')
        arrow-next
      .footer-v-img(v-if='thumbnails && images.length > 1')
        img(v-for='(thumbnail, index) in images' :key='index' :src='thumbnail' @click='select(index)' :class="{'is-selected': currentImageIndex == index}")
      .content-v-img
        img(:src='images[currentImageIndex]' @click='next')
</template>

<script>
import ClosePopup from '../../sprites/close-popup.svg';
import ArrowPrev from '../../sprites/arrow-prev.svg';
import ArrowNext from '../../sprites/arrow-next.svg';

export default {
  data() {
    return {
      images: [],
      titles: [],
      sourceButtons: [],
      visibleUI: true,
      currentImageIndex: 0,
      closed: true,
      uiTimeout: null,
      handlers: {},
      thumbnails: false,
    };
  },
  watch: {
    closed(newVal) {
      if (newVal && this.handlers.closed) {
        this.handlers.closed();
      }
      if (!newVal && this.handlers.opened) {
        this.handlers.opened();
      }
    },
  },
  methods: {
    // Not using currentImageIndex watcher because it will
    // fire on all cases when opened not first image and
    // index should be changed in order to show clicked image
    // in the image set.
    fireChangeEvent() {
      if (this.handlers.changed) {
        this.handlers.changed(this.currentImageIndex);
      }
    },
    close() {
      if (!this.closed) {
        document.querySelector('body').classList.remove('body-fs-v-img');
        this.images = [];
        this.currentImageIndex = 0;
        this.closed = true;
      }
    },
    next() {
      if (!this.closed && this.images.length > 1) {
        // if next index not exists in array of images, set index to first element
        if (this.currentImageIndex + 1 < this.images.length) {
          this.currentImageIndex++;
        } else {
          this.currentImageIndex = 0;
        }
        this.fireChangeEvent();
      }
    },
    select(selectedImage) {
      this.currentImageIndex = selectedImage;
    },
    prev() {
      if (!this.closed && this.images.length > 1) {
        // if prev index not exists in array of images, set index to last element
        if (this.currentImageIndex > 0) {
          this.currentImageIndex--;
        } else {
          this.currentImageIndex = this.images.length - 1;
        }
        this.fireChangeEvent();
      }
    },
    showUI() {
      if (!this.closed) {
        // UI's hidden, we reveal it for some time only on mouse move and
        // ImageScreen appear
        clearTimeout(this.uiTimeout);
        this.visibleUI = true;
        this.uiTimeout = setTimeout(() => {
          this.visibleUI = false;
        }, 3500);
      }
    },
  },
  components: {ArrowPrev, ArrowNext, ClosePopup},
  created() {
    window.addEventListener('keyup', e => {
      // esc key and 'q' for quit
      if (e.keyCode === 27 || e.keyCode === 81) this.close();
      // arrow right and 'l' key (vim-like binding)
      if (e.keyCode === 39 || e.keyCode === 76) this.next();
      // arrow left and 'h' key (vim-like binding)
      if (e.keyCode === 37 || e.keyCode === 72) this.prev();
    });
    window.addEventListener('scroll', () => {
      this.close();
    });
    window.addEventListener('mousemove', () => {
      this.showUI();
    });
  },
};
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.fullscreen-v-img {
  background-color: white;
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.content-v-img img {
  cursor: pointer;
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header-v-img,
.footer-v-img {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
}

.header-v-img {
  justify-content: space-between;
}

.footer-v-img {
  bottom: 0;
  justify-content: center;
  height: 70px;
  /* scrolling thumbnails on mobile */
  overflow-x: auto;
}

.footer-v-img img {
  width: 60px;
  height: 60px;
  cursor: pointer;
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  object-fit: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footer-v-img img.is-selected {
  transform: scale(1.1);
}

.footer-v-img img:not(:last-child) {
  margin-right: 7px;
}

.buttons-v-img span path {
  fill: #e5e6eb;
  -webkit-transition: fill 0.4s ease-in-out;
  transition: fill 0.4s ease-in-out;
}

.buttons-v-img span {
  cursor: pointer;
  color: #e5e6eb;
  font-size: 30px;
  -webkit-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
  text-decoration: none;
  text-align: center;
}

.buttons-v-img span:not(:last-child) {
  margin-right: 8px;
}

.buttons-v-img span svg {
  height: 20px;
  width: 15px;
}

.buttons-v-img span:hover svg path {
  fill: white;
}

.buttons-v-img span:hover {
  color: white;
}

.prev-v-img,
.next-v-img {

  top: 50%;
  position: absolute;
  display: block;
  border-radius: 50%;
  background: white;
  width: 70px;
  height: 70px;

  color: white;
  margin-top: -35px;
  font-size: 15px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  text-align: center;
  z-index: 1000;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.prev-v-img {
  left: 10px;
}

.next-v-img {
  right: 10px;
}

.v-img-fade-enter,
.v-img-fade-leave-to {
  opacity: 0;
}

.v-img-fade-enter-active,
.v-img-fade-leave-active {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.close-btn-container {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  left: 20px;
  top: 20px;
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 1000;
}
</style>
