import { render, staticRenderFns } from "./page-rsv-rules.vue?vue&type=template&id=0262001d&scoped=true&lang=pug&"
import script from "./page-rsv-rules.vue?vue&type=script&lang=js&"
export * from "./page-rsv-rules.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0262001d",
  null
  
)

export default component.exports