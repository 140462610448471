<template lang="pug">
  li.agv-tag-li-image(:class="{'tag-inactive': !active, 'checked': checked}")
    el-image.el-image(:src="normalImageUrl" fit="scale-down")
    .tag-name
      | {{name | capitalize}}
</template>

<script>
  export default {
    name: "agv-tag-li-image",
    props: {
      name: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        required: true
      },
      checked: {
        type: Boolean,
        required: true
      },
      imageUrl: {
        type: String,
        required: true
      }
    },
    computed: {
      normalImageUrl() {
        return (this.imageUrl || '').replace('thumbnail_', 'aggregator_')
      }
    }
  }
</script>

<style scoped>
  .tag-inactive {
    opacity: 0.3;
    pointer-events: none;
  }

  .agv-tag-li-image {
    position: relative;
    font-size: 13px;
    color: #000000;

    display: flex;
    cursor: pointer;

    margin-bottom: 14px;

    align-items: center;
    justify-content: center;

    border: 2px solid white;
    border-radius: 5px;

    white-space: nowrap;
  }

  .agv-tag-li-image:hover {
    border-color: #ddd;
  }

  .agv-tag-li-image.checked {
    border-color: #f6a623;
  }

  .el-image {
    height: 100%;
    width: 100%;
    border-radius: 3px;
  }

  .tag-name {
    position: absolute;

    color: #9b9b9b;
    font-size: 10px;
    transform: translateX(-50%);
    bottom: -14px;
    left: 50%;

    display: none;
  }

  .agv-tag-li-image.checked .tag-name {
    color: #f6a623;
  }

  .agv-tag-li-image:hover .tag-name {
    display: block;
  }
</style>
