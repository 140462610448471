<template lang="pug">
  .order-delivery-by-renter.order-service-row.cc-order-delivery-by-renter(:class="{'is-editing': isEditing}")
    order-delivery-details-modal(v-if="showDetailsModal"
    :delivery-details="deliveryDetails"
    :get-orders-url="getOrdersUrl"
    :order="order"
    :branch="branch"
    :town="town"
    :renter="renter"
    :has-pifakit-service="!!pifakitService"
    @validated="onDetailsValidated"
    @close="handleModalClose")
    .name-cell
      .service-title
        .service-name
          | Доставка
      .label-mandatory(v-if="renter.is_delivery_mandatory")
        | Обязательная
      el-popover(v-if='articlesWithMandatoryDelivery.length > 0'
      placement="top-start"
      popper-class="cc-popover-articles-with-mandatory-delivery"
      width="400"
      trigger="hover")
        .explanation
          p
            | Некоторые изделия мы доставляем сами,
            br
            | потому что они сложные в перевозке.
          p
            | В эту смету добавлены такие изделия:
        ul.articles-with-mandatory-services.cc-articles-with-mandatory-delivery
          li(v-for='article in articlesWithMandatoryDelivery')
            | {{article.name}}
        .label-mandatory(slot="reference")
          | Обязательная
      el-button.cc-btn-remove-delivery(v-else-if="isEditing" type="text" @click="$emit('remove')")
        | Удалить услугу
    .details-cell
      el-popover(popper-class="warning"
      width="200"
      v-model="showError"
      placement="left"
      trigger="manual")
        | Укажите детали доставки
        .details-container.cc-delivery-details(@click='openDeliveryDetailsModal'
        slot="reference"
        style="width: fit-content"
        :class='{warning: order.errors && order.errors.delivery}')
          .details(v-if='!isEditing || areDetailsCompleted' :class="{'details-editing': isEditing}")
            .details-row
              | Получение –
              |
              span(v-if="order.acquisition")
                | {{order.acquisition | date('dd.MM.yy')}}
                |
              | {{acquisitionTimePeriod | timePeriod }},
              br
              | возврат –
              |
              span(v-if="order.returning")
                | {{order.returning | date('dd.MM.yy')}}
                |
              | {{returningTimePeriod | timePeriod }}
            .details-row
              | {{order.delivery.address}}
            .details-row
              | {{order.delivery.contact_name}},
              | {{order.delivery.contact_phone | phoneNumber(renter.country)}}
          .details.details-to-be-filled(v-else)
            .details-row
              | Время доставки
            .details-row
              | Место доставки
            .details-row
              | Контактное лицо
    .distance-cell
      span(v-if="renter.unloading_and_loading_cost !== null")
        | {{(order.delivery.is_to_hall || (renter.unloading_and_loading_cost === 0)) ? 'до зала' : 'до парковки'}},
      div
        span(v-if='order.delivery.distance')
          | {{order.delivery.distance}} км от {{town.short_genitive_name.replace('Москвы', 'МКАД')}}
        span(v-else)
          | в пределах {{town.short_genitive_name.replace('Москвы', 'МКАД')}}
    .total-price-cell.cc-total-price
      | {{price | money(renter.country)}}
</template>

<script>
  import Vue from 'vue'

  import {
    areDeliveryDetailsCompleted,
    getDeliveryDetails,
    getDeliveryPrice,
    getArticlesWithMandatoryDelivery, getServiceDiscountPortion
  } from 'Shared/order'
  import OrderDeliveryDetailsModal from 'Shared/components/order-delivery-details-modal'
  import {deliveryPrice as pifakitServiceDeliveryPrice} from "Shared/pifakitService";

  export default {
    name: 'order-delivery-by-renter',
    components: {OrderDeliveryDetailsModal},
    props: {
      order: Object,
      customer: Object,
      branch: Object,
      town: Object,
      renter: Object,
      getOrdersUrl: Function,
      pifakitService: Object,
      isEditing: Boolean
    },
    data() {
      return {
        isDeliveryExtratown: true,
        deliveryDetails: null,
        showDetailsModal: false,
        internalDistanceValue: null
      }
    },
    computed: {
      showError: {
        get() {
          return !!_.get(this.order.errors, 'delivery')
        },
        set(value) {
        },
      },
      articlesWithMandatoryDelivery() {
        return getArticlesWithMandatoryDelivery(_.map(this.order.line_items, 'article'))
      },
      areDetailsCompleted() {
        return areDeliveryDetailsCompleted(this.order.delivery, this.renter.unloading_and_loading_cost !== null)
      },
      acquisitionTimePeriod() {
        return [
          this.order.delivery.acquisition_hour_start,
          this.order.delivery.acquisition_hour_end
        ]
      },
      returningTimePeriod() {
        return [
          this.order.delivery.returning_hour_start % 24,
          this.order.delivery.returning_hour_end % 24
        ]
      },
      price() {
        if (this.pifakitService) {
          return pifakitServiceDeliveryPrice(
            this.order,
            this.pifakitService,
            this.renter.service_discount
          )
        }
        return getDeliveryPrice(
          this.order.line_items,
          this.order.delivery,
          this.branch.delivery_price_multiplier,
          this.renter
        )
      }
    },
    methods: {
      openDeliveryDetailsModal() {
        this.$store.commit('order/clearDeliveryError', {renterId: this.renter.id})
        this.deliveryDetails = getDeliveryDetails(this.order, this.customer)
        this.showDetailsModal = true
      },
      onDetailsValidated(details) {
        const wasOldInMorning = !_.isNil(this.order.delivery?.returning_hour_end) && this.order.delivery.returning_hour_end <= 4
        const isNewMorning = details.returning_hour_end <= 4
        const shouldUpdateAvailability = wasOldInMorning !== isNewMorning

        this.$store.commit('order/setDeliveryDetails', {renterId: this.renter.id, details})
        this.showDetailsModal = false
        if (shouldUpdateAvailability) {
          this.$store.dispatch('availability/loadAvailable',
          {renterId: this.renter.id, reason: 'setRentPeriod'},
          {root: true}
          )
        }
      },
      onBlur() {
        this.syncDistance(this.order.delivery.distance)
      },
      syncDistance(value) {
        this.internalDistanceValue = value
      },
      onOrderChange() {
        this.isDeliveryExtratown = this.order.delivery.distance > 0
        this.syncDistance(this.order.delivery.distance)
      },
      handleModalClose() {
        this.showDetailsModal = false
      }
    },
    watch: {
      order: [{
        handler: 'onOrderChange',
        immediate: true
      }],
      'order.delivery.distance': [{
        handler: 'syncDistance',
        immediate: true
      }]
    }
  }
</script>

<style scoped>
  .details-cell {
    flex-grow: 2;
  }

  .kms {
    display: inline-block;
    margin-left: 10px;
  }

  .details {
    font-size: 13px;
    line-height: 1.5em;
    padding: 10px 15px;
    margin-left: -15px;
    margin-top: 10px;
    width: fit-content;
    cursor: pointer;
  }

  .details-container {
    pointer-events: none;
  }

  .is-editing .details-container {
    pointer-events: auto;
  }

  .details-to-be-filled {
    color: #80bfff;
  }

  .details-editing {
    color: #409EFF;
  }

  .details-container.warning .details-to-be-filled {
    color: #ff9e66;
  }

  .details:hover {
    background: #f8f8f8;
  }

  .distance-cell {
    width: 230px;
    line-height: 1.5;
  }
</style>
