const orderActionLogger = (store) => {
  store.subscribeAction({
    before: (action, state) => {
      console.log(`Action "${action.type}" began with payload:`, action.payload);
      if (action.type === 'order/place') {
        store.commit('setIsOrderPlacing', true);
      } else if (action.type === 'order/cancel') {
        store.commit('setIsOrderCancelling', true);
      } else if (action.type === 'order/save') {
        console.log('order/save');
        store.commit('setIsOrderSaving', true);
      }
    },
    after: (action, state) => {
      console.log(`Action "${action.type}" completed.`);
      if (action.type === 'order/place') {
        store.commit('setIsOrderPlacing', false);
      } else if (action.type === 'order/cancel') {
        store.commit('setIsOrderCancelling', false);
      } else if (action.type === 'order/save') {
        store.commit('setIsOrderSaving', false);
      }
    },
    error: (action, state) => {
      console.log(`Action "${action.type}" failed.`);
      if (action.type === 'order/place') {
        store.commit('setIsOrderPlacing', false);
      } else if (action.type === 'order/cancel') {
        store.commit('setIsOrderCancelling', false);
      } else if (action.type === 'order/save') {
        store.commit('setIsOrderSaving', false);
      }
    }
  });
};

export default orderActionLogger;