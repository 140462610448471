// @flow

import getActions from './actions'
import getters from './getters'
import mutations from './mutations'

export default function createStore(
  getAvailabilityUrl: Function
){
  return {
    namespaced: true,
    state() {
      return {
        availableByRenterId: {},
        lastAvailabilityUpdateReason: null,
      }
    },
    getters,
    mutations,
    actions: getActions(getAvailabilityUrl)
  }
}
