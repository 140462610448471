<template lang="pug">
  .form-container
    .page-heading
      h1.page-title.page-title_top
        span(v-if="isInPasswordChangeMode")
          | Смена пароля
        span(v-else)
          | Мои данные
    form-agv-details(:customer="customerCopy"
    :errors="errors"
    :country-code="renter.country"
    :is-in-password-change-mode="isInPasswordChangeMode"
    :is-in-aggregator-mode="false"
    :renter-genitive-name="renter.short_commercial_name_cases[1]")
    el-button(type="success" @click="onTrySave")
      | Сохранить
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import Vue from 'vue'

  import FormAgvDetails from 'Aggregator/pages/form-agv-details'

  export default {
    name: 'page-rsv-customer-details',
    components: {FormAgvDetails},
    props: {
      customer: Object
    },
    data() {
      return {
        errors: {},
        customerCopy: {},
        isInPasswordChangeMode: false,
        renter: this.$store.state.renter
      }
    },
    methods: {
      onTrySave() {
        this.$store.dispatch('customer/save', {customer: this.customerCopy})
          .then(() => {
            this.isInPasswordChangeMode = false
            this.$router.push('/customer/details')
          })
          .catch((error) => {
            Vue.set(this, 'errors', error.response.data)
          })
      },
      onCustomerChange() {
        this.customerCopy = _.clone(this.customer)
      }
    },
    watch: {
      customer: {
        handler: 'onCustomerChange',
        immediate: true
      }
    },
    created() {
      this.isInPasswordChangeMode = !!this.$route.path.match('password-change')
    }
  }
</script>

<style scoped>
</style>
