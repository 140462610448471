import ArticleSize from 'Shared/ArticleSize'

class RenterSiteArticle {
  get url() {
    return `/article/${this.id}`
  }

  get preview_url() {
    return `/${this.photos[0].image.preview.url}`
  }

  get size() {
    return new ArticleSize(this).value
  }

  get branch() {
    return this.state.branchesByTownId[this.state.town.current.id]
  }

  get price_in_town() {
    return Math.round(this.price * this.branch.rent_price_multiplier)
  }

  get total_available() {
    return this.type === 'Article' ? _.min(
      _.map(this.components_with_quantity, ({id, quantity}) => Math.floor(this.state.articlesById[id].total_available / quantity))
    ) : this.branch.available_total[this.id]
  }

  get are_all_components_offered() {
    return this.type === 'Article' ? _.every(
      _.map(this.components_with_quantity, ({id}) => this.state.articlesById[id].is_offered
      )) : this.is_offered
  }
}

export function toRenterSiteArticles(articles, state) {
  for (let a of articles) {
    a.state = state
    Object.setPrototypeOf(a, new RenterSiteArticle)
  }
  // for (let a of articles) {
  //   if (a.type === 'Article' && a.components_with_quantity) {
  //     a.price = _.sum(_.map(a.components_with_quantity, ({id, quantity}) => state.articlesById[id].price * quantity))
  //   }
  // }
}
