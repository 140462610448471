<template lang="pug">
  .order-ad-hoc-line-item.cc-order-ad-hoc-line-item
    .name-cell
      .name
        el-input.name-input.cc-name-input(v-if="isEditing"
        :value="item.name"
        @input="updateName"
        :debounce="1000"
        :class="{error: item.nameError}")
        span.name-text(v-else)
          | {{item.name}}
      .btn-remove(v-if="isEditing")
        el-button(type="text" @click="$emit('remove', item.id)")
          | Удалить позицию
    .total-price-cell
      span(v-if="isEditing" style="white-space: nowrap;")
        el-input.price-input.cc-price-input(:value="item.price"
        @input="updatePrice"
        :class="{error: item.priceError}")
        |
        span.price-text(style="margin-left: 10px;")
          | {{countryCode | currencySymbol}}
      span.price-text(v-else)
        | {{item.price | money(countryCode)}}
</template>

<script>
  export default {
    name: "order-ad-hoc-line-item",
    props: {
      item: Object,
      countryCode: String,
      isEditing: Boolean
    },
    methods: {
      updateName(name) {
        this.$emit('setName', this.item, name)
      },
      updatePrice(price) {
        this.$emit('setPrice', this.item, price)
      }
    }
  }
</script>

<style scoped>
  .order-ad-hoc-line-item {
    display: flex;
    align-items: baseline;
    height: 135px;
    justify-content: space-between;
  }

  .order-ad-hoc-line-item > div {
    padding-left: 20px;
  }

  .name-cell {
    margin-left: 100px;
    width: 60%;
  }

  .el-input.error >>> input {
    border-color: #F56C6C;
  }

  .name-text {
    font-weight: bold;
  }
</style>
