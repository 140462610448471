import Vue from 'vue'
import _ from 'lodash'
import accounting from 'accounting'
import dayjs from 'dayjs'
import {format} from 'date-fns'
import intlFormatDistance from 'date-fns/intlFormatDistance'

Vue.filter('formatRelativeGMTDate', (date) => {
  return intlFormatDistance(new Date(date + 'Z'), new Date(), {addSuffix: true, locale: 'ru'})
})

Vue.filter('capitalize', (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
})

Vue.filter('uncapitalize', (str) => {
  return str ? str.charAt(0).toLowerCase() + str.slice(1) : ''
})

Vue.filter('lowercase', (str) => {
  return str.toLowerCase()
})

const currencySymbolFilter = Vue.filter('currencySymbol', function (countryCode, short) {
  const shortSymbols = {ru: '₽', cz: '₽', ua: 'гр.', by: 'BYN', kz: 'Тг.', md: '€', ee: '€', cy: '€', kg: 'KGS'}
  const longSymbols = {ru: '₽', cz: '₽', ua: 'грн.', by: 'BYN', kz: 'Тг.', md: '€', ee: '€', cy: '€', kg: 'KGS'}
  return (short ? shortSymbols : longSymbols)[countryCode]
})

const SYMBOLS_ISO4217 = {
  ru: 'RUB',
  cz: 'RUB',
  ua: 'UAH',
  by: 'BYN',
  kz: 'KZT',
  md: 'EUR',
  ee: 'EUR',
  cy: 'EUR',
  kg: 'KGS'
}

Vue.filter('currencySymbolISO4217',
  function (countryCode) {
    return SYMBOLS_ISO4217[countryCode]
  }
)

Vue.filter('date', function (date, dateFormat) {
  return format(new Date(date), dateFormat)
})

Vue.filter('decline', function (n, text_forms, only_word) {
  if (_.isUndefined(n)) {
    n = 0
  }
  let word = undefined
  if (Math.round(n) === parseInt(n)) {
    const cases = [2, 0, 1, 1, 1, 2]
    word = text_forms[(((n % 100) > 4) && ((n % 100) < 20)) ? 2 : cases[((n % 10) < 5) ? n % 10 : 5]]
  } else {
    word = text_forms[1]
  }
  if (only_word) {
    return word
  } else {
    return n.toString().replace('.', ',') + ' ' + word
  }
})

Vue.filter('number', (num, decimalPlaces) => {
  return num.toFixed(decimalPlaces)
})

Vue.filter('pkitNumber', (num) => {
  let result = Math.round(num * 10) / 10
  return String(result).replace('.', ',')
})

const DAY_NAMES = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']

Vue.filter('cpWorkdaysFromOffDays', function (cpOffDays) {
  if (_.isUndefined(cpOffDays)) {
    return ''
  }
  if (_.isEqual(cpOffDays, [true, false, false, false, false, false, true])) {
    return 'пн – пт'
  }
  if (_.isEqual(cpOffDays, [true, false, false, false, false, false, false])) {
    return 'пн – сб'
  }

  if (_.isEqual(cpOffDays, [false, false, false, false, false, false, false])) {
    return 'каждый день'
  }

  let offDaysIndices = _.compact(_.map(cpOffDays, (d, i) => {
    return d ? i : null
  }))
  let offDaysNames = _.map(offDaysIndices, i => {
    return DAY_NAMES[i]
  })
  let offDaysString
  if (offDaysNames.length > 1) {
    offDaysString = _.initial(offDaysNames).join(', ') + ' и ' + _.last(offDaysNames)
  } else {
    offDaysString = offDaysNames[0]
  }
  return `каждый день, кроме ${offDaysString}`
})

const militaryTime = number => (number < 10 ? `0${number}` : number) + ':00'

Vue.filter('militaryTime', militaryTime)

Vue.filter('cpSchedule', (cpSchedule) => {
    if (cpSchedule) {
      if ((cpSchedule[0] === 0) && (cpSchedule[1] === 0)) {
        return 'круглосуточно'
      } else {
        return `${militaryTime(cpSchedule[0])} – ${militaryTime(cpSchedule[1])}`
      }
    }
  }
)

Vue.filter('percentage', (number) => {
  return Math.round(number * 100) + '%'
})

const PHONE_PREFIX_INTERNATIONAL = {
  ru: '7',
  cy: '357',
  cz: '7',
  ua: '380',
  by: '375',
  kz: '7',
  md: '373',
  kg: '0',
  ee: '372'
}

const PHONE_PREFIX_RUSSIAN = {
  ru: '8',
  cy: '357',
  cz: '8',
  ua: '380',
  by: '375',
  kz: '8',
  md: '373',
  kg: '0',
  ee: '372'
}

export function phonePrefix(countryCode, international = false) {
  if (international) {
    return PHONE_PREFIX_INTERNATIONAL[countryCode]
  }

  return PHONE_PREFIX_RUSSIAN[countryCode]
}

Vue.filter('phonePrefix', phonePrefix)

Vue.filter('phoneNumber', (tel, countryCode) => {
  if (!tel) {
    return ''
  }
  const regex =
    (() => {
      switch (countryCode) {
        case 'ru':
        case 'kz':
        case 'cz':
          return /(\d{3})(\d{3})(\d{2})(\d{2})/
        case 'md':
          return /(\d{3})(\d{5})/
        case 'kg':
          return /(\d{3})(\d{6})/
        case 'ee':
        case 'cy':
          return /(\d+)/
        default:
          return /(\d{2})(\d{3})(\d{2})(\d{2})/
      }
    })()

  let digitGroups = tel.match(regex).slice(1)

  return phonePrefix(countryCode) + ' (' + digitGroups[0] + ') ' + digitGroups.slice(1).join('-')
})

const MONTHS = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек']
const WEEKDAYS = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']

Vue.filter('longDate', (inputDate) => {
  let date = dayjs(inputDate)
  return `${date.date()} ${MONTHS[date.month()]} ${date.year()}`
})

Vue.filter('deliveryDetailsDate', (inputDate) => {
  let date = dayjs(inputDate)
  return `${DAY_NAMES[date.day()]}, ${date.date()} ${MONTHS[date.month()]}`
})

Vue.filter('dateRange', (dates) => {
  let acq = dayjs(dates[0])
  let ret = dayjs(dates[1])

  if (acq.isSame(ret)) {
    return `${acq.date()} ${MONTHS[acq.month()]}, ${WEEKDAYS[acq.day()]}`
  }

  if (acq.month() === ret.month()) {
    return `${acq.date()}–${ret.date()} ${MONTHS[acq.month()]}, ${WEEKDAYS[acq.day()] + '–' + WEEKDAYS[ret.day()]}`
  } else {
    return `${acq.date()} ${MONTHS[acq.month()]} – ${ret.date()} ${MONTHS[ret.month()]}, ${WEEKDAYS[acq.day()] + '–' + WEEKDAYS[ret.day()]}`
  }
})

const moneyFilter = (number, countryCode = 'ru', alwaysSigned = false, forceCurrency = false) => {
  const currency = currencySymbolFilter(countryCode)
  number = parseInt(number)
  if ((number === 0) && !forceCurrency) {
    return '0'
  } else {
    const thousand = ' '
    const decimal = ','
    const format = '%v %s'
    return ((alwaysSigned && (number > 0)) ? '+ ' : '') + (accounting.formatMoney(number, currency, 0, thousand, decimal, format)).replace('-', '– ')
  }
}

Vue.filter('money', moneyFilter)

const formatPaymentDeadline = (order) => {
  const paymentDueDate = dayjs(order.payment_due)
  const leftTillDeadline = paymentDueDate.diff(dayjs().startOf('day'), 'days')
  if (leftTillDeadline === 1) {
    return 'до завтра'
  } else if (leftTillDeadline === 0) {
    return 'сегодня'
  } else {
    return `до ${paymentDueDate.date()} ${MONTHS[paymentDueDate.month()]}`
  }
}

const formatWhatToPay = (order, countryCode) => {
  return order.required_payment === '100' ? `остаток ${moneyFilter(order.total - order.paid, countryCode)}` : 'предоплату'
}

Vue.filter('stateToWords', function (order, countryCode) {
  switch (order.state) {
    case 'payment_pending':
      return `Внести ${formatWhatToPay(order, countryCode)} — ${formatPaymentDeadline(order)}`
    case 'paid':
      return 'Оплачен'
    case 'preparing':
      return 'Подготовка'
    case 'executing':
      return 'В работе'
    case 'completed':
      return 'Выполнен'
    case 'cancelled_over_delinquency':
    case 'cancelled_before_starting':
    case 'cancelled_in_process':
      return 'Отменен'
    default:
      return ''
  }
})

Vue.filter('formatCreditCard', cardNumber => cardNumber.replace(/\D/g, '').match(/.{1,4}|^$/g).join(' '))

Vue.filter(
  'articleSizes',
  function (article) {
    const {sizes} = article
    switch (article.shape) {
      case 'линия':
        return `Длина ${sizes[0]}`
      case 'круг':
        return `Диаметр ${sizes[0]}`
      case 'прямоугольник':
        return sizes[0] + '×' + sizes[1]
      case 'параллелепипед':
        if (_.max(sizes) > (sizes[0] * 2)) {
          return sizes[1] + '×' + sizes[2]
        } else {
          return `Высота ${sizes[0]}`
        }
      case 'цилиндр':
        if (sizes[0] > (sizes[1] * 2)) {
          return `Диаметр ${sizes[0]}`
        } else {
          return `Высота ${sizes[1]}`
        }
    }
  }
)

Vue.filter('ceilToNearestHalf', num => Math.ceil(num * 2) / 2)

Vue.filter('timePeriod', function (period, showMinutes = true, longForm = false) {
    const s = showMinutes ? `${period[0]}:00` : period[0]
    const e = showMinutes ? `${period[1]}:00` : period[1]

    if (period[0] === period[1]) {
      return s
    }

    if (longForm) {
      return `с ${s} до ${e}`
    } else {
      return `${s}–${e}`
    }
  }
)

Vue.filter('spellTime', function (timeStr) {
    const time = Number(timeStr)
    const hours = Math.floor(time)
    const mins = time - hours
    const hourString = hours > 0 ? hours + ' ч' : ''
    const minString = Math.round(mins * 60) + ' мин'
    return hourString + ' ' + minString
  }
)

const MONTH_GENITIVE_NAMES = ['', 'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

Vue.filter('monthGenitiveName', monthNumber => MONTH_GENITIVE_NAMES[monthNumber])

Vue.filter('lineBreaksToCommas', str => _.replace(str, /(\n|,\ ?)+/g, ', '))
Vue.filter('newlinesToBrTags', str => str.replace(/\n/g, '<br/>'))