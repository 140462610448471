<template lang="pug">
  .content
    .page-heading
      h1.page-title.page-title_top
        | Регистрация
    .signup-container
      form-agv-details(:customer="newCustomer"
      @clearError="onClearSignupError"
      :errors="signupErrors"
      :country-code="renter.country"
      :is-in-aggregator-mode="false"
      :renter-genitive-name="renter.short_commercial_name_cases[1]")
      .personal-data-btn-container
        el-checkbox.personal-data-policy-checkbox.cc-personal-data-policy-checkbox(type="text" v-model="agreeToPrivacyPolicy" :class="{'el-checkbox_error': signupErrors.agreeToPrivacyPolicy}")
          | Я принимаю
          |
          a(@click="showPersonalDataPolicyModal = true" )
            | политику обработки персональных данных
      .register-btn-container
        el-button(type="success" @click="onTrySignup" :disabled="!agreeToPrivacyPolicy")
          | Зарегистрироваться
      el-dialog(v-if="showPersonalDataPolicyModal" :append-to-body="true" :title="`Политика обработки персональных данных ${renter.full_legal_name}`" width="660px" :visible.sync="showPersonalDataPolicyModal")
        rsv-personal-data-policy(:renter="renter")
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'

  import FormAgvDetails from 'Aggregator/pages/form-agv-details'
  import RsvPersonalDataPolicy from 'RenterSite/components/rsv-personal-data-policy'
  import {reachGoal} from 'Shared/lib/metrika'
  import {fbqWrapper} from "../../shared/lib/facebook";

  export default {
    name: 'page-rsv-signup',
    data() {
      return {
        prevRoute: null,
        showPersonalDataPolicyModal: false,
        agreeToPrivacyPolicy: true,
        signupErrors: {},
        newCustomer: {town_id: this.$store.state.town.current.id, is_professional: false, wants_to_receive_news: true},
        renter: this.$store.state.renter,
      }
    },
    components: {
      RsvPersonalDataPolicy,
      FormAgvDetails
    },
    metaInfo() {
      return {
        title: 'Регистрация'
      }
    },
    methods: {
      agreeToPrivacyPolicyHandler(value) {
        if (value) {
          this.signupErrors.agreeToPrivacyPolicy = false
        }
      },
      onTrySignup() {
        if (!this.agreeToPrivacyPolicy) {
          Vue.set(this.signupErrors, 'agreeToPrivacyPolicy', true)
          return
        }

        reachGoal('trySignup')

        axios.post(
          '/api/customers',
          {customer: this.newCustomer}
        ).then(({data: customer}) => {
          fbqWrapper('track', 'CompleteRegistration')
          this.$store.commit('customer/setCurrent', customer)
          this.$router.push('/order/new')
        }).catch(({response: {data: errors}}) => {
          Vue.set(this, 'signupErrors', errors)
        })
      },
      onClearSignupError() {
        this.signupErrors = {}
      },
    },
    watch: {
      agreeToPrivacyPolicy: 'agreeToPrivacyPolicyHandler',
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
      })
    },
  }
</script>

<style scoped>
  .personal-data-btn-container {
    margin-bottom: 40px;
    margin-left: 185px;
  }

  .signup-container {
    font-size: 14px;
  }
</style>
