import $ from 'jquery'
import postToParent from "RenterSite/lib/postToParent";

export default function () {
  const topMostError = $('.error:visible, .warning:visible, .not-enough')[0]
  if (!topMostError) {
    console.log('scrollToTopmostError called, but no errors on page')
    return
  }

  postToParent({scrollToY: topMostError.offsetTop})

  return topMostError.scrollIntoView({block: "center", behavior: "smooth"})
}
