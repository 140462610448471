<template lang="pug">
  .agv-star-rating
    span.rating-number(v-if="!hideNumber && rating > 0")
      | {{ formatRating(rating) }}
    component.star(:is="starComponentFor(portion)" v-for="(portion, index) in starFillPortionsFor(rating)" :key="index")
</template>

<script>
  import _ from 'lodash'

  import Star0 from '-!vue-svg-loader!../sprites/star-0.svg'
  import Star25 from '-!vue-svg-loader!../sprites/star-25.svg'
  import Star50 from '-!vue-svg-loader!../sprites/star-50.svg'
  import Star75 from '-!vue-svg-loader!../sprites/star-75.svg'
  import Star100 from '-!vue-svg-loader!../sprites/star-100.svg'

  import numeral from 'numeral'

  const LOWER_RATING_BOUNDS = [0, 1, 2, 3, 4]
  const COMPONENTS = [Star0, Star25, Star50, Star75, Star100]

  export default {
    name: 'agv-star-rating',
    props: {
      rating: Number,
      hideNumber: Boolean
    },
    methods: {
      starFillPortionsFor(rating) {
        return _.map(LOWER_RATING_BOUNDS, n => Math.min(1, Math.max(0, rating - n)))
      },
      starComponentFor(portion) {
        return COMPONENTS[Math.round(portion * 4)]
      },
      formatRating(rating) {
        return numeral(rating).format('0.0')
      }
    }
  }
</script>

<style scoped>
  .star {
    margin-right: 1px;
    display: inline-block;
    position: relative;
    top: 2px;
  }

  .rating-number {
    font-weight: 700;
    color: #F6AC18;
    margin-right: 5px;
  }
</style>
