export type RenterWithUnloadingLoading = {
  unloading_and_loading_cost?: number,
  manual_lifting_cost_per_floor?: number,
  carrying_free_meters?: number,
  carrying_additional_cost_per_meter?: number,
};

export type DeliveryWithUnloadingLoading = {
  carrying_distance: number,
  manual_lifting_floors: number,
};

export function calculateLoadingUnloading(renter: RenterWithUnloadingLoading, delivery: DeliveryWithUnloadingLoading, mass: number) {
  if (!delivery.is_to_hall || renter.unloading_and_loading_cost == null) {
    return 0;
  }

  const cargoUnits = Math.ceil(mass / 15.0);

  return Math.ceil(
    cargoUnits * (
      unloadingAndLoadingCost(renter) +
      carryingCost(renter, delivery) +
      manualLiftingCost(renter, delivery)
    )
  );
}

function unloadingAndLoadingCost(renter) {
  if (renter.unloading_and_loading_cost == null) {
    return 0;
  }
  return renter.unloading_and_loading_cost;
}

function manualLiftingCost(renter, delivery) {
  if (delivery.manual_lifting_floors == null || renter.manual_lifting_cost_per_floor == null) {
    return 0;
  }

  return delivery.manual_lifting_floors * renter.manual_lifting_cost_per_floor;
}

function carryingCost(renter, delivery) {
  if (delivery.carrying_distance == null || renter.carrying_free_meters == null || renter.carrying_additional_cost_per_meter == null) {
    return 0;
  }

  const carryingMeters = Math.max(delivery.carrying_distance - renter.carrying_free_meters, 0);
  return carryingMeters * renter.carrying_additional_cost_per_meter;
}
