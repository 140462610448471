<template lang="pug">
  .rsv-personal-data-policy
    p.c1
      span.c0 Дата публикации: {{publicationDate | date("dd.MM.yy")}}
    p.c1.c4
      span.c0
    p.c1
      span
        | Настоящая Политика обработки персональных данных (далее — Политика) определяет общие принципы и порядок обработки персональных данных и меры по обеспечению их безопасности при
        | использовании Сайта
      span.c0  «{{currentDomainName}}» и размещении заказов на нем.
    p.c1.c4
      span.c0
    p.c1
      span.c2 Термины и определения
    p.c1
      span В Политике
      span.c0  используются следующие термины в указанном ниже значении:
    ol.c8.lst-kix_w6ri3sa3dy1i-0.start(start="1")
      li.c1.c5
        span.c0
          | Автоматизированная обработка персональных данных — обработка Персональных данных с помощью средств вычислительной техники.
      li.c1.c5
        span.c0
          | Блокирование персональных данных — временное прекращение обработки Персональных данных (за исключением случаев, когда обработка необходима для уточнения Персональных данных).
      li.c1.c5
        span.c0
          | Данные — данные, относящиеся к Пользователю, которые в соответствии с Законодательством не относятся к категории Персональных данных.
      li.c1.c5
        span.c0
          | Доступ к персональным данным — ознакомление определенных лиц с Персональными данными, обрабатываемыми Оператором, при условии сохранения конфиденциальности этих сведений.
      li.c1.c5
        span.c0 Законодательство — действующее законодательство Российской Федерации.
      li.c1.c5
        span.c0
          | Информационная система персональных данных — совокупность содержащихся в базах данных Персональных данных и обеспечивающих их обработку информационных технологий и технических средств.
      li.c1.c5
        span.c0
          | Конфиденциальность персональных данных — обязанность лиц, получивших доступ к Персональным данным, не раскрывать третьим лицам и не распространять Персональные данные без согласия Пользователя Персональных данных, если иное не предусмотрено федеральным законодательством.
      li.c1.c5
        span.c0
          | Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с Персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение Персональных данных.
      li.c1.c5
        span.c0
          | Оператор — {{renter.full_legal_name}} (ИНН {{renter.tin}}), самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку Персональных данных, а также определяющий цели обработки Персональных данных, состав Персональных данных, подлежащих обработке, действия (операции), совершаемые с Персональными данными.
      li.c1.c5
        span.c0
          | Персональные данные — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (Пользователю).
      li.c1.c5
        span.c0
          | Пользователь — лицо, вступившее с Оператором в договорные отношения и зарегистрировавшееся на Сайте.
      li.c1.c5
        span.c0
          | Предоставление персональных данных — действия, направленные на раскрытие Персональных данных определенному лицу или определенному кругу лиц.
      li.c1.c5
        span.c0
          | Субъект персональных данных — физическое лицо, к которому относятся Персональные данные.
      li.c1.c5
        span.c0
          | Третьи лица — клиенты (партнеры) Пользователя, чьи данные обрабатываются в CRM-системе.
      li.c1.c5
        span.c0
          | Уничтожение персональных данных — действия, в результате которых невозможно восстановить содержание Персональных данных в информационной системе Персональных данных и (или) в результате которых уничтожаются материальные носители Персональных данных.
      li.c1.c5
        span.c0
          | Cайт Оператора (Сайт) — сайт, расположенный в сети Интернет на доменном имени {{currentDomainName}}.
    p.c1
      span.c0
        | Иные термины толкуются в соответствии с Лицензионным соглашением (публичной офертой) и Законодательством.
    p.c1.c4
      span.c0
    p.c1
      span.c2 1. ОБЩИЕ ПОЛОЖЕНИЯ
    p.c1
      span
        | 1.1. Настоящая Политика в отношении обработки Персональных данных и Данных разработана в соответствии с положениями Федерального закона от 27.07.2006 № 152
      span  
      span.c0
        | ФЗ «О персональных данных» (с изменениями и дополнениями), другими законодательными и нормативными правовыми актами и определяет порядок работы с Персональными данными и Данными Пользователей и (или) передаваемых Пользователями и требования к обеспечению их безопасности.
    p.c1
      span.c0
        | 1.2. Мероприятия по обеспечению безопасности Персональных данных являются составной частью деятельности Оператора.
    p.c1.c4
      span.c0
    p.c1
      span.c2 2. ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
    p.c1
      span.c0
        | 2.1. Обработка Персональных данных (Данных) Оператором осуществляется в соответствии со следующими принципами:
    p.c1
      span.c0
        | 2.1.1. Законность и справедливая основа обработки Персональных данных (Данных). Оператор принимает все необходимые меры по выполнению требований Законодательства, не обрабатывает Персональные данные в случаях, когда это не допускается Законодательством, не использует Персональные данные во вред Пользователю.
    p.c1
      span.c0
        | 2.1.2. Обработка только тех Персональных данных (Данных), которые отвечают заранее объявленным целям их обработки. Соответствие содержания и объема обрабатываемых Персональных данных заявленным целям обработки. Недопущение обработки Персональных данных, несовместимых с целями сбора Персональных данных, а также избыточных по отношению к заявленным целям их обработки.
    p.c1
      span.c0
        | Оператор обрабатывает Персональные данные исключительно в целях исполнения договорных обязательств перед Пользователем.
    p.c1
      span.c0
        | 2.1.3. Обеспечение точности, достаточности и актуальности Персональных данных (Данных) по отношению к целям обработки Персональных данных (Данных). Оператор принимает все разумные меры по поддержке актуальности обрабатываемых Персональных данных, включая, но не ограничиваясь реализацией права каждого Субъекта получать для ознакомления свои Персональные данные и требовать от Оператора их уточнения, блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленных выше целей обработки.
    p.c1
      span.c0
        | 2.1.4. Хранение Персональных данных (Данных) в форме, позволяющей определить Пользователя Персональных данных (Данных), не дольше, чем этого требуют цели обработки Персональных данных, если срок хранения Персональных данных не установлен федеральным законодательством, договором, стороной которого или выгодоприобретателем по которому является Пользователь Персональных данных.
    p.c1
      span.c0
        | 2.1.5. Недопустимость объединения созданных для несовместимых между собой целей баз данных Информационных систем Персональных данных (Данных).
    p.c1
      span.c0
        | 2.1.6. Оператор использует Данные, Персональные данные Третьих лиц, внесенные Пользователем исключительно для выполнения обязательств перед Пользователями.
    p.c1.c4
      span.c0
    p.c1
      span.c2 3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
    p.c1
      span.c0 3.1. Обработка Персональных данных Оператором допускается в следующих случаях:
    p.c1
      span.c0 3.1.1. При наличии согласия Пользователя на обработку его Персональных данных.
    p.c1
      span.c0
        | 3.1.2. При передаче Персональных данных Субъекта персональных данных Пользователем. Пользователь гарантирует, что предварительно получил от Субъекта персональных данных согласие на передачу данных Оператору.
    p.c1
      span.c0
        | 3.1.3. Персональные данные подлежат опубликованию или обязательному раскрытию в соответствии с Законодательством.
    p.c1
      span.c0
        | 3.2. Оператор не раскрывает третьим лицам и не распространяет Персональные данные без согласия Пользователя, если иное не предусмотрено Законодательством.
    p.c1
      span.c0
        | 3.3. Оператор не обрабатывает Персональные данные, относящиеся к специальным категориям и касающиеся расовой и национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни Субъекта персональных данных, о членстве Субъекта персональных данных в общественных объединениях за исключением случаев, прямо предусмотренных Законодательством.
    p.c1
      span.c0
        | 3.4. Оператор не осуществляет Трансграничную передачу Персональных данных Пользователей за исключением случаев, прямо предусмотренных Законодательством.
    p.c1
      span.c0
        | 3.5. Оператор не принимает решений, порождающих юридические последствия в отношении Пользователя или иным образом затрагивающих права и законные интересы Пользователей, на основании исключительно автоматизированной обработки Персональных данных. Данные, имеющие юридические последствия или затрагивающие права и законные интересы Пользователей, подлежат перед их использованием проверке со стороны уполномоченных работников Оператора.
    p.c1.c4
      span.c0
    p.c1
      span.c2 4. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ И ИНЫХ ДАННЫХ
    p.c1
      span.c0
        | 4.1. Оператор собирает и хранит только те Персональные данные (Данные), которые необходимы для размещения заказов и слежения за их выполнением.
    p.c1
      span.c0
        | 4.2. Перечень Персональных данных Пользователя (физического лица), на обработку которых дается согласие:
    ol.c8.lst-kix_uyr89u9nahk8-0.start(start="1")
      li.c1.c5
        span фамилия
        span.c0 ;
      li.c1.c5
        span.c0 имя
      li.c1.c5
        span.c0 контактный телефон;
      li.c1.c5
        span.c0 адрес электронной почты.
    p.c1
      span.c0
        | 4.3. Персональные данные (и иные Данные, переданные Пользователем) могут использоваться в следующих целях:
    p.c1
      span.c0 4.3.1. размещение заказа;
    p.c1
      span.c0 4.3.2. идентификация Пользователя;
    p.c1
      span.c0 4.3.3. взаимодействие с Пользователем;
    p.c1
      span.c0 4.3.4. направление Пользователю рекламных материалов, информации и запросов;
    p.c1
      span.c0 4.3.5. проведение статистических и иных исследований.
    p.c1
      span.c0
        | 4.4. Оператор обязуется использовать Персональные данные в соответствии с Федеральным законом «О персональных данных» No 152 ФЗ от 27 июля 2006 г. и внутренними документами Оператора.
    p.c1
      span.c0
        | 4.5. В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме случаев, когда указанные данные являются общедоступными.
    p.c1
      span.c0
        | 4.6. Оператор имеет право сохранять архивную копию Персональных данных и иных Данных
    p.c1
      span.c0
        | 4.7. Оператор имеет право передавать Персональные данные и иные Данные Пользователя без согласия Пользователя следующим лицам:
    p.c1
      span.c0
        | 4.7.1. государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по их мотивированному запросу;
    p.c1
      span.c0
        | 4.7.2. партнерам Оператора c целью выполнения договорных обязательств перед Пользователем;
    p.c1
      span.c0 4.7.3. в иных случаях, прямо предусмотренных Законодательством.
    p.c1
      span.c0
        | 4.8. Оператор имеет право передавать Персональные данные и иные Данные третьим лицам, не указанным в п. 4.7 настоящей Политики, в следующих случаях:
    p.c1
      span.c0 4.8.1. пользователь выразил свое согласие на такие действия;
    p.c1
      span.c0 4.8.2. передача необходима в рамках использования Пользователем Сайта;
    p.c1
      span.c0
        | 4.8.3. передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики;
    p.c1
      span.c0
        | 4.9. Оператор осуществляет автоматизированную обработку Персональных данных и иных Данных.
    p.c1
      span.c0
        | 4.10. Доступ к Информационным системам, содержащим Персональные данные, обеспечивается системой паролей. Пароли устанавливаются уполномоченными сотрудниками Оператора и индивидуально сообщаются работникам Оператора, имеющим доступ к Персональным данным (Данным).
    p.c1
      span.c0
        | 4.11. Пользователь по собственному желанию имеет право предоставить службе технической поддержки Оператора доступ к аккаунту Пользователя на Сайте с целью технической поддержки. Доступ осуществляется с использованием логина и пароля. При осуществлении доступа Оператор получает право просмотра Данных (Персональных данных) без права копирования (изменения).
    p.c1
      span.c0
        | 4.12. Пользователь подтверждает, что данные Третьих лиц вносятся в Систему с предварительным получением согласия от Третьих лиц.
    p.c1.c4
      span.c0
    p.c1
      span.c2 5. ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
    p.c1
      span.c0
        | 5.1. Пользователь может в любой момент изменить (обновить, дополнить) Персональные данные посредством Личного кабинета либо путем направления письменного заявления Оператору.
    p.c1
      span.c0
        | 5.2. Пользователь в любой момент имеет право удалить Персональные данные (Данные), используя функциональность Личного кабинета.
    p.c1.c4
      span.c0
    p.c1
      span.c2 6. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ
    p.c1
      span.c0
        | 6.1. Оператор обеспечивает конфиденциальность обрабатываемых им Персональных данных (Данных) в порядке, предусмотренном Законодательством. Обеспечение конфиденциальности не требуется в отношении:
    p.c1
      span.c0 6.1.1. Персональных данных после их обезличивания;
    p.c1
      span.c0
        | 6.1.2. Персональных данных, доступ неограниченного круга лиц к которым предоставлен Пользователем либо по его просьбе (далее — Персональные данные, сделанные общедоступными Пользователем);
    p.c1
      span.c0
        | 6.1.3. Персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с Законодательством.
    p.c1.c4
      span.c0
    p.c1
      span.c2 7. СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
    p.c1
      span.c0
        | 7.1. Пользователь принимает решение о предоставлении своих Персональных данных Оператору и дает согласие на их обработку свободно, своей волей и в своем интересе.
    p.c1
      span.c0
        | 7.2. Персональные данные лиц, вступивших в договорные обязательства с Оператором, содержащиеся в единых государственных реестрах юридических лиц и индивидуальных предпринимателей, являются открытыми и общедоступными, за исключением сведений о номере, дате выдачи и органе, выдавшем документ, удостоверяющий личность физического лица. Охраны их конфиденциальности и согласия Пользователей на обработку не требуется.
    p.c1.c4
      span.c0
    p.c1
      span.c2 8. ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
    p.c1
      span.c0
        | 8.1. Пользователь имеет право на получение информации, касающейся обработки его Персональных данных (Данных). Пользователь вправе требовать от Оператора уточнения его Персональных данных, их блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
    p.c1
      span.c0
        | 8.2. Если Пользователь считает, что Оператор осуществляет обработку его Персональных данных с нарушением требований Законодательства или иным образом нарушает его права и свободы, Пользователь вправе обжаловать действия или бездействие Оператора в уполномоченном органе по защите прав субъектов Персональных данных или в судебном порядке.
    p.c1
      span.c0
        | 8.3. Пользователь имеет право на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке.
    p.c1.c4
      span.c0
    p.c1
      span.c2 9. ИНЫЕ ПОЛОЖЕНИЯ
    p.c1
      span.c0
        | 9.1. К настоящей Политике и отношениям между Пользователем и Оператором, возникающим в связи с применением Политики, подлежит к применению право Российской Федерации.
    p.c1
      span.c0
        | 9.2. Все возможные споры подлежат разрешению в соответствии с Законодательством. Перед обращением в суд Пользователь должен соблюсти обязательный досудебный порядок и направить Оператору соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.
    p.c1
      span.c0
        | 9.3. Если по тем или иным причинам одно или несколько положений Политики будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Политики конфиденциальности.
    p.c1
      span.c0
        | 9.4. Оператор имеет право в любой момент изменять настоящую Политику (полностью или в части) в одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают в силу на следующий день после размещения на Сайте.
    p.c1
      span.c0
        | 9.5. Пользователь обязуется самостоятельно следить за изменениями Политики конфиденциальности путем ознакомления с актуальной редакцией.
    p.c1.c4
      span.c0
    p.c1
      span.c2 10. КОНТАКТНАЯ ИНФОРМАЦИЯ ОПЕРАТОРА
    p.c6
      span.c0 Организация: {{renter.full_legal_name}}
    p.c3
      span.c0 ИНН/КПП: {{renter.tin}}/{{renter.rrc}}
    p.c3
      span.c0 Банк: {{renter.bank_name}}
    p.c3
      span.c0 Расчетный счет: {{renter.bank_account}}
    p.c3
      span.c0 БИК: {{renter.bic}}
    p.c3
      span.c0 Корреспондентский счет: {{renter.correspondent_account}}
    p.c3
      span.c0 Юридический адрес: {{renter.legal_address}}
    p.c3
      span.c0 Почтовый адрес: {{renter.mail_address}}
    p.c3(v-if="renter.form_of_incorporation !== 'ИП'")
      span.c0 {{renter.ceo_name}}
    p.c4.c7
      span.c0

</template>

<script>
  export default {
    computed: {
      currentDomainName() {
        return location.host
      },
      publicationDate() {
        return new Date(this.renter.updated_at_date)
      }
    },
    props: {
      renter: Object
    },
    name: 'rsv-personal-data-policy'
  }
</script>

<style scoped>
  .c1, .c6, .c3 {
    margin-bottom: 20px;
    line-height: 1.5em;
  }

  ol {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 24px;
  }
</style>
