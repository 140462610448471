import axios from 'axios'

export default {
  get isEmbedded() {
    return this.isAdmin
  },
  get isAdmin() {
    return !!this._adminCustomerId
  },
  get customerIdParamName() {
    if (this.isAdmin) {
      return 'as_customer_id'
    }
    return ''
  },
  get customerId() {
    return this._adminCustomerId
  },
  updateUrlParams() {
    let requestParams = {}

    requestParams[this.customerIdParamName] = this.customerId

    axios.defaults.params = requestParams
  },
  set adminCustomerId(customerId) {
    this._adminCustomerId = customerId
    this.updateUrlParams()
  },
  get adminCustomerId() {
    return this._adminCustomerId
  },
  urlWithCustomerId(url) {
    if (!this.isEmbedded) {
      return url
    }

    let paramSeparator = url.match(/\?/) ? '&' : '?'

    return url + paramSeparator + this.customerIdParamName + '=' + this.customerId
  }
}
