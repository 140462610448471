import { render, staticRenderFns } from "./agv-article-meta.vue?vue&type=template&id=0ec8a91a&scoped=true&lang=pug&"
import script from "./agv-article-meta.vue?vue&type=script&lang=js&"
export * from "./agv-article-meta.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec8a91a",
  null
  
)

export default component.exports