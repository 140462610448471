<template lang="pug">
  div
    .form-section
      .radio-group
        span(v-if="toHallIncluded")
          | входит в стоимость
        el-radio-group(v-else v-model="deliveryDetails.is_to_hall" @change="onIsDeliveryToHallChange")
          el-radio.cc-delivery-is-doorstep-false(:label="false" :disabled="isToHallMandatory")
            |  не нужна
          el-radio.cc-delivery-is-doorstep-true(:label="true")
            |  нужна
    .form-section(v-if="(deliveryDetails.is_to_hall || toHallIncluded)  && shouldShowCarryingSection")
      div(v-if="renter.carrying_free_meters === 0")
        .label-top
          | Примерное расстояние от парковки до здания, м:
        |
        el-input.number-input.cc-carrying-distance-input(size="small" type="text" v-model="deliveryDetails.carrying_distance" maxlength="4" :class="{error: errors.carrying_distance}" placeholder="X")
      div(v-else)
        .label-top
          | Расстояние от парковки до здания
        el-radio-group.cc-require-carrying(v-model="carryingOutsideFreeRange" @change="onCarryingOutsideFreeRangeChange")
          el-radio.cc-require-carrying-false(:label="false")
            | в пределах {{renter.carrying_free_meters}} м
          el-radio.cc-require-carrying-true(:label="true")
            | больше {{renter.carrying_free_meters}} м
        div(v-if="carryingOutsideFreeRange")
          | Примерное расстояние, м:
          |
          el-input.number-input.cc-carrying-distance-input(size="small" type="text" v-model="deliveryDetails.carrying_distance" maxlength="4" :class="{error: errors.carrying_distance}" placeholder="X")
    .form-section(v-if="(deliveryDetails.is_to_hall || toHallIncluded) && shouldShowManualLiftingSection")
      .label-top
        | Лифт
      div
        el-radio-group(v-model="deliveryDetails.isElevatorPresent" @change="onIsElevatorPresentChange")
          el-radio.cc-elevator-present(:label="true")
            | Есть
          el-radio.cc-elevator-absent(:label="false")
            | Нет
        div(v-if="!deliveryDetails.isElevatorPresent")
          | Число этажей ручного подъема:
          |
          el-input.number-input.cc-manual-lifting-floors-input(size="small" type="text" v-model="deliveryDetails.manual_lifting_floors" maxlength="4" :class="{error: errors.manual_lifting_floors}" placeholder="X")
    div(v-if="deliveryDetails.is_to_hall && !shouldShowCarryingSection")
      | Длинные проносы — входят в стоимость
    div(v-if="deliveryDetails.is_to_hall && !shouldShowManualLiftingSection")
      | Подъемы без лифта — входят в стоимость
</template>

<script>
export default {
  name: "order-delivery-details-unloading",
  data() {
    return {
      carryingOutsideFreeRange: null,
    };
  },
  props: {
    deliveryDetails: {
      type: Object,
      required: true
    },
    isToHallMandatory: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    renter: {
      type: Object,
      required: true
    }
  },
  computed: {
    toHallIncluded() {
      return this.renter.unloading_and_loading_cost === 0
    },
    shouldShowCarryingSection() {
      return (this.renter.carrying_free_meters !== null) && (this.renter.carrying_additional_cost_per_meter !== null)
    },
    shouldShowManualLiftingSection() {
      return (this.renter.manual_lifting_cost_per_floor !== null)
    }
  },
  methods: {
    updateInternalState() {
      this.carryingOutsideFreeRange = (this.deliveryDetails.carrying_distance || 0) > this.renter.carrying_free_meters;
      this.deliveryDetails.isElevatorPresent = this.deliveryDetails.manual_lifting_floors === null;
    },
    onIsDeliveryToHallChange(val) {
      if (!val) {
        this.deliveryDetails.carrying_distance = null;
        this.deliveryDetails.manual_lifting_floors = null;
      }
      this.updateInternalState();
    },
    onCarryingOutsideFreeRangeChange(val) {
      if (!val) {
        this.deliveryDetails.carrying_distance = null;
      }
    },
    onIsElevatorPresentChange(val) {
      if (val === true) {
        this.deliveryDetails.manual_lifting_floors = null;
      }
    }
  },
  created() {
    this.updateInternalState();
  }
}
</script>

<style scoped>
.el-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.label-top {
  margin-bottom: 10px;
}

input {
  flex-grow: 1;
  outline: none;
  min-width: 120px;
  border: none;
  background: #ffffff;
  color: #4A90E2;
  font-size: inherit;
  line-height: 15px;
  height: 15px;
  padding: 0;
}

input::placeholder {
  color: #7dabe3;
}

.form-section + .form-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.number-input {
  width: 70px;
}
</style>