<template lang="pug">
  svg(viewBox='0 0 23 28' xmlns='http://www.w3.org/2000/svg')
    text.quantity(v-if='quantity' text-anchor="middle" x="11" y="22")
      | {{quantity}}
    path(d='M22.615 26.918L21.231 5.809a.883.883 0 0 0-.881-.825h-3.865v-.568A4.42 4.42 0 0 0 12.07 0h-1.52a4.42 4.42 0 0 0-4.415 4.416v.568H2.271a.883.883 0 0 0-.881.825L.002 26.97a.883.883 0 0 0 .881.941H21.74a.883.883 0 0 0 .876-.992zM7.901 4.416a2.652 2.652 0 0 1 2.65-2.65h1.519a2.652 2.652 0 0 1 2.65 2.65v.568H7.9v-.568zM1.826 26.144L3.098 6.75h3.037v.788a1.944 1.944 0 0 0-1.06 1.73c0 1.072.872 1.943 1.943 1.943a1.945 1.945 0 0 0 1.943-1.943c0-.753-.431-1.407-1.06-1.73V6.75h6.818v.788a1.944 1.944 0 0 0-1.06 1.73c0 1.072.872 1.943 1.943 1.943a1.945 1.945 0 0 0 1.943-1.943c0-.753-.431-1.407-1.06-1.73V6.75h3.037l1.272 19.394H1.826z' fill='#4A4A4A' fill-rule='evenodd')
</template>

<script>
  export default {
    name: 'agv-shopping-bag',
    props: {
      quantity: Number
    }
  }
</script>

<style scoped>
  .quantity {
    font-size: 12px;
    fill: #F6A623;
  }

  svg {
    width: 23px;
    height: 28px;
  }
</style>
