<template lang="pug">
  el-dialog.cc-sbol-payment-modal(title="Оплата переводом на карту" width="500px" :visible="true" @close="$emit('close')")
    agv-sbol-payment-help(:amount="amount" :receiver-full-name="renter.sbol_name" :payer="customer" :card="renter.sbol_card", :renter="renter")
</template>

<script>
  import AgvSbolPaymentHelp from 'Aggregator/components/agv-sbol-payment-help'
  export default {
    name: "sbol-payment-modal",
    components: {AgvSbolPaymentHelp},
    props: {
      amount: Number,
      renter: Object,
      customer: Object,
      visible: Boolean
    }
  }
</script>

<style scoped>

</style>
