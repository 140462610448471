<template lang="pug">
  el-dialog(width="420px" :visible="true" @close="$emit('close')")
    p
      | Заявка на возврат принята.
    p
      | Оператор {{renter.short_commercial_name_cases[1]}} свяжется с вами в течение одного рабочего дня.
</template>

<script>
  export default {
    name: "surplus-retrieval-modal",
    props: {
      renter: Object
    }
  }
</script>

<style scoped>

</style>
