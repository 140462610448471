export default class ArticleSize {
  constructor(article) {
    this.article = article;
  }

  get value() {
    if (this._value) {
      return this._value;
    }

    this._value = (function(a) {
      switch (a.shape) {
        case 'линия':
          return a.sizes[0];
        case 'круг':
          return (Math.pow(a.sizes[0] / 2, 2) * 3.14) / 2;
        case 'прямоугольник':
          return (a.sizes[0] * a.sizes[1]) / 2;
        case 'параллелепипед':
          return ((a.sizes[1] / 2) + (a.sizes[2] / 2)) * a.sizes[0];
        case 'цилиндр':
          return a.sizes[0] * a.sizes[1];
      }
    })(this.article);

    return this._value;
  }
}
