<template lang="pug">
  .color-container(:class="{'color-inactive': !isActive, 'color-container_compact': isCompact}"
  @click="$emit('click', $event)")
    .color(:data-color='color' :class="{'color_compact': isCompact}")
      check-mark.check-mark(v-if='isChecked')
      cross.cross(v-if='!isActive')
      .color__name(:class="{'color__name_compact': isCompact}")
        | {{colorName}}
</template>

<script>
  import CheckMark from 'Shared/sprites/check.svg'
  import Cross from 'Aggregator/sprites/cross.svg'

  export default {
    components: {CheckMark, Cross},
    name: 'agv-color-btn',
    props: {
      color: String,
      colorName: String,
      isChecked: Boolean,
      isActive: Boolean,
      isCompact: Boolean
    }
  }
</script>

<style scoped>
  .color {
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    width: 22px;
    height: 22px;
    position: relative;
  }

  .color.color_compact {
    position: absolute;
    top: 5px;
  }

  .color .color__name {
    color: #000;
    font-size: 13px;
    position: absolute;
    left: 30px;
    top: 3px;
  }

  .color .color__name.color__name_compact {
    color: #9b9b9b;
    font-size: 10px;
    transform: translateX(-50%);
    top: 23px;
    left: 50%;
    display: none;
  }

  @media (pointer: fine) {
    .color:hover .color__name {
      display: block;
    }
  }

  .color-inactive {
    opacity: 0.2;
    pointer-events: none;
  }

  .color-container {
    height: 34px;
    cursor: pointer;
  }

  .color-container.color-container_compact {
    display: inline-block;
    position: relative;
    width: 22px;
    margin-right: 15px;
    height: 42px;
  }

  .check-mark {
    position: absolute;
    top: 7px;
    left: 6px;
  }

  .check-mark {
    fill: white;
  }

  .color[data-color='white'] .check-mark, .color[data-color='yellow'] .check-mark, .color[data-color='transparent'] .check-mark {
    fill: black;
  }
</style>

<style src='../../shared/styles/colors.css' lang="css">
/* back to top */
</style>
