<template lang="pug">
  .order-line-item.cc-order-line-item
    .photo-cell
      i.el-icon-picture(v-if="upToDateArticle.is_infringing")
      img.article-photo(v-else :src="photoUrlFor(upToDateArticle)")
    .name-cell
      .name(slot="reference")
        router-link.article-link(:to="getArticleUrl(lineItem.article_id, renter.id)")
          | {{articleNameWithSubstitutions}}
      .btn-remove(v-if="isEditing")
        el-button(type="text" @click="$emit('remove', lineItem.article_id)")
          | Удалить изделие
    .price-cell.cc-price
      | {{price | money(renter.country)}}
    .quantity-cell.cc-quantity-cell
      el-input.cc-order-line-item__quantity-input(v-if="isEditing"
      maxlength="4"
      :class="{'not-enough': !!notEnough, error: !!error}"
      @focus="clearNotEnoughError"
      @blur="onBlur"
      type="text"
      v-model="internalValue"
      style="width: 70px")
      span(v-else)
        | {{lineItem.quantity}}
      .pcs
        | шт.
      .order-line-item_available.cc-available(v-if="isEditing" :class="{'not-enough': !!notEnough}")
        | Доступно {{available}} шт.
    .total-price-cell.cc-total-price
      | {{price * lineItem.quantity | money(renter.country)}}
</template>

<script>
  import {getArticlePrice} from 'Shared/order'
  import {getNameWithSubstitutions} from "Shared/articleNames";

  export default {
    name: 'order-line-item',
    data() {
      return {
        internalValue: null
      }
    },
    props: {
      lineItem: Object,
      branch: Object,
      renter: Object,
      originalRenterId: Number,
      available: Number,
      errors: Object,
      errorText: String,
      getArticle: Function,
      getArticleUrl: Function,
      photoUrlFor: Function,
      isEditing: Boolean
    },
    computed: {
      showError: {
        get() {
          return !!this.errorText
        },
        set(value) {
        },
      },
      error() {
        if (!this.errors) {
          return null
        }
        return _.find(this.errors['line_items.not_enough'], e => e.article_id == this.lineItem.article_id)
      },
      notEnough() {
        if (!this.error) {
          return Math.max(0, this.lineItem.quantity - this.available)
        }
        return this.error.quantity
      },
      upToDateArticle() {
        return this.getArticle(this.lineItem.article_id, this.originalRenterId)
      },
      articleNameWithSubstitutions() {
        const nameSubstitutions = this.$store.getters.getArticleNameSubstitutions(this.renter.id)
        return getNameWithSubstitutions(this.upToDateArticle.name, nameSubstitutions)
      },
      price() {
        return getArticlePrice(this.lineItem.article, this.branch)
      },
      min() {
        return Math.min(this.available, 1)
      }
    },
    methods: {
      clearNotEnoughError() {
        if (this.error) {
          this.$store.commit(
            'order/clearNotEnoughError',
            {
              renterId: this.originalRenterId,
              articleId: this.error.article_id
            }
          )
        }
      },
      onBlur() {
        this.syncQuantity(this.lineItem.quantity)
      },
      syncQuantity(value) {
        this.internalValue = value
      },
      handleQuantityChange(value) {
        if (value !== '') {
          const valueAsNumber = parseInt(value) || 0
          this.internalValue = Math.max(this.min, valueAsNumber)
          this.$emit('setQuantity', this.lineItem.article_id, this.internalValue)
        }
      }
    },
    watch: {
      'lineItem.quantity': [
        {
          handler: 'syncQuantity',
          immediate: true
        }
      ],
      'internalValue': 'handleQuantityChange'
    }
  }
</script>

<style scoped>
  .order-line-item {
    display: flex;
    align-items: baseline;
    height: 135px;
  }

  .el-input.not-enough >>> input {
    border: 2px solid #ff944d;
    /*background: #ffd699;*/
    color: black;
  }

  .el-badge >>> .el-badge__content {
    background: #ff944d !important;
    color: white;
  }

  .order-line-item > div {
    padding-left: 20px;
  }

  .photo-cell {
    width: 100px;
    align-self: center;
  }

  .article-photo {
    max-width: 70px;
    max-height: 70px;
  }

  .article-link {
    color: black;
  }

  .name-cell {
    flex-grow: 1;
    margin-right: 20px;
  }

  .name {
    font-weight: bold;
  }

  .price-cell {
    width: 100px;
  }

  .total-price-cell {
    font-size: 18px;
    text-align: right;
  }

  .pcs {
    display: inline-block;
    margin-left: 10px;
  }

  .order-line-item_available {
    margin-top: 10px;
    font-size: 12px;
    color: gray;
  }

  .order-line-item_available.not-enough {
    color: #ff944d;
  }

  .quantity-cell {
    background: white;
    width: 130px;
  }
</style>
