<template lang="pug">
  .form-agv-bank-details
    el-form(ref="form" :model="details" label-width="90px" label-position="top" size="small"
      @submit.native.prevent="onSubmit" :show-message="false" :hide-required-asterisk="true")
      el-form-item(prop="legal_name")
        el-input.cc-legal-name-input(v-model="details.legal_name" placeholder="Наименование юрлица или ИП" name="legal-name")
      el-form-item(prop="address")
        el-input.cc-address-input(v-model="details.address" placeholder="Адрес" name="address")
      el-form-item(prop="tin" style="display: inline-block; width: 160px; margin-right: 20px;")
        el-input.cc-tin-input(v-model="details.tin" placeholder="ИНН" name="tin" style="width: 160px")
      el-form-item(prop="rrc" style="display: inline-block; width: 160px;")
        el-input.cc-rrc-input(v-model="details.rrc" placeholder="КПП" name="rrc" style="width: 160px")
</template>

<script>
  export default {
    name: 'form-agv-bank-details',
    props: {
      details: Object
    },
    watch: {
      'details.legal_name': 'clearError',
      'details.tin': 'clearError',
      'details.rrc': 'clearError',
      'details.address': 'clearError'
    },
    methods: {
      clearError() {
        this.$emit('clearError')
      },
      onSubmit() {
        this.$emit('submit')
      }
    }
  }
</script>

<style scoped>
</style>
