<template lang="pug">
  ul(:class="{'thumbnail-container': showThumbnails}")
    component(:is="showThumbnails ? 'agv-tag-li-image' : 'agv-tag-li-checkbox'"
      v-for="tag in tags" :key="tag.id"
      v-on:click.native="$emit('toggle', tag)"
      :name="tag.name"
      :active="isTagActive(tag)"
      :checked="isTagChecked(tag)"
      :image-url="tag.image_url")
</template>

<script>
  import _ from 'lodash'

  import AgvTagLiImage from 'Aggregator/components/agv-tag-li-image'
  import AgvTagLiCheckbox from 'Aggregator/components/agv-tag-li-checkbox'

  export default {
    name: 'agv-tag-ul',
    props: {
      tags: {
        type: Array,
        required: true
      },
      activeTagIds: {
        type: Object,
        required: true
      },
      checkedTagIds: {
        type: Object,
        required: true
      },
      showThumbnails: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      isTagActive(tag) {
        return !!(this.activeTagIds[tag.id])
      },
      isTagChecked(tag) {
        return !!(this.checkedTagIds[tag.id])
      },
    },
    components: {AgvTagLiCheckbox, AgvTagLiImage}
  }
</script>

<style scoped>
  ul.thumbnail-container {
    display: flex;
    flex-wrap: wrap;
  }

  ul.thumbnail-container li {
    width: 59px;
    margin-right: 2px;
    max-height: 86px;
  }
</style>
