<template lang="pug">
  .rsv-payment-modal.cc-payment-modal
    el-dialog(title="Оплата заказа" width="560px" top="5vh" @close="$emit('close')" :visible="true")
      agv-payment-form(:show-sum="true"
      :sum-options="options.sumOptions"
      :payment-methods="options.paymentMethods"
      :rules-url="rulesUrl"
      :renter="renter"
      :bank-details="bankDetails"
      @proceed="onProceed")
</template>

<script>
  import AgvPaymentForm from 'Aggregator/components/agv-payment-form'

  export default {
    name: 'rsv-payment-modal',
    components: {AgvPaymentForm},
    props: {
      renter: Object,
      options: Object,
      bankDetails: Object,
      rulesUrl: String
    },
    methods: {
      onProceed(bill) {
        this.$emit('proceedToPayment', bill)
      }
    }
  }
</script>

<style scoped>

</style>
