<template lang="pug">
  .content(v-if='articles.length > 0')
    agv-breadcrumbs(v-if='category || section' :items="breadcrumbItems")
    .page-heading
      h1.page-title.page-title_top
        | {{title | capitalize}}
      .tools(style='display: flex' v-if='possibleArticles.length')
        agv-ordering-selector(v-if='articles.length' :ordering-criteria="orderingCriteria" :sort-column.sync='sortColumn' :sort-direction.sync='sortDirection')
        .spreader
        .item-count
          | Показано {{filteredArticles.length | decline(['изделие', 'изделия', 'изделий'])}}
    .catalog-container
      .catalog(v-if='!possibleArticles.length')
        .not-found-warning
          | Ничего не нашлось.
      .catalog(v-else)
        .catalog-banner(v-if='banner')
          a(:href='banner.sanitized_url' target="_blank")
            img(:src='banner.image.url')
          .close(@click='closeBanner(banner)')
            icon-close-banner
        agv-article(v-for="article in limitedArticles" :key="article.id"
        :town-id="town.id"
        :article="article"
        :show-discount="showDiscount"
        :show-like-button="false"
        :renter="renter")
      .sidebar-wrapper.noselect
        agv-filter-remove-button(@click='clearFilters' :style="{visibility: range || anyColors || anyCheckedCategories ? 'visible' : 'hidden'}")
        .filter-block
          .filter-block-title Период аренды
          order-datepicker.cc-datepicker(:value="range"
            @input="syncRange"
            :can-select-urgent-dates="false"
            :is-date-disabled-predicate="isDateDisabled")
        .filter-block(v-if='colorOptions.length && !isFavoritesPage' style='margin-bottom: 0; padding-bottom: 4px;')
          .filter-block-title Цвет
          agv-color-filter(:color-options="colorOptions"
            :active-colors="activeColors"
            :checked-colors="selectedColors"
            @toggle="toggleColor"
            :is-compact="false")
        .filter-block(v-if="section || isCataloguePage || isFavoritesPage")
          .filter-block-title Категория
          agv-tag-ul.categories(:tags="orderedCategories"
          :active-tag-ids='activeCategoryIds'
          :checked-tag-ids='checkedCategoryIds'
          :show-thumbnails='false'
          @toggle='toggleCategory')
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import _ from 'lodash'

  import IconCloseBanner from 'RenterSite/sprites/icon-close-banner.svg'

  import AgvArticle from 'Aggregator/components/agv-article'
  import AgvOrderingSelector from 'Aggregator/components/agv-ordering-selector'
  import AgvColorFilter from 'Aggregator/components/agv-color-filter'
  import AgvFilterRemoveButton from 'Aggregator/components/agv-filter-remove-button'
  import AgvBreadcrumbs from 'Aggregator/components/agv-breadcrumbs'
  import AgvTagUl from 'Aggregator/components/agv-tag-ul'

  import OrderDatepicker from 'Shared/components/order-datepicker'

  import getBannersService from 'RenterSite/shared/bannersService'

  import {getCurrentOrder, isDateDisabledForOrder} from 'RenterSite/lib/order'

  const DEFAULT_SORT_COLUMN = 'category_position'
  const DEFAULT_SORT_DIRECTION = 'asc'

  const getRentPeriodRange = (order) => {
    if (!(order.acquisition && order.returning)) {
      return null
    }
    return [order.acquisition, order.returning]
  }

  export default {
    name: 'page-rsv-articles-list',
    props: {
      category_id: String,
      section_id: String,
      searchTerms: String,
      limit: Number
    },
    data() {
      return {
        renter: this.$store.state.renter,
        sortColumn: DEFAULT_SORT_COLUMN,
        sortDirection: DEFAULT_SORT_DIRECTION,
        acquisition: null,
        returning: null,
        selectedColors: {},
        banner: null,
        bannersService: null,
        defaultOrderingCriteria: [
          {name: 'popularity', description: 'по популярности', defaultDirection: 'desc'},
          {name: 'price_in_town', description: 'по цене', defaultDirection: 'asc'},
          {name: 'size', description: 'по размеру', defaultDirection: 'desc'},
        ],
        defaultOrderingCriteriaForFavorites: [
          {name: 'order_count', description: 'по моим заказам', defaultDirection: 'desc'},
          {name: 'price_in_town', description: 'по цене', defaultDirection: 'asc'},
          {name: 'size', description: 'по размеру', defaultDirection: 'desc'},
        ],
        checkedCategoryIds: {},
        searchResults: {}
      }
    },
    components: {
      AgvBreadcrumbs,
      AgvTagUl,
      AgvFilterRemoveButton,
      AgvColorFilter,
      AgvOrderingSelector,
      AgvArticle,
      OrderDatepicker,
      IconCloseBanner
    },
    metaInfo() {
      return {
        title: this.metaTitle
      }
    },
    methods: {
      toggleColor(color) {
        Vue.set(this.selectedColors, color, !this.selectedColors[color])
      },
      setDefaultOrdering() {
        this.sortColumn = this.isFavoritesPage ? 'order_count' : DEFAULT_SORT_COLUMN
        this.sortDirection = this.isFavoritesPage ? 'desc' : DEFAULT_SORT_DIRECTION
      },
      isDateDisabled(date) {
        return isDateDisabledForOrder(date, getCurrentOrder())
      },
      fetchSearchResults() {
        if (!this.searchTerms) {
          return
        }

        axios.get(`/api/articles/search/${this.searchTerms}`).then(({data: searchResults}) => {
          this.searchResults = searchResults
        })
      },
      filterArticlesByExcept(property) {
        let filterByColor = this.anyColors && property !== 'color'
        let filterByCategory = this.anyCheckedCategories && property !== 'category'

        return _.filter(this.availableArticles, (a) => {
          return (!filterByColor || this.selectedColors[a.color]) &&
            (!filterByCategory || !!(this.checkedCategoryIds[a.category_id]))
        })
      },
      closeBanner(banner) {
        if (this.bannersService) {
          this.bannersService.close(banner)
          this.banner = this.bannersService.getNext()
          this.bannersService.show(this.banner)
        }
      },
      toggleCategory(category) {
        Vue.set(this.checkedCategoryIds, category.id, !this.checkedCategoryIds[category.id])
      },
      clearFilters() {
        this.$store.dispatch(
            'order/setRentPeriod',
            {
              range: null,
              renterId: this.renter.id
            }
        )
        this.selectedColors = {}
        this.checkedCategoryIds = {}
      },
      syncRange(value) {
        this.$store.dispatch(
            'order/setRentPeriod',
            {
              range: value,
              renterId: this.renter.id
            }
        )
      },
      resetFiltersAndSorting() {
        this.sortColumn = DEFAULT_SORT_COLUMN
        this.sortDirection = DEFAULT_SORT_DIRECTION
        this.selectedColors = {}
      }
    },
    computed: {
      showDiscount() {
        return this.$store.getters.showDiscount
      },
      articles() {
        return _.filter(this.$store.getters['articles/getAllByRenterId'](this.$store.state.renter.id), {is_shown_in_catalog: true})
      },
      order() {
        return getCurrentOrder()
      },
      range() {
        return getRentPeriodRange(this.order)
      },
      isCataloguePage() {
        return this.$route.path === '/catalogue'
      },
      isFavoritesPage() {
        return this.$route.path === '/favorites'
      },
      orderingCriteria() {
        if (this.isFavoritesPage) {
          return this.defaultOrderingCriteriaForFavorites
        }

        return this.defaultOrderingCriteria
      },
      metaTitle() {
        if (this.section) {
          return this.section.name
        }

        if (this.category) {
          return this.category.name
        }

        return this.searchTerms || 'Каталог'
      },
      anyCheckedCategories() {
        return _.values(this.checkedCategoryIds).some(v => v)
      },
      categories() {
        if (this.isFavoritesPage) {
          return _.filter(this.$store.state.categories,
            c => _.find(this.possibleArticles, a => a.category_id === c.id)
          )
        }

        if (this.isCataloguePage) {
          return this.$store.state.categories
        }

        if (this.section_id && !this.category_id) {
          return _.filter(this.$store.state.categories, c => c.section_id === parseInt(this.section_id))
        }
      },
      orderedCategories() {
        return _.orderBy(this.categories, 'position')
      },
      activeCategoryIds() {
        const categoryIdList = _.map(
          this.filterArticlesByExcept('category'),
          'category_id'
        )
        return  _.fromPairs(_.map(categoryIdList, id => [id, true]))
      },
      activeColors() {
        return _.uniq(_.map(this.filterArticlesByExcept('color'), 'color'))
      },
      anyColors() {
        return _.values(this.selectedColors).some(v => v)
      },
      colorOptions() {
        return _.filter(this.$store.state.color_options, (o) => {
          return _.find(this.availableArticles, a => a.color === o.value)
        })
      },
      category() {
        if (!(this.category_id)) {
          return null
        }
        return this.$store.state.categoriesById[this.category_id]
      },
      section() {
        if (!(this.section_id)) {
          return null
        }
        return this.$store.getters.sectionsById[this.section_id]
      },
      sectionOfCategory() {
        if (!(this.category && this.category.section_id)) {
          return null
        }
        return this.$store.getters.sectionsById[this.category.section_id]
      },
      breadcrumbItems() {
        return _.compact([
          {
            name: 'Каталог',
            url: '/catalogue'
          },
          (this.sectionOfCategory ? {
            name: this.sectionOfCategory.name,
            url: `/catalogue/section/${this.sectionOfCategory.id}`
          } : null)
        ])
      },
      title() {
        if (this.isFavoritesPage) {
          return 'Мои любимые изделия'
        }
        return this.searchTerms || _.get(this.category, 'name') || _.get(this.section, 'name') || 'Каталог'
      },
      path() {
        if (this.searchTerms) {
          return `/api/articles/search/${this.searchTerms}`
        }

        return '/api/articles.json'
      },
      sortingFactors() {
        const defaultDirections = {
          order_count: 'desc',
          search_rank: 'desc',
          category_position: 'asc',
          position_in_category: 'asc'
        }

        let withDefDirection = (columnName) => {
          return [columnName, defaultDirections[columnName]]
        }

        let factors = _.compact([
          (this.searchTerms && this.sortColumn === DEFAULT_SORT_COLUMN ? withDefDirection('search_rank') : [this.sortColumn, this.sortDirection]),
          [this.sortColumn, this.sortDirection],
          withDefDirection('category_position'),
          withDefDirection('position_in_category')
        ])

        return {
          columns: _.map(factors, f => f[0]),
          directions: _.map(factors, f => f[1]),
        }
      },
      categoriesInSectionIds() {
        if (!this.section_id) {
          return []
        }

        return _.map(
          _.filter(
            this.$store.state.categories,
            c => c.section_id === parseInt(this.section_id)),
          'id'
        )
      },
      possibleArticles() {
        if (this.isFavoritesPage) {
          if (!this.$store.state.customer.current) {
            return []
          }

          return _.map(
            this.$store.state.customer.current.article_order_counts,
            (orderCount, id) => {
              return _.merge(
                this.$store.getters['articles/getByRenterIdAndArticleId'](
                  this.$store.state.renter.id,
                  id
                ),
                {order_count: orderCount}
              )
            }
          )
        }

        if (this.searchTerms && this.searchResults) {
          return _.filter(this.articles, a => !!this.searchResults[a.id])
        }

        if (this.section_id) {
          return _.filter(this.articles, a => _.includes(this.categoriesInSectionIds, a.category_id))
        }

        if (this.category_id) {
          return _.filter(this.articles, a => a.category_id === parseInt(this.category_id))
        }

        return this.articles
      },
      possibleArticlesWithAdditionalData() {
        if (this.searchTerms && this.searchResults) {
          return _.map(this.possibleArticles, a => _.merge(a, this.searchResults[a.id]))
        }

        return this.possibleArticles
      },
      availableArticles() {
        if (this.$store.state.$isServer) {
          return this.possibleArticlesWithAdditionalData
        }
        return _.filter(this.possibleArticlesWithAdditionalData, a => a.total_available > 0)
      },
      filteredArticles() {
        return _.filter(this.filterArticlesByExcept(), a => a.is_offered && a.are_all_components_offered)
      },
      orderedArticles() {
        return _.orderBy(
          this.filteredArticles,
          this.sortingFactors.columns,
          this.sortingFactors.directions
        )
      },
      limitedArticles() {
        return _.take(this.orderedArticles, this.limit)
      },
      town() {
        return this.$store.state.town.current
      }
    },
    created() {
      if (!this.$store.state.$isServer) {
        this.bannersService = getBannersService(this.$store.state)
        this.banner = this.bannersService.getNext()
        this.bannersService.show(this.banner)
      }
      this.setDefaultOrdering()
    },
    watch: {
      range: 'syncRange',
      searchTerms: [
        {
          handler: 'fetchSearchResults',
          immediate: true
        },
        {handler: 'resetFiltersAndSorting'}
      ],
      $route() {
        this.resetFiltersAndSorting()

        this.setDefaultOrdering()
      }
    }
  }
</script>

<style scoped>
  .not-found-warning {
    color: gray;
    display: block;
    font-size: 14px;
    margin-left: 6px;
  }

  .catalog-banner {
    width: 96%;
    min-height: 165px;
    margin-bottom: 40px;
    position: relative;
  }

  .catalog-banner a {
    width: 100%;
  }

  .catalog-banner img {
    width: 100%;
    border: 1px solid #eee;
  }

  .catalog-banner .close {
    opacity: 0;
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 14px;
    top: 12px;
    cursor: pointer;
  }

  .catalog-banner:hover .close {
    opacity: 1;
  }

  .catalog-container {
    display: flex;
    justify-content: space-between;
  }

  .catalog {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    height: fit-content;
  }

  .catalog .ag-article {
    flex-basis: 25%;
  }

  .categories {
    margin-top: 12px;
  }

  .sidebar-wrapper {
    position: relative;
    margin-left: 20px;
  }

  .sidebar-wrapper >>> .btn-filter-remove {
    position: absolute;
    top: -29px;
    left: 0;
  }

  .datepicker {
    margin-top: 12px;
  }
</style>
