import _ from 'lodash'

export default function getGetters() {
  return {
    getByRenterId: (state) => (renterId) => {
      return state.ordersByRenterId[renterId] || {}
    },
    lineItemsQuantity(state) {
      return _.sumBy(
        _.filter(_.values(state.ordersByRenterId), o => !o.id),
        o => _.get(o.line_items, 'length', 0)
      ) || 0
    },
  }
}
