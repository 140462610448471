<template lang="pug">
  .branch-select
    icon-arrow-down.icon-arrow-down
    v-select.btn-open-dropdown-geo(v-model='internalValue' :options='options' :style="{width: inputWidth + 'px'}")
      template(slot="option" slot-scope="option")
        span(:class="{'bold': option.isBold}")
          | {{ option.label }}
      span(slot="no-options")
        | Город не найден
</template>

<script>
  import vSelect from 'vue-select'

  import IconArrowDown from 'Aggregator/components/agv-branch-select/sprites/icon-arrow-down.svg'

  export default {
    name: 'agv-branch-select',
    props: {
      value: Object,
      options: Array
    },
    data() {
      return {
        internalValue: this.value
      }
    },
    computed: {
      inputWidth() {
        return 42 * Math.sqrt(2 + this.internalValue.label.length / 1.8)
      }
    },
    watch: {
      'value': 'updateInternalValue',
      'internalValue': 'emitValue'
    },
    methods: {
      updateInternalValue() {
        this.internalValue = this.value
      },
      emitValue() {
        this.$emit('input', this.internalValue)
      }
    },
    components: {vSelect, IconArrowDown}
  }
</script>

<style scoped>
  .btn-open-dropdown-geo {
    font-size: 14px;
        line-height: 15px;
    cursor: pointer;
    display: inline-block;
  }

  .icon-arrow-down {
    margin-right: 3px;
    position: relative;
    top: -1px;
  }

  .branch-select {
    padding: 7px 0;
    line-height: 13px;
  }

  .branch-select >>> .v-select {
        margin-left: -5px;
  }

  .branch-select >>> .dropdown-menu {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #B9B9B9;
    padding: 0 !important;
  }

  .branch-select >>> .dropdown-menu li {
    height: 38px;
    line-height: 32px;
    font-size: 13px;
    cursor: pointer;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

        padding-left: 6px;
    padding-right: 6px;

    border-top: 1px solid #E4E4E4;
  }

  .branch-select >>> .dropdown-menu li:first-child {
    border-top: none;
  }

  .branch-select >>> .v-select .clear {
    display: none;
  }

  .branch-select >>> .v-select .open-indicator {
    display: none;
  }

  .branch-select >>> .v-select .dropdown-toggle {
    border: none;
    display: inline-block;
  }

  .branch-select >>> .v-select input {
    font-size: 14px;
    cursor: pointer;
  }

  .branch-select >>> .v-select {
    font-family: 'Inter', sans-serif;
  }

  .branch-select >>> .v-select .selected-tag {
    font-family: 'Inter', sans-serif;
    position: absolute;
    font-size: 14px;
    margin: 4px 0 0 0;
  }

  .branch-select >>> .v-select .dropdown-menu a {
    background: white;
    color: #4A90E2;
  }

  .branch-select >>> .v-select .dropdown-menu > .highlight > a {
    background: white;
    color: #4A90E2;
  }
</style>
