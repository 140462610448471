<template lang="pug">
  .rsv-modals
    one-time-access-modal(v-if="showOneTimeAccessRequestModal" @close="showOneTimeAccessRequestModal = false")
</template>

<script>
  import OneTimeAccessModal from 'Shared/components/one-time-access-modal'

  export default {
    name: "rsv-modals",
    components: {
      OneTimeAccessModal,
    },
    data() {
      return {
        showOneTimeAccessRequestModal: false,
      }
    }
  }
</script>

<style scoped>

</style>
