<template lang="pug">
  .content(v-if="photo")
    .page-heading
      h1.page-title.page-title_top
        | {{title | capitalize}}
    .photo-container
      img.photo__image(:src="`https://pifakit.ru${photo.image.large.url}`")
    .additional-section(v-if='articles && articles.length > 0')
      .title-additional
        | Изделия на этой фотографии
      .catalog
        agv-article(v-for="article in articles" :key="article.id"
          :article="article"
          :town-id="$store.state.town.current.id"
          :renter="$store.state.renter")
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import AgvArticle from 'Aggregator/components/agv-article'

  import TruncatedTitle from 'RenterSite/shared/TruncatedTitle'
  import {getCurrentOrder} from 'RenterSite/lib/order'

  export default {
    name: 'page-rsv-gallery-photo',
    components: {AgvArticle},
    computed: {
      order() {
        return getCurrentOrder()
      },
      articles() {
        if (!this.photo) {
          return null
        }

        return _.orderBy(
          _.filter(
            this.$store.getters['articles/getAllByRenterId'](this.$store.state.renter.id),
            a => a.is_offered && _.includes(this.photo.all_article_ids, a.id) && a.total_available
          ),
          ['category_position', 'position_in_category']
        )
      },
      title() {
        return 'Фотография'
      },
      categories() {
        if (!this.articles) {
          return null
        }

        return _.map(
          _.map(this.articles, 'category_id'),
          id => this.$store.state.categoriesById[id]
        )
      },
      metaTitle() {
        if (!this.categories) {
          return 'Аренда декора'
        }
        return new TruncatedTitle(
          `Фотография: ${_.map(this.categories, 'name').join(', ').toLowerCase()} в аренду`
        ).toString()
      }
    },
    data() {
      return {
        photo: null,
      }
    },
    metaInfo() {
      return {
        title: this.metaTitle
      }
    },
    methods: {
      fetchPhoto() {
        axios.get(`/api/gallery_photos/${this.photo_id}.json`).then(({data: photo}) => {
          this.photo = photo
        })
      }
    },
    props: {
      photo_id: String
    },
    watch: {
      photo_id: {
        handler: 'fetchPhoto',
        immediate: true
      }
    }
  }
</script>

<style scoped>
  .photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo__image {
    width: 80%;
    max-height: 100%;
  }

  .photo-container {
    min-height: 500px;
  }

  .catalog {
    display: flex;
    flex-wrap: wrap;
  }

  .catalog .ag-article {
    flex-basis: 25%;
  }

  .content {
    margin-bottom: 60px;
  }

  .title-additional {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 1.5em;
  }

  .additional-section {
    margin-top: 40px;
  }
</style>
