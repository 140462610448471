<template lang="pug">
  .nav-btn(@click='$emit("click")' :class="{'nav-btn-activated': isNavSheetVisible}" :style="{'background-color': isNavSheetVisible ? null : themeColor}")
    svg.icon.icon-cross(v-if='isNavSheetVisible' width='19' height='14' viewbox='0 0 19 15')
      g#Page-1(stroke='none' stroke-width='1' fill='none' fill-rule='evenodd')
        g#Artboard(stroke='#FFFFFF' stroke-width='2')
          g#cross
            path#Line(d='M0.5,13 L15.5,0.5')
            path#Line(d='M0.5,0.5 L15.5,13')
    svg.icon(v-if='!isNavSheetVisible' width='19' height='14' viewbox='0 0 19 15' xmlns='http://www.w3.org/2000/svg')
      g(stroke='#FFF' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square')
        path(d='M1.5 11h16M1.5 1h16M1.5 6h16')
    | Каталог изделий
</template>

<script>
  export default {
    name: 'agv-nav-button',
    props: {
      isNavSheetVisible: Boolean
    },
    computed: {
      themeColor() {
        return this.$store.state.themeColor
      }
    }
  }
</script>

<style scoped>
  .icon {
    margin-right: 14px;
    margin-bottom: 4px;
    position: relative;
    top: 3px;
  }

  .icon-cross {
    top: 2px;
  }

  .nav-btn {
    line-height: 28px;
    font-size: 14px;
    height: 50px;
    color: white;
    background: #4990e2;
    border-left: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    user-select: none;
  }

  .nav-btn.nav-btn-activated {
    background: #9b9b9b;
  }
</style>
