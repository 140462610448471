<template lang="pug">
  el-popover(placement="bottom-start" :width="Math.max(360, 80 + paymentMethods.length * 110)" trigger="click" @hide="showSbolPaymentModal = false")
    agv-payment-form(:bank-details="bankDetails" :sum-options="paymentSumOptions" :payment-methods="paymentMethods" :show-sum="false" style="padding: 10px" @proceed="onProceedToPayment")
    sbol-payment-modal(v-if="showSbolPaymentModal" @close="showSbolPaymentModal = false" :renter="renter" :customer="customer" :amount="sbolPaymentSum")
    slot(name="reference" slot="reference")
</template>

<script>
import $ from 'jquery'
import qs from 'querystring'
import axios from 'axios'

import SbolPaymentModal from 'Shared/components/sbol-payment-modal'
import allowedPaymentMethodsFor from 'Shared/allowedPaymentMethodsFor'
import AgvPaymentForm from 'Aggregator/components/agv-payment-form'

import {openWindowWithPost} from 'Shared/lib/openWindowWithPost'

export default {
  name: "agv-customer-debt-payment-popover",
  data() {
    return {
      showSbolPaymentModal: false,
      sbolPaymentSum: null
    }
  },
  components: {AgvPaymentForm, SbolPaymentModal},
  props: {
    customer: {type: Object, required: true},
    renter: {type: Object, required: true},
    getCustomerBillsUrl: {type: Function, required: true},
    bankDetails: Object
  },
  computed: {
    paymentSumOptions() {
      return [
        {
          value: -this.customer.balance,
          label: 'Полная оплата'
        }
      ]
    },
    paymentMethods() {
      return _.without(allowedPaymentMethodsFor(this.renter), 'cash')
    },
  },
  methods: {
    onProceedToPayment(data) {
      let queryString = qs.stringify(_.pick(this.bankDetails, ['legal_name', 'address', 'tin', 'rrc']) || {})
      let customerBillsUrl = this.getCustomerBillsUrl(this.renter.id)

      switch (data.paymentMethod) {
        case 'robokassa':
          return axios.get( customerBillsUrl + '/create/robokassa')
              .then(({data: robokassaRequestParams}) => {
                console.log(robokassaRequestParams)
                return openWindowWithPost("https://auth.robokassa.ru/Merchant/Index.aspx", robokassaRequestParams)
              })
        case 'modulbank':
          return window.open(customerBillsUrl + '/create/modulbank')
        case 'wire_transfer':
          window.open(
              customerBillsUrl + '/create/wire_transfer.pdf?' + queryString,
              '_blank'
          )
          return
        case 'sberbank':
          this.sbolPaymentSum = data.sum
          this.showSbolPaymentModal = true
          setTimeout(() => {
            $('.v-modal').css('z-index', 0)
          }, 0)
          return;
      }
    }
  }
}
</script>

<style scoped>

</style>
