<template lang="pug">
  input.search(maxlength='40' v-model='internalValue' @blur="emitValue()" @keyup.enter="emitValue()" placeholder='Я хочу взять в аренду...' type='text')
</template>

<script>
  export default {
    name: 'agv-sitewide-search',
    props: {
      value: String,
    },
    data() {
      return {
        internalValue: this.value
      }
    },
    watch: {
      'value': 'updateInternalValue',
    },
    methods: {
      updateInternalValue() {
        this.internalValue = this.value
      },
      emitValue() {
        this.$emit('input', this.internalValue)
      }
    }
  }
</script>

<style scoped>
  .search {
    line-height: 28px;
    font-size: 14px;
    height: 50px;
    border-radius: 4px 0 0 4px;
    width: 100%;
    display: block;
    border: 1px solid #9b9b9b;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 20px 0 21px;
    background: #fff;
    outline: none;
    color: #4a4a4a;
  }

  .search::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #b9b9b9;
  }

  .search::-moz-placeholder {
    /* Firefox 19+ */
    color: #b9b9b9;
  }

  .search:-ms-input-placeholder {
    /* IE 10+ */
    color: #b9b9b9;
  }

  .search:-moz-placeholder {
    /* Firefox 18- */
    color: #b9b9b9;
  }
</style>
