export const METHODS = {
  ROBOKASSA: 'robokassa',
  MODULBANK: 'modulbank',
  SBERBANK: 'sberbank',
  WIRE_TRANSFER: 'wire_transfer',
  CASH: 'cash'
}

export const TITLES = {
  robokassa: 'Картой',
  modulbank: 'Картой',
  sberbank: 'Переводом на карту',
  wire_transfer: 'Банковским переводом',
  cash: 'Наличными'
}

export const SUBTITLES = {
  robokassa: 'через Робокассу',
  modulbank: 'через Модульбанк',
  sberbank: '',
  wire_transfer: 'Только для юрлиц и ИП',
  cash: ''
}
