<template lang="pug">
  .agv-rating
    agv-star-rating.star-rating(:rating="effectiveRating")
    .rating-count(v-if="ratingCount > 0")
      | {{ratingCount | decline(['отзыв', 'отзыва', 'отзывов'])}}
    .rating-count(v-else)
      | Нет отзывов
</template>

<script>
  import AgvStarRating from 'Aggregator/components/agv-star-rating'

  export default {
    name: 'agv-rating',
    components: {AgvStarRating},
    computed: {
      effectiveRating() {
        if (!this.ratingCount) {
          return 0
        }

        return this.rating
      }
    },
    props: {
      rating: Number,
      ratingCount: Number
    }
  }
</script>

<style scoped>
  .agv-rating >>> .agv-star-rating {
    margin-right: 0.35em;
  }

  .rating-count {
    color: #949494;
    display: inline-block;
  }

  .star-rating {
    display: inline-block;
  }
</style>
