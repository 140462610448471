<template lang="pug">
  el-dialog(title="Оплата наличными" width="500px" :visible="true" @close="$emit('close')")
    p
      | Оплатите заказ в нашем пункте выдачи:
      | {{branch.cp_address}}, {{branch.cp_off_days | cpWorkdaysFromOffDays}},
      |
      span(v-if="branch.freeform_cp_schedule")
        | {{branch.freeform_cp_schedule}}
      span(v-else)
        | {{branch.cp_schedule | cpSchedule}}
      | .
</template>

<script>
  export default {
    name: "cash-payment-modal",
    props: {
      renter: Object,
      branch: Object,
    }
  }
</script>

<style scoped>

</style>
