// @flow

import Vue from 'vue'
import _ from 'lodash'

import {
  findOrBuildLineItem,
  fixQuantities,
  getEmptyOrder,
  setLocalStorageOrder,
  getAllLocalStorageOrders,
  getNotEnoughErrors,
  containsArticle,
  clearAllLocalStorageOrders,
  isPlaydayInBranch,
  getRawLocalStorageOrders,
  fixOrder
} from 'Shared/order'

import eventBus from 'Shared/eventBus'

function setOrder(state, order, renterId) {
  Vue.set(state.ordersByRenterId, renterId, order)
  // FIXME should update town?
  // if (order.town_id) {
  //   state.town.current = state.town.townsById[order.town_id]
  // }
  // availability.maybeLoadAvailable('setOrder')
  // FIXME перенести это в actions, потому что здесь может вызывать асинхронное действие loadAvailable
}

function maybeSaveToLocalStorage(order, renterId) {
  if (order.id) {
    return
  }
  setLocalStorageOrder(_.omit(order, 'errors'), renterId)
}

function mutatingOrder(state, renterId: number, callback: Function) {
  // assert.number(renterId, 'renterId')
  let order = state.ordersByRenterId[renterId]
  if (!order) {
    order = {added_at: new Date().getTime()}
    Vue.set(state.ordersByRenterId, renterId, order)
  }
  callback(order)
  maybeSaveToLocalStorage(order, renterId)
}

function clearOrder(state, renterId, townId, removeOrderWhenClearing) {
  if (removeOrderWhenClearing) {
    Vue.delete(state.ordersByRenterId, renterId)
    setLocalStorageOrder(null, renterId)
  } else {
    const order = getEmptyOrder(townId)
    setOrder(state, order, renterId)
    maybeSaveToLocalStorage(order, renterId)
  }
}

function setArticleQuantityFn(order, articleId, renterId, quantity) {
  articleId = parseInt(articleId)
  const isNew = !_.find(order.line_items, li => li.article_id === articleId)
  const li = findOrBuildLineItem(order, articleId)
  li.quantity = parseInt(quantity)
  if (isNew) {
    order.line_items.push(li)
    eventBus.$emit('order:itemAdded', renterId)
  }
}

export default function getMutations(getRenter: (renterId: number) => Object,
                                     getArticle: (articleId: number) => { id: ?number, article_id: number },
                                     getAvailableForPeriod: (article: { id: ?number, article_id: ?number }) => number,
                                     removeOrderWhenClearing: boolean) {
  return {
    clearCurrent(state, {townId, renterId}) {
      clearOrder(state, renterId, townId, removeOrderWhenClearing)
    },
    clearAllNewOrders(state) {
      clearAllLocalStorageOrders()
      state.ordersByRenterId = {}
    },
    setCurrentFromRawLocalStorage(state) {
      state.ordersByRenterId = _.pick(getRawLocalStorageOrders(), _.map(window.renters, r => r.id))
    },
    setCurrentFromLocalStorage(state, {townId}) {
      state.ordersByRenterId = getAllLocalStorageOrders(townId, getRenter, getArticle)
    },
    setCurrent(state, {order, renterId}) {
      setOrder(state, order, renterId)
      maybeSaveToLocalStorage(order, renterId)
    },
    setArticleQuantity(state, {articleId, quantity, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        setArticleQuantityFn(order, articleId, renterId, quantity)
      })
    },
    addArticlesQuantity(state, {articleIds, componentsWithQuantity, quantity, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        _.each(componentsWithQuantity, (component) => {
          const alreadyAddedLineItem = _.find(order.line_items, li => li.article_id === component.id)
          setArticleQuantityFn(
            order,
            component.id,
            renterId,
            parseInt(alreadyAddedLineItem?.quantity || 0, 10) + quantity * parseInt(component.quantity, 10)
          )
        })
      })
    },
    setAdHocLineItemName(state, {ahli, name, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        ahli.name = name
        Vue.delete(ahli, 'nameError')
      })
    },
    setAdHocLineItemPrice(state, {ahli, price, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        const parsedPrice = price === '' || price === '-' ? price : parseInt(price)
        ahli.price = _.isNaN(parsedPrice) ? 0 : parsedPrice
        Vue.delete(ahli, 'priceError')
      })
    },
    removeAdHocLineItem(state, {itemId, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        const ahli = _.find(order.ad_hoc_line_items, ahli => ahli.id == itemId)
        order.ad_hoc_line_items.splice(order.ad_hoc_line_items.indexOf(ahli), 1)
      })
    },
    setAdHocLineItemNameError(state, ahli) {
      Vue.set(ahli, 'nameError', true)
    },
    setAdHocLineItemPriceError(state, ahli) {
      Vue.set(ahli, 'priceError', true)
    },
    fixQuantities(state, {renterId, writeErrors}) {
      mutatingOrder(state, renterId, (order) => {
        if (writeErrors) {
          const notEnoughErrors = getNotEnoughErrors(order.line_items, renterId, getAvailableForPeriod)
          if (notEnoughErrors.length) {
            if (!order.errors) {
              Vue.set(order, 'errors', {})
            }
            Vue.set(order.errors, 'line_items.not_enough', notEnoughErrors)
          }
        }
        fixQuantities(order.line_items, renterId, getAvailableForPeriod)
      })
    },
    setBackup(state, {order, renterId}) {
      Vue.set(state, 'backupsByRenterId', {})
      state.backupsByRenterId[renterId] = order
    },
    setErrors(state, {errors, renterId}) {
      const order = state.ordersByRenterId[renterId]
      Vue.set(order, 'errors', errors)
    },
    restoreFromBackup(state, {order, renterId}) {
      _.assign(order, state.backupsByRenterId[renterId])
      state.backupsByRenterId = null
    },
    fixOrder(state, {renter, townId, getArticle}) {
      const order = state.ordersByRenterId[renter.id]
      const fixedOrder = fixOrder(order, renter, townId, getArticle)
      _.each(_.keys(fixedOrder), k => {
        Vue.set(order, k, fixedOrder[k])
      })
    },
    addAdHocLineItem(state, {renterId}) {
      mutatingOrder(state, renterId, (order) => {
        if (!order.ad_hoc_line_items) {
          Vue.set(order, 'ad_hoc_line_items', [])
        }
        order.ad_hoc_line_items.push({name: '', price: 0, id: 'new-' + Math.round(Math.random() * 1000000)})
      })
    },
    addArticle(state, {articleId, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        if (containsArticle(order, articleId)) {
          return
        }

        const li = findOrBuildLineItem(order, articleId)
        order.line_items.push(li)
        eventBus.$emit('order:itemAdded', renterId)
      })
    },
    removeArticle(state, {articleId, renterId, clearOrderAfter}) {
      let lineItemsCount
      let townId

      mutatingOrder(state, renterId, (order) => {
        const li = _.find(order.line_items, li => li.article_id === parseInt(articleId))
        order.line_items.splice(order.line_items.indexOf(li), 1)

        lineItemsCount = order.line_items.length
        townId = order.town_id
      })

      if (lineItemsCount === 0 && clearOrderAfter) {
        clearOrder(state, renterId, townId, removeOrderWhenClearing)
        eventBus.$emit('order:cleared')
      }
    },
    addArrangement(state, {renterId}) {
      mutatingOrder(state, renterId, (order) => {
        Vue.set(order, 'arrangement', {})
        if (!order.delivery) {
          Vue.set(order, 'delivery', {is_to_hall: true})
        } else {
          Vue.set(order.delivery, 'is_to_hall', true)
        }
      })
    },
    removeArrangement(state, {renterId}) {
      mutatingOrder(state, renterId, (order) => {
        Vue.set(order, 'arrangement', undefined)
      })
    },
    addDelivery(state, {renterId}) {
      mutatingOrder(state, renterId, (order) => {
        Vue.set(order, 'delivery', {})
      })
    },
    setDeliveryDetails(state, {details, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        Vue.set(order, 'delivery', details)
      })
    },
    removeDelivery(state, {renterId, branch}) {
      mutatingOrder(state, renterId, (order) => {
        Vue.set(order, 'delivery', undefined)
        Vue.set(order, 'arrangement', undefined)

        if (
          isPlaydayInBranch(branch, new Date(order.acquisition)) ||
          isPlaydayInBranch(branch, new Date(order.returning))
        ) {
          Vue.set(order, 'acquisition', null)
          Vue.set(order, 'returning', null)
        }
      })
    },
    setDeliveryDistance(state, {distance, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        Vue.set(order.delivery, 'distance', distance)
      })
    },
    setRentPeriod(state, {range, renterId}) {
      mutatingOrder(state, renterId, (order) => {
        if (range) {
          Vue.set(order, 'acquisition', range[0])
          Vue.set(order, 'returning', range[1])
        } else {
          Vue.set(order, 'acquisition', null)
          Vue.set(order, 'returning', null)
        }
      })
    },
    saveOrderToLocalStorage(state, {order, renterId}) {
      maybeSaveToLocalStorage(order, renterId)
    },
    clearErrors(state, {renterId}) {
      mutatingOrder(state, renterId, (order) => {
        order.errors = undefined
      })
    },
    clearDeliveryError(state, {renterId}) {
      const errors = _.get(state.ordersByRenterId[renterId], 'errors')
      if (!errors) {
        return
      }

      if (errors.delivery) {
        Vue.set(errors, 'delivery', undefined)
        delete errors.delivery
      }
    },
    clearNotEnoughError(state, {articleId, renterId}) {
      const errors = _.get(state.ordersByRenterId[renterId], 'errors')
      if (!errors) {
        return
      }

      const notEnoughErrors = errors['line_items.not_enough']
      const error = _.find(notEnoughErrors, e => e.article_id == articleId)
      Vue.set(errors, 'line_items.not_enough', _.without(notEnoughErrors, error))
    },
    clearNotEnoughErrors(state, {renterId}) {
      const errors = _.get(state.ordersByRenterId[renterId], 'errors')
      if (!errors) {
        return
      }
      Vue.set(errors, 'line_items.not_enough', undefined)
      delete errors['line_items.not_enough']
    }
  }
}
