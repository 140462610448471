import Vue from 'vue'

export default {
  setAvailableForPeriod(state, {available, reason, renterId}) {
    state.lastAvailabilityUpdateReason = reason
    Vue.set(state.availableByRenterId, renterId, available)
  },
  clearAvailable(state, {renterId}) {
    Vue.set(state.availableByRenterId, renterId, null)
  }
}
