<template lang="pug">
  .form-container
    .agv-customer-orders-page-content.form-container
    .page-heading
      h1.page-title.page-title_top
        | {{title}}
      .span(v-if="areCurrentOrdersPresent")
        .expand-btn.link-btn(v-if='showAll' @click="showAll = false")
          | Показать текущие
        .expand-btn.link-btn(v-else @click="showAll = true")
          | Показать все заказы
    div(v-if="orders && orders.length === 0")
      | Пока у вас нет заказов
    agv-customer-orders-table(v-else v-loading="!orders"
      :country-code="renter.country"
      :show-renter="false"
      :is-prolongation-disabled="$store.state.featureFlags.disable_payment_prolongation"
      :is-selecting-orders="false"
      :get-orders-route-url="getOrdersRouteUrl"
      :get-orders-url="getOrdersUrl"
      :orders="orders")
</template>

<script>
  import axios from 'axios'
  import dayjs from 'dayjs'
  import _ from 'lodash'

  import {getOrdersRouteUrl, getOrdersUrl} from 'RenterSite/lib/order'
  import AgvCustomerOrdersTable from 'Aggregator/agv-customer-orders-table'

  export default {
    name: 'page-rsv-customer-orders-list',
    components: {AgvCustomerOrdersTable},
    props: {
      customer: Object,
      town_id: String
    },
    data() {
      return {
        orders: null,
        showAll: false,
        getOrdersRouteUrl,
        getOrdersUrl
      }
    },
    computed: {
      title() {
        return this.shownOrdersSubset === 'current' ? 'Текущие заказы' : 'Мои заказы'
      },
      currentOrders() {
        return _.filter(this.orders, o => {
          const now = dayjs()
          const returning = dayjs(o.returning)
          return o.state_code < 5 ||
            o.state_code === 6 && returning.diff(now, 'day') >= 0
        })
      },
      areCurrentOrdersPresent() {
        return this.currentOrders.length > 0
      },
      shownOrdersSubset() {
        if (!this.areCurrentOrdersPresent) {
          return 'all'
        }

        if (this.showAll) {
          return 'all'
        } else {
          return 'current'
        }
      },
      renter() {
        return this.$store.state.renter
      },
    },
    methods: {
      fetchOrders() {
        axios.get(getOrdersUrl()).then(({data: orders}) => {
          this.orders = orders
        })
      },
      onCustomerChange() {
        if (!this.customer) {
          return
        }

        this.fetchOrders()
      }
    },
    watch: {
      customer: {
        handler: 'onCustomerChange',
        immediate: true
      }
    }
  }
</script>

<style scoped>
</style>
