<template lang="pug">
  .datepicker
    agv-datepicker(v-model='internalValue'
    :is-date-disabled-predicate="isDateDisabledPredicate"
    align="left"
    @focus="$emit('focus')"
    @change="onChange")
</template>

<script>
  import dayjs from 'dayjs'
  import $ from 'jquery'

  import AgvDatepicker from 'Aggregator/components/agv-datepicker'

  export default {
    name: 'order-datepicker',
    props: {
      value: Array,
      isDateDisabledPredicate: Function,
      canSelectUrgentDates: Boolean
    },
    components: {AgvDatepicker},
    data() {
      return {
        internalValue: this.value,
      }
    },
    watch: {
      'value': 'updateInternalValue',
      'internalValue': 'emitValue'
    },
    methods: {
      onChange(range) {
        if (!range) {
          this.internalValue = null
        }
      },
      updateInternalValue() {
        this.internalValue = this.value
      },
      emitValue() {
        this.$emit('input', this.internalValue)
      }
    }
  }
</script>

<style scoped>
  .datepicker .el-popover {
    padding: 22px;
  }

  p {
    text-align: left;
    margin-bottom: 10px;
  }
</style>
