<template lang="pug">
  .agv-sbol-payment-help
    .p
      | Переведите сумму на карту со следующими реквизитами:
      .sbol-details
        .label
          | Номер карты
        |     {{card | formatCreditCard}}
      .sbol-details
        .label
          | Сумма
        |     {{amount | money(renter.country)}}
      .sbol-details
        .label
          | Сообщение
        |     {{payer.first_name}} {{payer.last_name}}
    .p
      | Проверьте имя получателя:
      | {{receiverFullName}}
    .sbol-details(style='margin-top: 23px;')
      | Зачислим деньги в течение одного рабочего дня.
</template>

<script>
  export default {
    name: 'agv-sbol-payment-help',
    props: {
      amount: Number,
      receiverFullName: String,
      card: String,
      payer: Object,
      renter: Object
    }
  }
</script>

<style scoped>
  .p {
    font-size: 13px;
    color: #4a4a4a;
    line-height: 16px;
    margin-bottom: 18px;
  }

  .p a {
    color: #4a90e2;
  }

  .sbol-details {
    font-size: 11px;
    color: #4a4a4a;
    line-height: 19px;
  }

  .sbol-details:first-child {
    margin-top: 10px;
  }

  .sbol-details .label {
    margin-left: 15px;
    width: 88px;
    display: inline-block;
  }
</style>
